import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { CupCode } from '@/interfaces/models/CupCode';

export class CupCodeApiService extends ApiService<any> {
  constructor() {
    super('general/cup');
  }

  public generate(param: any): AxiosPromise<any> {
    return axios.post(`${this.getBaseUrl()}/generate`, param);
  }

  public getCupCodes(page?: Page | null | undefined, filter?: any): AxiosPromise<Pagination<any> | any[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    if (page) {
      return axios.get(`${this.getBaseUrl()}/?page=${page.page}&${query}`);
    }

    return axios.get(`${this.getBaseUrl()}/?${query}`);
  }

  public destroyCupCode(param: { id: string }): AxiosPromise<any> {
    return axios.delete(`${this.getBaseUrl()}/${param.id}`);
  }

  public exportCodes(param: { customerGroups: string[]; format: 'csv' | 'xlsx' | 'zip' }): AxiosPromise<any> {
    let query: string = '';
    if (param && Object.keys(param).length > 0) {
      query = this.mapToQuery(param);
    }

    return axios.get(`v1/export/cup/?${query}`, { responseType: 'arraybuffer' });
  }

  public activate(data: { id: string }): AxiosPromise<CupCode> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/activate`);
  }

  public deactivate(data: { id: string }): AxiosPromise<CupCode> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/deactivate`);
  }

  public toggleActive(data: {
    customerGroups: string[];
    numFrom: string;
    numTo: string;
    active: boolean;
  }): AxiosPromise<any> {
    return axios.patch(`${this.getBaseUrl()}/active-toggle`, data);
  }

  public deleteMany(data: { customerGroups: string[]; numFrom: string; numTo: string }): AxiosPromise<any> {
    let query: string = '';
    if (data && Object.keys(data).length > 0) {
      query = this.mapToQuery(data);
    }
    return axios.delete(`${this.getBaseUrl()}/?${query}`);
  }
}
