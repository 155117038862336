import ApiService from '@/api/http/ApiService';
import { Workflow } from '@/interfaces/models/Workflow';
import axios from 'axios';

export default class WorkflowApiService extends ApiService<Workflow> {
  constructor() {
    super('general/workflow');
  }

  public upload(data: FormData) {
    return axios.post(`${this.getBaseUrl()}/json`, data);
  }
}
