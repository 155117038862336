import { Permission } from '@/enums/Permission';
import cookie from 'js-cookie';
import { getPermissions, hasAnyPermission, hasPermission, isAuthorized } from '@/util/helper';

export const Acl = {
  install(Vue: any, options: object) {
    Vue.prototype.$permissions = Permission;

    Vue.prototype.$can = (perm: Permission) => {
      // @ts-ignore
      const role: string | undefined = cookie.get('role');

      if (!isAuthorized()) {
        return false;
      }

      if (role === 'admin') {
        return true;
      }

      return hasPermission(perm);
    };

    Vue.prototype.$canAny = (perm: Permission[]) => {
      // @ts-ignore
      const role: string | undefined = cookie.get('role');

      if (!isAuthorized()) {
        return false;
      }

      if (role === 'admin') {
        return true;
      }

      if (!perm) {
        return true;
      }

      return hasAnyPermission(perm);
    };

    Vue.prototype.$hasCustomerGroup = () => {
      const customer: string | undefined = cookie.get('customerGroup');

      return !!(customer && customer !== '');
    };

    Vue.prototype.$getCustomerGroup = () => {
      return cookie.get('customerGroup');
    };

    Vue.prototype.$getLinkedCustomerGroup = () => {
      return cookie.get('linkedCustomerGroup');
    };

    Vue.prototype.$isAdmin = () => {
      // @ts-ignore
      const token: string | undefined = cookie.get('token');
      const role: string | undefined = cookie.get('role');

      if (!token) {
        return false;
      }

      return role === 'admin';
    };

    Vue.prototype.$isAuthorized = () => {
      return isAuthorized();
    };
  },
};
