import _Vue from 'vue';
export const Loading = {
  install(Vue: typeof _Vue): void {
    const root: _Vue = new Vue({
      data: {
        loading: {},
        anyLoading: false,
      },
    });

    Vue.prototype.$isLoading = (comp?: string): boolean => {
      if (comp && root.$data.loading.hasOwnProperty(comp) && root.$data.loading[comp] === true) {
        return true;
      }
      if (!comp) {
        return root.$data.anyLoading;
      }

      return false;
    };

    Vue.prototype.$isAnyLoading = (): boolean => {
      return root.$data.anyLoading;
    };

    Vue.prototype.$startLoading = (comp?: string): void => {
      if (comp) {
        root.$set(root.$data.loading, comp, true);
      }
      root.$data.anyLoading = true;
    };

    Vue.prototype.$stopLoading = (comp?: string): void => {
      if (comp && root.$data.loading.hasOwnProperty(comp)) {
        root.$set(root.$data.loading, comp, false);
      }
      const keys: string[] = Object.keys(root.$data.loading);

      if (!comp) {
        root.$data.loading = {};
        root.$data.anyLoading = false;
      }

      for (const key of keys) {
        if (root.$data.loading[key] === true) {
          return;
        }
      }
      root.$data.anyLoading = false;
    };

    Vue.prototype.$stopAllLoading = () => {
      root.$data.loading = {};
      root.$data.anyLoading = false;
    };
  },
};
