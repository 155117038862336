import { User } from '@/interfaces/models/User';
import UserApiService from '@/api/http/UserApiService';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import Filter from '@/interfaces/api/Filter';

interface UserState {
  items: User[];
  active: User | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: UserApiService = new UserApiService();

const state: UserState = {
  active: null,
  filter: {},
  items: [],
  pagination: { total: 0 },
};

const actions: ActionTree<UserState, RootState> = {
  store({ commit }, data: Partial<User>) {
    return api.store(data).then((res: AxiosResponse<User>) => {
      commit('addItem', res.data);
    });
  },
  storeRestricted({ commit }, data: Partial<User>) {
    return api.storeRestricted(data).then((res: AxiosResponse<User>) => {
      commit('addItem', res.data);
    });
  },
  update({ commit }, data: Partial<User>) {
    return api.update(data).then((res: AxiosResponse<User>) => {
      commit('update', res.data);
    });
  },
  updateRestricted({ commit }, data: Partial<User>) {
    return api.updateRestricted(data).then((res: AxiosResponse<User>) => {
      commit('update', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<User>) => {
      commit('setActive', res.data);
    });
  },
  fetch({ commit, state }, page?: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<User[] | Pagination<User>>) => {
      commit('setItems', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.delete(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<UserState> = {
  addItem(state: UserState, data: User) {
    state.items.push(data);
  },
  setActive(state: UserState, data: User) {
    state.active = data;
  },
  update(state: UserState, data: User) {
    state.items = [...state.items.filter((u: User) => u._id !== data._id), data];
  },
  setItems(state: UserState, data: Pagination<User>) {
    state.items = (data.data as User[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  removeItem(state: UserState, data: User) {
    state.items = state.items.filter((item: User) => item._id !== data._id);
  },
  setFilter(state: UserState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
