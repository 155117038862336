import ApiService from '@/api/http/ApiService';
import { Page } from '@/interfaces/api/Page';
import { SyncJob } from '@/interfaces/models/SyncJob';
import axios, { AxiosPromise } from 'axios';

export default class SyncApiService extends ApiService<any> {
  constructor() {
    super('sync');
  }
  public index(page?: Page | null | undefined, filter?: any): AxiosPromise<SyncJob[]> {
    return axios.get(`${this.getBaseUrl()}/jobs`, { params: { page: page?.page, limit: page?.limit, ...filter } });
  }

  public sync(config: any): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/sync`, config);
  }

  public copyVenue(config: any): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/venue/copy`, config);
  }

  public copyVenueOnly(config: any): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/venue/copy/venue-only`, config);
  }
}
