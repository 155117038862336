import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import Filter from '@/interfaces/api/Filter';
import PrintGroupsServiceApi from '@/api/http/PrintGroupsServiceApi';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';

interface PrintGroupsState {
  items: PrintGroups[];
  active: PrintGroups | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: PrintGroupsState = {
  items: [],
  active: null,
  filter: {},
  pagination: {
    total: 0,
  },
};

const api: PrintGroupsServiceApi = new PrintGroupsServiceApi();

const actions: ActionTree<PrintGroupsState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api
      .getPrintGroupsByVenue(page, state.filter)
      .then((res: AxiosResponse<PrintGroups[] | Pagination<PrintGroups>>) => {
        commit('setItems', res.data);
      });
  },
  store({ commit }, data: Partial<PrintGroups>) {
    return api.store(data).then((res: AxiosResponse<PrintGroups>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<PrintGroups>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<PrintGroups>) {
    return api.update(data).then((res: AxiosResponse<PrintGroups>) => {
      commit('update', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  destroy({ commit }, data: { _id: string }) {
    return api.destroyPrintGroups(data).then(() => {
      commit('removePrintGroups', { _id: data._id });
    });
  },
};

const mutations: MutationTree<PrintGroupsState> = {
  setItems(state: PrintGroupsState, data: Pagination<PrintGroups>) {
    state.items = (data.data as PrintGroups[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: PrintGroupsState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: PrintGroupsState, data: PrintGroups) {
    state!.items.push(data);
  },
  update(state: PrintGroupsState, data: PrintGroups) {
    state.items = [...(state.items as PrintGroups[]).filter((item: PrintGroups) => item._id !== data._id), data];
  },
  setActive(state: PrintGroupsState, data: PrintGroups) {
    state.active = data;
  },
  removePrintGroups(state: PrintGroupsState, data: PrintGroups) {
    state.items = state.items.filter((pg: PrintGroups) => pg._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
