import ApiService from '@/api/http/ApiService';
import { Page } from '@/interfaces/api/Page';
import axios, { AxiosPromise } from 'axios';
import { Receipt } from '@/interfaces/models/Receipt';

export class ReceiptApiService extends ApiService<any> {
  constructor() {
    super('payment', 'v3');
  }

  public index(page?: Page | null | undefined, filter?: any): AxiosPromise<Receipt[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }
    if (page) {
      return axios.get(`${this.getBaseUrl()}/receipts/?${query}`);
    }

    return axios.get(`${this.getBaseUrl()}/receipts/?${query}`);
  }

  public exportCustomer(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/export/customer?payment=${param.id}`, { responseType: 'arraybuffer' });
  }

  public exportMerchant(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/export/merchant?payment=${param.id}`, { responseType: 'arraybuffer' });
  }

  public exportCombined(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/export/combined?payment=${param.id}`, { responseType: 'arraybuffer' });
  }

  public exportFull(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/export/full?payment=${param.id}`, { responseType: 'arraybuffer' });
  }
}
