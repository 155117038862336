import CustomerGroupPropertyApiService from '@/api/http/CustomerGroupPropertyApiService';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { CustomerGroupProperty } from '@/interfaces/models/CustomerGroupProperty';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import { ActionTree, MutationTree } from 'vuex';

interface CustomerGroupProprtyState {
  items: CustomerGroupProperty[];
  active: CustomerGroupProperty | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: CustomerGroupProprtyState = {
  active: null,
  filter: {},
  items: [],
  pagination: { total: 0 },
};

const api: CustomerGroupPropertyApiService = new CustomerGroupPropertyApiService();

const actions: ActionTree<CustomerGroupProprtyState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api
      .index(page, state.filter)
      .then((res: AxiosResponse<CustomerGroupProperty[] | Pagination<CustomerGroupProperty>>) => {
        commit('setItems', res.data);
      });
  },
  store({ commit }, data: Partial<CustomerGroupProperty>) {
    return api.store(data).then((res: AxiosResponse<CustomerGroupProperty>) => {
      commit('addItem', res.data);
    });
  },
  update({ commit }, data: Partial<CustomerGroupProperty>) {
    return api.update(data).then((res: AxiosResponse<CustomerGroupProperty>) => {
      commit('update', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<CustomerGroupProperty>) => {
      commit('setActive', res.data);
    });
  },
};

const mutations: MutationTree<CustomerGroupProprtyState> = {
  addItem(state: CustomerGroupProprtyState, data: CustomerGroupProperty) {
    state.items.push(data);
  },
  setActive(state: CustomerGroupProprtyState, data: CustomerGroupProperty) {
    state.active = data;
  },
  update(state: CustomerGroupProprtyState, data: CustomerGroupProperty) {
    state.items = [...state.items.filter((u: CustomerGroupProperty) => u._id !== data._id), data];
  },
  setItems(state: CustomerGroupProprtyState, data: Pagination<CustomerGroupProperty>) {
    state.items = (data.data as CustomerGroupProperty[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: CustomerGroupProprtyState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
