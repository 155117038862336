import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import Filter from '@/interfaces/api/Filter';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import StatisticsApiService from '@/api/http/StatisticsApiService';
import SalesItem from '@/interfaces/models/SalesItem';

const api: StatisticsApiService = new StatisticsApiService();

interface SalesState {
  items: SalesItem[];
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: SalesState = {
  pagination: {
    total: 0,
  },
  filter: {},
  items: [],
};

const actions: ActionTree<SalesState, RootState> = {
  fetch({ commit, state }) {
    return api
      .getSales({ ...state.filter, hidden: true })
      .then((res: AxiosResponse<SalesItem[] | Pagination<SalesItem>>) => {
        commit('setItems', res.data);
      });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<SalesState> = {
  setItems(state: SalesState, data: Pagination<SalesItem>) {
    state.items = (data.data as SalesItem[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: SalesState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
