import ApiService from '@/api/http/ApiService';
import { Page } from '@/interfaces/api/Page';
import axios, { AxiosPromise } from 'axios';
import { EposJob } from '@/interfaces/models/EposJob';

export class EposJobsApiService extends ApiService<any> {
  constructor() {
    super('epos/jobs', 'v1');
  }

  public index(page?: Page | null | undefined, filter?: any): AxiosPromise<EposJob[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }
    if (page) {
      return axios.get(`${this.getBaseUrl()}/?page=${page.page}&limit=${page.limit}&${query}`);
    }

    return axios.get(`${this.getBaseUrl()}/_`);
  }
}
