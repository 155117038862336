import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import Filter from '@/interfaces/api/Filter';
import { AxiosResponse } from 'axios';
import StatisticsApiService from '@/api/http/StatisticsApiService';
import Statistic from '@/interfaces/models/Statistic';

const api: StatisticsApiService = new StatisticsApiService();

interface AnalyticsState {
  item: Statistic | null;
  filter: Filter;
}

const state: AnalyticsState = {
  filter: {},
  item: null,
};

const actions: ActionTree<AnalyticsState, RootState> = {
  fetch({ commit, state }) {
    return api.getAnalytics({ ...state.filter, hidden: true }).then((res: AxiosResponse<Statistic>) => {
      commit('setItems', res.data);
    });
  },
  fetchByGroup({ commit, state }) {
    // TODO: add customer group filter (key: group, value: @mixins/CustomerGroup)
    return api.getCustomerGroupAnalytics({ ...state.filter, hidden: true }).then((res: AxiosResponse<Statistic>) => {
      commit('setItems', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<AnalyticsState> = {
  setItems(state: AnalyticsState, data: Statistic) {
    state.item = (data as Statistic) || data;
  },
  setFilter(state: AnalyticsState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
