import _Vue from 'vue';

interface RecentAction {
  text: string;
  route: string;
  params?: any;
}

const MAX_RECENT: number = 10;

export const Action = {
  install(Vue: typeof _Vue): void {
    let recent: string | null | RecentAction[] = localStorage.getItem('recentActions') || [];
    if (recent && typeof recent === 'string') {
      recent = JSON.parse(recent as string);
    }

    const root: _Vue = new Vue({
      data: {
        recentActions: recent,
      },
    });

    Vue.prototype.$newRecentAction = (text: string, route: string, params?: any): void => {
      if (root.$data.recentActions.length === MAX_RECENT) {
        root.$data.recentActions.pop();
      }
      root.$data.recentActions = [{ text, route, params }, ...root.$data.recentActions];
    };
  },
};
