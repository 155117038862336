import { Payment } from '@/interfaces/models/Payment';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import PaymentApiService from '@/api/http/PaymentApiService';

interface PaymentState {
  items: Payment[];
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: PaymentState = {
  items: [],
  pagination: {
    total: 0,
  },
  filter: {},
};

const api: PaymentApiService = new PaymentApiService();

const actions: ActionTree<PaymentState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<Pagination<Payment>>) => {
      commit('setItems', res.data);
    });
  },
  refund({ commit }, data: { articles: string[]; order: string; isExternal: boolean }) {
    return api.refund(data).then((res: AxiosResponse<Payment>) => {
      commit('addItem', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<PaymentState> = {
  setItems(state: PaymentState, data: Pagination<Payment>) {
    state.items = (data.data as Payment[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  addItem(state: PaymentState, data: Payment) {
    state.items.push(data);
  },
  setFilter(state: PaymentState, filter: Filter) {
    state.filter = filter;
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
