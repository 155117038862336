import VConfirm from '@/components/shared/VConfirm.vue';
import vuetify from '@/plugins/vuetify';
import i18n from '@/i18n';

export const Confirm = {
  mounted: false,
  vue: null,
  $instance: null,

  install(Vue: any, options: object) {
    this.vue = Vue;
    Vue.prototype.$confirm = (message: string) => {
      this.mountIfNotMounted(message);
      return new Promise((resolve, reject) => {
        // @ts-ignore
        this.$instance.open(resolve, reject);
      });
    };
  },

  mountIfNotMounted(message: string) {
    if (this.mounted) {
      return;
    }

    // @ts-ignore

    // @ts-ignore
    const Confirm = this.vue.extend(VConfirm);
    const node = document.createElement('div');
    node.setAttribute('id', 'confirm');
    // @ts-ignore
    document.getElementById('app').appendChild(node);
    const instance = new Confirm({ el: '#confirm', vuetify, i18n });
    if (message && message.length > 0) {
      instance.setText(message);
    }
    instance.$mount(node);
    this.$instance = instance;
  },
};
