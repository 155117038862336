import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import Filter from '@/interfaces/api/Filter';
import { CupCode } from '@/interfaces/models/CupCode';
import { CupCodeApiService } from '@/api/http/CupCodeApiService';

interface CupCodeState {
  items: CupCode[];
  active: CupCode | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: CupCodeApiService = new CupCodeApiService();

const state: CupCodeState = {
  active: null,
  items: [],
  filter: {},
  pagination: { total: 0 },
};

const actions: ActionTree<CupCodeState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.getCupCodes(page, state.filter).then((res: AxiosResponse<CupCode[] | Pagination<CupCode>>) => {
      commit('setItems', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<CupCode>) => {
      commit('setActive', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  store({ commit }, data: Partial<CupCode>) {
    return api.store(data).then((res: AxiosResponse<CupCode>) => {
      commit('addItem', res.data);
      commit('setActive', res.data);
    });
  },
  generate({ commit }, data: Partial<CupCode>) {
    return api.generate(data).then((res: AxiosResponse<CupCode>) => {
      commit('addItem', res.data);
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<CupCode>) {
    return api.update(data).then((res: AxiosResponse<CupCode>) => {
      commit('update', res.data);
    });
  },
  activateCupCode({ commit }, data: { id: string }) {
    return api.activate(data).then((res: AxiosResponse<CupCode>) => {
      commit('update', res.data);
    });
  },
  deactivateCupCode({ commit }, data: { id: string }) {
    return api.deactivate(data).then((res: AxiosResponse<CupCode>) => {
      commit('update', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroyCupCode(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  toggleActive({ commit }, data: { customerGroups: string[]; numFrom: string; numTo: string; active: boolean }) {
    return api.toggleActive(data).then(() => {
      console.log(data);

      // commit('removeItem', { _id: data.id });
    });
  },
  deleteMany({ commit }, data: { customerGroups: string[]; numFrom: string; numTo: string }) {
    return api.deleteMany(data).then(() => {
      console.log(data);

      // commit('removeItem', { _id: data.id });
    });
  },
};

const mutations: MutationTree<CupCodeState> = {
  setItems(state: CupCodeState, data: Pagination<CupCode>) {
    state.items = (data.data as CupCode[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: CupCodeState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: CupCodeState, data: CupCode) {
    state!.items.push(data);
  },
  update(state: CupCodeState, data: CupCode) {
    state.items = state.items.map((item: CupCode) => {
      if (item._id === data._id) {
        item = data;
      }
      return item;
    });
  },
  setActive(state: CupCodeState, data: CupCode) {
    state.active = data;
  },
  removeItem(state: CupCodeState, data: CupCode) {
    state.items = state.items.filter((item: CupCode) => item._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
