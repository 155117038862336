import { Venue } from '@/interfaces/models/Venue';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import VenueApiService from '@/api/http/VenueApiService';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { getVenuesFromCookie, hasPermission } from '@/util/helper';
import { Permission } from '@/enums/Permission';
import cookie from 'js-cookie';

interface AppState {
  venues: Venue[];
}

const venueApi: VenueApiService = new VenueApiService();

const state: AppState = {
  venues: [],
};

const actions: ActionTree<AppState, RootState> = {
  fetchVenues({ commit, dispatch }) {
    return venueApi.index(null, { hidden: true }).then(async (res: AxiosResponse<Venue[] | Pagination<Venue>>) => {
      commit('setVenues', res.data);
      let venues: string[] | null = getVenuesFromCookie();
      const customerGroup: string | undefined = cookie.get('customerGroup');

      if (customerGroup && customerGroup !== '') {
        let venueCustomerResponse: AxiosResponse<Partial<Venue[]>>;

        try {
          venueCustomerResponse = await venueApi.getVenueIdsByCustomerGroup(customerGroup);
        } catch (e) {
          // console.error('Could not get venues by customers');
          throw e;
        }
        for (const v of venueCustomerResponse.data) {
          venues.push(v!._id);
        }
      }

      if (venues.length === 0 && !hasPermission(Permission.ADMIN)) {
        venues = null;
      }
      commit('filterVenues', venues);
      if (venues && venues.length === 1) {
        dispatch('venue/show', { id: venues[0] }, { root: true });
      }
    });
  },
  async filterVenues({ commit }) {
    let venues: string[] | null = getVenuesFromCookie();
    const customerGroup: string | undefined = cookie.get('customerGroup');

    if (customerGroup && customerGroup !== '') {
      let venueCustomerResponse: AxiosResponse<Partial<Venue[]>>;

      try {
        venueCustomerResponse = await venueApi.getVenueIdsByCustomerGroup(customerGroup);
      } catch (e) {
        // console.error('Could not get venues by customers');
        throw e;
      }
      for (const v of venueCustomerResponse.data) {
        venues.push(v!._id);
      }
    }
    if (venues.length === 0 && !hasPermission(Permission.ADMIN)) {
      venues = null;
    }
    commit('filterVenues', venues);
  },
  updateVenue({ commit }, venue: Venue) {
    commit('updateVenue', venue);
  },
};

const mutations: MutationTree<AppState> = {
  setVenues(state: AppState, data: Venue[]) {
    state.venues = data;
  },
  updateVenue(state: AppState, data: Venue) {
    if (data) {
      state.venues = state.venues.map((venue: Venue) => {
        if (data._id === venue._id) {
          return data;
        }
        return venue;
      });
    }
  },
  filterVenues(state: AppState, venues: string[]) {
    if (venues && venues.length > 0) {
      state.venues = [...state.venues.filter((v: Venue) => venues.indexOf(v._id) > -1)];
    }
    if (!venues) {
      state.venues = [];
    }
  },
};

export default {
  actions,
  state,
  mutations,
  namespaced: true,
};
