import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import { Venue } from '@/interfaces/models/Venue';
import VenueApiService from '@/api/http/VenueApiService';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import Filter from '@/interfaces/api/Filter';
import { Tag } from '@/interfaces/models/Tag';
import { Constrain } from '@/interfaces/models/Constrain';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import { CheckoutIntegrationProperties } from '@/interfaces/models/CheckoutIntegrationProperties';
import { CheckoutIntegration } from '@/interfaces/models/CheckoutIntegration';
import cookie from 'js-cookie';
import { Delfi } from '@/interfaces/models/Delfi';
import { DelfiApiService } from '@/api/http/DelfiApiService';

interface VenueState {
  items: Venue[];
  filter: Filter;
  active: Venue | null;
  pagination: {
    total: number;
  };
  needsRefresh: boolean;
  checkoutIntegration: CheckoutIntegration | null;
  checkoutIntegrationByType: CheckoutIntegrationProperties[];
  delfi: Delfi[] | null;
}

const api: VenueApiService = new VenueApiService();
const apiDelfi: DelfiApiService = new DelfiApiService();

const state: VenueState = {
  pagination: {
    total: 0,
  },
  active: null,
  filter: {},
  items: [],
  needsRefresh: false,
  checkoutIntegration: null,
  checkoutIntegrationByType: [],
  delfi: null,
};

const actions: ActionTree<VenueState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api
      .index(page, {
        ...state.filter,
        hidden: true,
      })
      .then((res: AxiosResponse<Venue[] | Pagination<Venue>>) => {
        commit('setItems', res.data);
      });
  },
  uploadImage({ commit }, data: { id: string; file: FormData }) {
    return api.uploadPhoto(data, data.file).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
    });
  },
  uploadNutritionTable({ commit }, data: { id: string; file: FormData }) {
    return api.uploadNutritionTable(data, data.file).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
    });
  },
  uploadSelfCertification({ commit }, data: { id: string; file: FormData }) {
    return api.uploadSelfCertification(data, data.file).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
    });
  },
  uploadTermsAndConditions({ commit }, data: { id: string; file: FormData }) {
    return api.uploadTermsAndConditions(data, data.file).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
    });
  },
  uploadImprint({ commit }, data: { id: string; file: FormData }) {
    return api.uploadImprint(data, data.file).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
    });
  },
  uploadExternalDeliveryIcon({ commit }, data: { id: string; file: FormData }) {
    return api.uploadExternalDeliveryIcon(data, data.file).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
    });
  },
  uploadPrivacy({ commit }, data: { id: string; file: FormData }) {
    return api.uploadPrivacy(data, data.file).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
    });
  },
  store({ commit }, data: Partial<Venue>) {
    return api.store(data).then((res: AxiosResponse<Venue>) => {
      commit('addItem', res.data);
      commit('setActive', res.data);
    });
  },
  verify({ commit }, data: { id: string }) {
    return api.verify(data);
  },
  publish({ commit }, data: { id: string }) {
    return api.publish(data);
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroy(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  serviceActivatedTogle({ commit }, data: { venue: string; isServiceActivated: boolean }) {
    return api.changServiceStatus(data).then((res: AxiosResponse<Venue>) => {
      commit('setServiceStatus', res.data);
    });
  },
  activateService({ commit }, data: { venue: string; isServiceActivated: boolean }) {
    return api.activateService(data).then((res: AxiosResponse) => {
      commit('setServiceStatus', { _id: data.venue, isServiceActivated: res.data });
    });
  },
  deactivateService({ commit }, data: { venue: string; isServiceActivated: boolean }) {
    return api.deactivateService(data).then((res: AxiosResponse) => {
      commit('setServiceStatus', { _id: data.venue, isServiceActivated: res.data });
    });
  },
  piggyLoyaltytoggle({ commit }, data: { venue: string; piggyLoyaltyEnabled: boolean }) {
    return api.changPiggyLoyalty(data).then((res: AxiosResponse<Venue>) => {
      commit('setPiggyLoyalty', res.data);
    });
  },
  softDelete({ commit }, data: { id: string }) {
    return api.softDelete(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  update({ commit }, data: Partial<Venue>) {
    return api.update(data).then((res: AxiosResponse<Venue>) => {
      commit('overwriteActive', res.data);
      commit('updateItem', res.data);
    });
  },
  setCheckoutIntegration({ commit }, data: { venue: string; payload: any }) {
    return api.updateCheckoutIntegrationProps(data).then((res: any) => {
      // commit('setCheckoutIntegration', res.data);
    });
  },
  getCheckoutIntegration({ commit }, data: { venue: string; integrationType: string }) {
    return api.getCheckoutIntegration(data).then((res: any) => {
      commit('setCheckoutIntegration', res.data);
    });
  },
  setDelfi({}, data: { payload: Partial<Delfi> }) {
    return apiDelfi.storeDelfi(data).then((res: AxiosResponse<Delfi>) => {});
  },
  updateDelfi({}, data: { venue: string; payload: Partial<Delfi> }) {
    return apiDelfi.updateDelfi(data).then((res: AxiosResponse<Delfi>) => {});
  },
  resetCheckoutIntegration({ commit }) {
    commit('setCheckoutIntegration', null);
  },
  panic({ commit }, data: Partial<Venue>) {
    // @ts-ignore
    return api.panic(data).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
      commit('updateItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.showSimple(data).then((res: AxiosResponse<Venue>) => {
      cookie.set('venue', res.data._id, { expires: 1 });
      commit('setActive', res.data);
      // commit('removeItem', res.data);
      // commit('addItem', res.data);
    });
  },
  showSimple({ commit }, data: { id: string }) {
    return api.showSimple(data).then((res: AxiosResponse<Venue>) => {
      commit('setActive', res.data);
      // commit('removeItem', res.data);
      // commit('addItem', res.data);
    });
  },
  showPayment({ commit }, data: { id: string }) {
    return api.showPayment(data).then((res: AxiosResponse<Venue>) => {
      commit('setPayment', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  setActive({ commit }, item: Venue) {
    commit('setActive', item);
  },
  triggerRefresh({ commit }) {
    commit('setRefresh', true);
  },
  refreshed({ commit }) {
    commit('setRefresh', false);
  },
  copyFromToVenue({ commit }, fromTo: { from: string; to: string[] }) {
    return api.copyFromToVenue(fromTo);
  },
};

const mutations: MutationTree<VenueState> = {
  setItems(state: VenueState, data: Pagination<Venue>) {
    state.items = (data.data as Venue[]) || data;
    if (Number.isInteger(data.total)) {
      state.pagination.total = data.total;
    }
  },
  setServiceStatus(state: VenueState, data: { _id: string; isServiceActivated: boolean }) {
    const venue = state.items.find((v: Venue) => v._id === data._id);
    venue!.isServiceActivated = data.isServiceActivated;
  },
  setPiggyLoyalty(state: VenueState, data: { _id: string; piggyLoyaltyEnabled: boolean }) {
    const venue = state.items.find((v: Venue) => v._id === data._id);
    venue!.piggyLoyaltyEnabled = data.piggyLoyaltyEnabled;
  },
  addItem(state: VenueState, data: Venue) {
    state.items.push(data);
  },
  setFilter(state: VenueState, data: Filter) {
    state.filter = data;
  },
  setActive(state: VenueState, data: Venue) {
    if (!state.active) {
      state.active = data;
      return;
    }
    if (state.active && state.active._id === data._id && !state.active.paymentInitialised) {
      state.active = data;
    }
    if (state.active && state.active._id !== data._id) {
      state.active = data;
    }
  },
  setCheckoutIntegration(state: VenueState, data: any) {
    state.checkoutIntegration = data;
  },
  setDelfi(state: VenueState, data: any) {
    state.delfi = data;
  },
  overwriteActive(state: VenueState, data: Venue) {
    state.active = data;
  },
  updateItem(state: VenueState, data: Venue) {
    state.items = [...state.items.filter((item: Venue) => item._id !== data.id), data];
  },
  addTag(state: VenueState, data: Tag) {
    (state.active!.tags as Tag[]).push(data);
  },
  addConstrain(state: VenueState, data: Constrain) {
    (state.active!.constrains as Constrain[]).push(data);
  },
  addPrintGroups(state: VenueState, data: PrintGroups) {
    (state.active!.printGroups as PrintGroups[]).push(data);
  },
  updateTag(state: VenueState, data: Tag) {
    state.active!.tags = [...(state.active!.tags as Tag[]).filter((item: Tag) => item._id !== data._id), data];
  },
  updateConstrain(state: VenueState, data: Constrain) {
    state.active!.constrains = [
      ...(state.active!.constrains as Constrain[]).filter((item: Constrain) => item._id !== data._id),
      data,
    ];
  },
  updatePrintGroups(state: VenueState, data: PrintGroups) {
    state.active!.printGroups = [
      ...(state.active!.printGroups as PrintGroups[]).filter((item: PrintGroups) => item._id !== data._id),
      data,
    ];
  },
  removeItem(state: VenueState, data: Venue) {
    state.items = state.items.filter((item: Venue) => item._id !== data._id);
  },
  setRefresh(state: VenueState, val: boolean) {
    state.needsRefresh = val;
  },
  setPayment(state: VenueState, data: Venue) {
    state.active!.paymentInitialised = true;
    state.active!.provisionFixedRate = data.provisionFixedRate;
    state.active!.provisionFixedRate = data.provisionFixedRate;
    state.active!.provisionRate = data.provisionRate;
    state.active!.ccSecretKey = data.ccSecretKey;
    state.active!.ccMerchantId = data.ccMerchantId;
    state.active!.ppSecretKey = data.ppSecretKey;
    state.active!.ppMerchantId = data.ppMerchantId;
    state.active!.sepaSecretKey = data.sepaSecretKey;
    state.active!.sepaMerchantId = data.sepaMerchantId;
    state.active!.sepaMerchantName = data.sepaMerchantName;
    state.active!.sepaCreditorId = data.sepaCreditorId;
    state.active!.sepaPaymentMandat = data.sepaPaymentMandat;
    state.active!.sbMerchantId = data.sbMerchantId;
    state.active!.apMerchantId = data.apMerchantId;
    state.active!.gpMerchantId = data.gpMerchantId;
    state.active!.ppEmail = data.ppEmail;
    state.active!.vrAccessToken = data.vrAccessToken;
    state.active!.legal = data.legal;
    state.active!.deliveryProviders = data.deliveryProviders;
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
