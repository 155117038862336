import Filter from '@/interfaces/api/Filter';
import { Receipt } from '@/interfaces/models/Receipt';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import { ReceiptApiService } from '@/api/http/ReceiptApiService';
import VenueApiService from '@/api/http/VenueApiService';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';

interface ReceiptState {
  filter: Filter;
  endOfDayFilter: Filter;
  items: Receipt[];
  endOfDayItems: Receipt[];
  pagination: {
    total: number;
  };
}

const state: ReceiptState = {
  filter: {},
  endOfDayFilter: {},
  items: [],
  endOfDayItems: [],
  pagination: {
    total: 0,
  },
};

const api: ReceiptApiService = new ReceiptApiService();
const venueApi: VenueApiService = new VenueApiService();
const actions: ActionTree<ReceiptState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<Receipt[] | Pagination<Receipt>>) => {
      commit('setItems', res.data);
    });
  },
  fetchEndOfDay({ commit, state }) {
    return venueApi.endOfDay(state.endOfDayFilter).then((res: AxiosResponse<Receipt[]>) => {
      commit('setEndOfDayItems', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  setEndOfDayFilter({ commit }, filter: Filter) {
    commit('setEndOfDayFilter', filter);
  },
};

const mutations: MutationTree<ReceiptState> = {
  setItems(state: ReceiptState, data: Pagination<Receipt>) {
    state.items = (data.data as Receipt[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setEndOfDayItems(state: ReceiptState, data: Receipt[]) {
    state.endOfDayItems = data;
  },
  setFilter(state: ReceiptState, filter: Filter) {
    state.filter = filter;
  },
  setEndOfDayFilter(state: ReceiptState, filter: Filter) {
    state.endOfDayFilter = filter;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
