import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Loyalty } from '@/interfaces/models/Loyalty';
export class LoyaltyApiService extends ApiService<any> {
  constructor() {
    super('loyalty', 'v1');
  }

  public index() {
    return axios.get(`${this.version}/loyalty/`);
  }

  public show(param: { id: string }): AxiosPromise<Loyalty> {
    return axios.get(`${this.version}/loyalty/${param.id}`);
  }

  public update(param: any): AxiosPromise<Loyalty> {
    return axios.patch(`${this.version}/loyalty/${param.id}`, param);
  }

  public destroy(param: { id: string }): AxiosPromise<any> {
    return axios.delete(`${this.version}/loyalty/${param.id}`);
  }

  public store(data: Partial<Loyalty>): AxiosPromise<Loyalty> {
    return axios.post(`${this.version}/loyalty/`, data);
  }
}
