import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Printer } from '@/interfaces/models/Printer';
import PrinterApiService from '@/api/http/PrinterApiService';
import Filter from '@/interfaces/api/Filter';
import { AxiosResponse } from 'axios';
import { PrinterResponse } from '@/interfaces/api/PrinterResponse';
import moment from 'moment';

interface PrinterState {
  items: Printer[];
  filter: Filter;
}

const api: PrinterApiService = new PrinterApiService();

const state: PrinterState = {
  items: [],
  filter: {},
};

function createEntry(data: PrinterResponse, venueId: string, key: string) {
  const entry: Printer = data[venueId][key];

  if (entry.articles) {
    entry.key = key;
    entry.venueId = venueId;
    entry.articles = Object.values(entry.articles);
    entry.articleCount = entry.articles.length;
    const now: moment.Moment = moment();
    const heartbeat: moment.Moment = moment(entry.heartbeat);
    entry.lastSignal = {
      value: heartbeat.from(now),
      date: heartbeat.format('YYYY-MM-DD HH:mm'),
      isOnline: now.diff(heartbeat, 'minutes') <= 5,
    };
  }

  return entry;
}

function mapPrinterResponse(data: PrinterResponse): Printer[] {
  const printer: Printer[] = [];
  if (typeof data === 'object' && data.hasOwnProperty(state.filter.venue)) {
    const keys: string[] = Object.keys(data[state.filter.venue]);
    for (const key of keys) {
      const entry: Printer = createEntry(data, state.filter.venue, key);
      if (entry.articles) {
        printer.push(entry);
      }
    }
  }

  return printer;
}

function mapPrinterAllResponse(data: PrinterResponse): Printer[] {
  const printer: Printer[] = [];
  if (typeof data === 'object') {
    const venueIds: string[] = Object.keys(data);
    for (const venueId of venueIds) {
      const keys: string[] = Object.keys(data[venueId]);
      for (const key of keys) {
        const entry: Printer = createEntry(data, venueId, key);
        if (entry.articles) {
          printer.push(entry);
        }
      }
    }
  }

  return printer;
}

const actions: ActionTree<PrinterState, RootState> = {
  fetch({ commit, state }) {
    return api.fetch(state.filter).then((res: AxiosResponse<PrinterResponse>) => {
      commit('setItems', mapPrinterResponse(res.data));
    });
  },
  fetchAll({ commit, state }) {
    return api.fetchAll(state.filter).then((res: AxiosResponse<PrinterResponse>) => {
      commit('setItems', mapPrinterAllResponse(res.data));
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  update({}, data: { venue: string; printer: string; articles: string[] }) {
    return api.update(data);
  },
  restart({}, data: { venue: string; printer: string }) {
    return api.restart(data);
  },
};

const mutations: MutationTree<PrinterState> = {
  setFilter(state: PrinterState, filter: Filter) {
    state.filter = filter;
  },
  setItems(state: PrinterState, data: Printer[]) {
    state.items = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
