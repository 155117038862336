import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { StatisticOverviewItem } from '@/interfaces/models/StatisticOverviewItem';
import SyncApiService from '@/api/http/SyncApiService';
import { SyncJob } from '@/interfaces/models/SyncJob';

const api: SyncApiService = new SyncApiService();

interface SyncState {
  items: SyncJob[];
  filter: Filter;
  pagination: {
    total: number;
  };
  overview: StatisticOverviewItem[];
}

const state: SyncState = {
  pagination: {
    total: 0,
  },
  filter: {},
  items: [],
  overview: [],
};

const actions: ActionTree<SyncState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api.index(page, { ...state.filter }).then((res: AxiosResponse<SyncJob[] | Pagination<SyncJob>>) => {
      commit('setItems', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) {
        delete filter[key];
      }
    });
    commit('setFilter', filter);
  },
  setItem({ commit, state }, data: SyncJob) {
    commit('updateStatusItem', data);
  },
};

const mutations: MutationTree<SyncState> = {
  setItems(state: SyncState, data: Pagination<any>) {
    state.items = (data.data as SyncJob[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: SyncState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
