import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { VenueTransactions } from '@/interfaces/models/VenueTransactions';
import PaymentApiService from '@/api/http/PaymentApiService';
import { AxiosResponse } from 'axios';

interface VenueTransactionsState {
  items: VenueTransactions | null;
}

const api: PaymentApiService = new PaymentApiService();

const state: VenueTransactionsState = {
  items: null,
};

const actions: ActionTree<VenueTransactionsState, RootState> = {
  fetch({ commit }, data: { venue: string }) {
    return api.venueTransactions({ venue: data.venue }).then((res: AxiosResponse<VenueTransactions>) => {
      commit('setItems', res.data);
    });
  },
};

const mutations: MutationTree<VenueTransactionsState> = {
  setItems(state: VenueTransactionsState, data: VenueTransactions) {
    state.items = data;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
