
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import items from './items';
import { isCustomerGroup } from '@/util/helper';

interface NavbarItem {
  key: string;
  title: string;
  icon?: string;
  to: string;
  can: string;
  children?: NavbarItem[];
}

@Component
export default class Navbar extends Vue {
  @Prop({ type: Boolean, required: true }) public drawer!: boolean;

  public navbarItems: any = items;

  public drawerVisible: boolean = true;

  public isCustomerGroup(item: any): boolean {
    return !item?.hasOwnProperty('isCustomerGroup') || isCustomerGroup(item.isCustomerGroup);
  }

  @Watch('drawer')
  public onDrawerChange() {
    this.drawerVisible = this.drawer;
  }

  @Watch('drawerVisible')
  public onDrawerVisibleChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.$emit('drawer', val);
    }
  }
}
