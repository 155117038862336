import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { Category } from '@/interfaces/models/Category';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { Article } from '@/interfaces/models/Article';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { ChangeCategoryRequest } from '@/interfaces/models/ChangeCategoryRequest';
import { SubscriptionCategory } from '@/interfaces/models/SubscriptionCategories';
import { Subscription } from '@/interfaces/models/Subscription';

const api: FoodcardApiService = new FoodcardApiService();

interface FoodcardState {
  subscriptionCategories: SubscriptionCategory[];
  categories: Category[];
  activeSubscriptionCategory: SubscriptionCategory | null;
  activeSubscription: Subscription | null;
  activeCategory: Category | null;
  activeOption: OptionGroup | null;
  subscriptionCategoriesPagination: {
    total: number;
  };
  categoryPagination: {
    total: number;
  };
  optionPagination: {
    total: number;
  };
  subscriptionCategoriesFilter: any;
  categoryFilter: any;
  articleFilter: any;
  articleOfSubscriptionFilter: any;
  optionFilter: any;
  optionArticleFilter: any;
  activeArticle: Article | null;
  multiplyCategory: string[];
  articlePagination: {
    total: number;
  };
  options: OptionGroup[];
  activeOptions: OptionGroup[];
  articles: Article[];
  articlesOfSubscription: Article[];
  subscriptions: Subscription[];
  articleNames: Array<Partial<Article>>;
}

const state: FoodcardState = {
  subscriptionCategories: [],
  activeOption: null,
  optionFilter: {},
  categories: [],
  activeSubscriptionCategory: null,
  activeSubscription: null,
  activeCategory: null,
  activeArticle: null,
  multiplyCategory: [],
  optionPagination: {
    total: 0,
  },
  subscriptionCategoriesPagination: {
    total: 0,
  },
  categoryPagination: {
    total: 0,
  },
  articlePagination: {
    total: 0,
  },
  subscriptionCategoriesFilter: {},
  categoryFilter: {},
  articleFilter: {},
  optionArticleFilter: {},
  articleOfSubscriptionFilter: {},
  subscriptions: [],
  articles: [],
  articlesOfSubscription: [],
  options: [],
  activeOptions: [],
  articleNames: [],
};

const getters: GetterTree<FoodcardState, RootState> = {
  articlesByCategories(state: FoodcardState): Article[] {
    let articles: Article[] = [];
    if (state.multiplyCategory.length > 0) {
      for (const category of state.categories) {
        if (state.multiplyCategory.includes(category._id)) {
          articles = [...articles, ...category.articles];
        }
      }
    }
    return articles;
  },
};

const actions: ActionTree<FoodcardState, RootState> = {
  fetchSubscriptionCategories({ commit, state }, page?: Page) {
    return api
      .getSubscriptionCategories(page, state.categoryFilter)
      .then((res: AxiosResponse<SubscriptionCategory[] | Pagination<SubscriptionCategory>>) => {
        commit('setSubscriptionCategories', res.data);
      });
  },

  fetchSubscriptions({ commit }, page?: Page) {
    return api.getSubscriptions().then((res: AxiosResponse<Subscription[]>) => {
      commit('setSubscriptions', res.data);
    });
  },

  // fetchSubscriptionsById({ commit, state }, page?: Page) {
  //   return api
  //     .getSubscriptions()
  //     .then((res: AxiosResponse<Subscription[]>) => {
  //       commit('setSubscriptions', res.data);
  //     });
  // },

  fetchCategories({ commit, state }, page?: Page) {
    return api
      .getCategories(page, state.categoryFilter)
      .then((res: AxiosResponse<Category[] | Pagination<Category>>) => {
        commit('setCategories', res.data);
      });
  },
  fetchArticles({ commit, state }, page?: Page) {
    return api.getArticles(page, state.articleFilter).then((res: AxiosResponse<Article[] | Pagination<Article>>) => {
      commit('setArticles', res.data);
    });
  },
  resetArticles({ commit }) {
    commit('setArticles', { data: [], total: 0 });
  },
  resetArticlesOfSubscription({ commit }) {
    commit('setArticlesOfSubscription', { data: [], total: 0 });
  },
  setArticleOfSubscription({ commit }, articles: Partial<Article[]>) {
    commit('setArticlesOfSubscription', { data: articles });
  },
  fetchCategoriesByVenue({ commit }, venueId) {
    return api.getCategoriesArticle(venueId).then((res: AxiosResponse<Article[]>) => {
      commit('setCategories', res.data);
    });
  },
  searchArticles({ commit, state }, data: { venue: string; page?: Page }) {
    return api
      .searchArticles(data.page, data.venue, state.articleFilter)
      .then((res: AxiosResponse<Article[] | Pagination<Article>>) => {
        commit('setArticles', res.data);
      });
  },
  concatArticlesOfSubscription({ commit, state }, data: { venue: string; page?: Page }) {
    return api
      .searchArticles(data.page, data.venue, state.articleOfSubscriptionFilter)
      .then((res: AxiosResponse<Article[] | Pagination<Article>>) => {
        commit('concatArticlesOfSubscription', res.data);
      });
  },
  fetchOptions({ commit }) {
    return api.getOptions(state.optionFilter).then((res: AxiosResponse<OptionGroup[]>) => {
      commit('setOptions', res.data);
    });
  },

  fetchPaginatedOptions({ commit }, page?: Page) {
    return api
      .getPaginatedOptions(page, state.optionFilter)
      .then((res: AxiosResponse<OptionGroup[] | Pagination<OptionGroup>>) => {
        commit('setOptions', res.data);
      });
  },
  filterOptionsByIds({ state, commit }, ids: any) {
    const options = state.options.filter((option: OptionGroup) => {
      const foundOption = ids.find((id: string) => id === option._id);
      if (foundOption) {
        return option;
      }
    });
    commit('setActiveOptions', options);
  },
  concatPaginatedOptions({ commit }, page?: Page) {
    return api
      .getPaginatedOptions(page, state.optionFilter)
      .then((res: AxiosResponse<OptionGroup[] | Pagination<OptionGroup>>) => {
        commit('concatOptions', res.data);
      });
  },
  setOptionsByArticle({ commit }) {
    commit('setOptions', [...state.activeOptions]);
  },
  fetchArticleNames({ commit }, data: { venue: string }) {
    return api.getArticleNames(data).then((res: AxiosResponse<Array<Partial<Article>>>) => {
      commit('setArticleNames', res.data);
    });
  },
  fetchOptionByArticleId({ commit }, data: { articleId: string; categoryId: string }) {
    return api.getOptionByArticleId(data).then((res: any) => {
      commit('setOptions', res.data);
      commit('setActiveOptions', res.data);
    });
  },
  resetOptions({ commit }) {
    commit('setOptions', { data: [], total: 0 });
  },
  setCategoryFilter({ commit }, filter: any) {
    commit('setCategoryFilter', filter);
  },
  setArticleFilter({ commit }, filter: any) {
    commit('setArticleFilter', filter);
  },
  setArticleOfSubscriptionFilter({ commit }, filter: any) {
    commit('setArticlesOfSubscriptionFilter', filter);
  },
  setOptionFilter({ commit }, filter: any) {
    commit('setOptionFilter', filter);
  },
  setOptionArticleFilter({ commit }, filter: any) {
    commit('setOptionArticleFilter', filter);
  },
  storeOption({ commit }, data: Partial<OptionGroup>) {
    return api.storeOption(data).then((res: AxiosResponse<OptionGroup>) => {
      commit('storeOption', res.data);
    });
  },
  storeOptionArticle({ commit }, data: Partial<OptionGroup>): Promise<OptionArticle> {
    return api.storeOptionArticle(data).then((res: AxiosResponse<OptionArticle>) => {
      commit('storeOptionArticle', res.data);

      return res.data;
    });
  },
  updateOptionArticle({ commit }, data: Partial<OptionGroup>) {
    return api.updateOptionArticle(data).then((res: AxiosResponse<OptionArticle>) => {
      commit('updateOptionArticle', res.data);
    });
  },
  deleteOptionArticle({ commit }, data: { group: string; id: string }) {
    return api.deleteOptionArticle(data).then(() => {
      commit('deleteOptionArticle', data);
    });
  },
  uploadOptionArticleImage({ commit }, data: { group: string; id: string; image: FormData }) {
    return api.uploadOptionArticleImage(data).then((res: AxiosResponse<OptionArticle>) => {
      commit('updateOptionArticle', res.data);
    });
  },
  uploadOptionImage({ commit }, data: { id: string; image: FormData }) {
    return api.uploadOptionImage(data).then((res: AxiosResponse<OptionGroup>) => {
      commit('updateOption', res.data);
    });
  },
  storeArticle({ commit }, data: Partial<Article>) {
    return api.storeArticle(data).then((res: AxiosResponse<{ articles: Article[] }>) => {
      commit('setActiveArticle', res.data.articles.pop());
    });
  },
  showArticle({ commit }, data: { id: string; category: string }) {
    return api.showArticle(data).then((res: AxiosResponse<Article>) => {
      commit('setActiveArticle', res.data);
    });
  },
  showOption({ commit }, data: { id: string }) {
    return api.showOption(data).then((res: AxiosResponse<OptionGroup>) => {
      commit('setActiveOption', res.data);
    });
  },
  copyOption({ commit }, data: { id: string }) {
    return api.copyOption(data).then((res: AxiosResponse<OptionGroup>) => {
      commit('setActiveOption', res.data);
    });
  },
  setActiveOption({ commit }, data: OptionGroup) {
    commit('setActiveOption', data);
  },
  resetOption({ commit }) {
    commit('setActiveOption', null);
  },
  updateArticle({ commit }, data: Partial<Article>) {
    return api.updateArticle(data).then((res: AxiosResponse<Article>) => {
      commit('setActiveArticle', res.data);
      commit('updateArticle', res.data);
    });
  },
  changeCategory({ commit }, data: ChangeCategoryRequest) {
    return api.changeCategory(data);
  },
  copyArticle({ commit }, data: ChangeCategoryRequest) {
    return api.copyArticle(data);
  },
  activateOptionArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.activateOptionArticle(data).then(() => {
      commit('toggleOptionArticleActivation', { value: true, articles: data.articles });
    });
  },
  deactivateOptionArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.deactivateOptionArticle(data).then(() => {
      commit('toggleOptionArticleActivation', { value: false, articles: data.articles });
    });
  },
  makeArticleOptionVisible({ commit }, data: { venue: string; articles: string[] }) {
    return api.makeArticleOptionVisible(data).then(() => {
      commit('toggleOptionArticleVisibility', { value: true, articles: data.articles });
    });
  },
  hideOptionArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.hideOptionArticle(data).then(() => {
      commit('toggleOptionArticleVisibility', { value: false, articles: data.articles });
    });
  },
  activateArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.activateArticle(data).then(() => {
      commit('toggleArticleActivation', { value: true, articles: data.articles });
    });
  },
  deactivateArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.deactivateArticle(data).then(() => {
      commit('toggleArticleActivation', { value: false, articles: data.articles });
    });
  },
  makeControlArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.makeControlArticle(data).then(() => {
      commit('toggleArticleControl', { value: true, articles: data.articles });
    });
  },
  undoControlArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.undoControlArticle(data).then(() => {
      commit('toggleArticleControl', { value: false, articles: data.articles });
    });
  },
  makeArticleVisible({ commit }, data: { venue: string; articles: string[] }) {
    return api.makeArticleVisible(data).then(() => {
      commit('toggleArticleVisibility', { value: true, articles: data.articles });
    });
  },
  hideArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.hideArticle(data).then(() => {
      commit('toggleArticleVisibility', { value: false, articles: data.articles });
    });
  },
  makeOptionVisible({ commit }, data: { venue: string; options: string[] }) {
    return api.makeOptionsVisible(data).then(() => {
      commit('toggleOptionVisibility', { value: true, options: data.options });
    });
  },
  hideOption({ commit }, data: { venue: string; options: string[] }) {
    return api.hideOptions(data).then(() => {
      commit('toggleOptionVisibility', { value: false, options: data.options });
    });
  },
  makeCategoryVisible({ commit }, data: { id: string }) {
    return api.makeCategoryVisible(data).then(() => {
      commit('toggleCategoryVisibility', { ...data, value: true });
    });
  },
  hideCategory({ commit }, data: { id: string }) {
    return api.hideCategory(data).then(() => {
      commit('toggleCategoryVisibility', { ...data, value: false });
    });
  },
  storeCategory({ commit }, data: Partial<Category>) {
    return api.storeCategory(data).then((res: AxiosResponse<{ articleCategories: Category[] }>) => {
      const category: Category = res.data.articleCategories.pop()!;
      commit('addCategory', category);
      commit('setActiveCategory', category);
    });
  },
  storeSubscriptionCategory({ commit }, data: Partial<SubscriptionCategory>) {
    return api.storeSubscriptionCategory(data).then((res: AxiosResponse) => {
      const subscriptionCategory: SubscriptionCategory = res.data;
      commit('addSubscriptionCategory', subscriptionCategory);
      commit('setActiveSubscriptionCategory', subscriptionCategory);
    });
  },
  storeSubscription({ commit }, data: Partial<Subscription>) {
    return api.storeSubscription(data).then((res: AxiosResponse) => {
      const subscription: Subscription = res.data;
      commit('addSubscription', subscription);
      commit('setActiveSubscription', subscription);
    });
  },
  destroySubscriptionCategory({ commit }, data: { _id: string }) {
    return api.destroySubscriptionCategory(data).then(() => {
      commit('removeSubscriptionCategory', { _id: data._id });
    });
  },
  destroySubscription({ commit }, data: { _id: string }) {
    return api.destroySubscription(data).then(() => {
      commit('removeSubscription', { _id: data._id });
    });
  },
  destroyCategory({ commit }, data: { id: string }) {
    return api.destroyCategory(data).then(() => {
      commit('removeCategory', { _id: data.id });
    });
  },
  updateCategory({ commit }, data: Partial<Category>) {
    return api.updateCategory(data).then((res: AxiosResponse<Category>) => {
      commit('setActiveCategory', res.data);
      commit('updateCategory', res.data);
    });
  },
  updateSubscriptionCategory({ commit }, data: Partial<SubscriptionCategory>) {
    return api.updateSubscriptionCategory(data).then((res: AxiosResponse<SubscriptionCategory>) => {
      commit('setActiveSubscriptionCategory', res.data);
      commit('updateSubscriptionCategory', res.data);
    });
  },
  addSubscriptionToSubscriptionCategory({ commit }, data: { subscriptionCategory: string; subscription: string }) {
    return api.addSubscriptionToSubscriptionCategory(data);
  },
  removeSubscriptionToSubscriptionCategory({ commit }, data: { subscriptionCategory: string; subscription: string }) {
    return api.removeSubscriptionToSubscriptionCategory(data);
  },
  updateSubscription({ commit }, data: Partial<Subscription>) {
    return api.updateSubscription(data).then((res: AxiosResponse<Subscription>) => {
      commit('setActiveSubscription', res.data);
      commit('updateSubscription', res.data);
    });
  },
  updateOption({ commit }, data: Partial<OptionGroup>) {
    return api.updateOption(data).then((res: AxiosResponse<OptionGroup>) => {
      commit('updateOption', res.data);
    });
  },
  destroyOption({ commit }, data: { id: string }) {
    return api.destroyOption(data).then(() => {
      commit('removeOption', { _id: data.id });
    });
  },
  destroyOptions({ commit }, data: { ids: string[] }) {
    return api.destroyGroupOfOption(data).then((res) => {
      commit('removeOptions', data.ids);
    });
  },
  uploadCategoryImage({ commit }, data: { id: string; venue: string; image: FormData }) {
    return api.uploadCategoryImage(data).then((res: AxiosResponse<Category>) => {
      commit('setActiveCategory', res.data);
    });
  },
  uploadSubscriptionCategoryImage({ commit }, data: { _id: string; image: FormData }) {
    return api.uploadSubscriptionCategoryImage(data).then((res: AxiosResponse<SubscriptionCategory>) => {
      commit('setActiveSubscriptionCategory', res.data);
    });
  },
  setActiveSubscriptionCategory({ commit }, data: SubscriptionCategory) {
    commit('setActiveSubscriptionCategory', data);
  },
  setActiveSubscription({ commit }, data: SubscriptionCategory) {
    commit('setActiveSubscription', data);
  },
  setActiveCategory({ commit }, data: Category) {
    commit('setActiveCategory', data);
  },
  uploadCategoryIcon({ commit }, data: { id: string; venue: string; icon: FormData }) {
    return api.uploadCategoryIcon(data).then((res: AxiosResponse<Category>) => {
      commit('setActiveCategory', res.data);
    });
  },
  uploadArticleImage({ commit }, data: { id: string; category: string; image: FormData }) {
    return api.uploadArticleImage(data).then((res: AxiosResponse<Article>) => {
      commit('setActiveArticle', res.data);
      commit('updateArticle', res.data);
    });
  },
  uploadArticleIcon({ commit }, data: { id: string; category: string; image: FormData }) {
    return api.uploadArticleIcon(data).then((res: AxiosResponse<Article>) => {
      commit('setActiveArticle', res.data);
      commit('updateArticle', res.data);
    });
  },
  uploadArticleBanner({ commit }, data: { id: string; category: string; image: FormData }) {
    return api.uploadArticleBanner(data).then((res: AxiosResponse<Article>) => {
      commit('setActiveArticle', res.data);
      commit('updateArticle', res.data);
    });
  },
  destroyArticle({ commit, state }, data: { id: string; category: string }) {
    return api.destroyArticle({ ...data }).then(() => {
      commit('removeArticle', { _id: data.id });
    });
  },
};

const mutations: MutationTree<FoodcardState> = {
  addCategory(state: FoodcardState, data: Category) {
    state.categories.push(data);
  },
  addSubscriptionCategory(state: FoodcardState, data: SubscriptionCategory) {
    state.subscriptionCategories.push(data);
  },
  addSubscription(state: FoodcardState, data: Subscription) {
    state.subscriptions.push(data);
  },
  storeOption(state: FoodcardState, data: OptionGroup) {
    state.options.push(data);
  },
  setActiveSubscriptionCategory(state: FoodcardState, data: SubscriptionCategory) {
    state.activeSubscriptionCategory = data;
  },
  setActiveSubscription(state: FoodcardState, data: Subscription) {
    state.activeSubscription = data;
  },
  setActiveCategory(state: FoodcardState, data: Category) {
    state.activeCategory = data;
  },
  setSubscriptionCategories(state: FoodcardState, data: Pagination<SubscriptionCategory>) {
    state.subscriptionCategories = (data.data as SubscriptionCategory[]) || data;
    if (data.total) {
      state.categoryPagination.total = data.total;
    }
  },
  setSubscriptions(state: FoodcardState, data: Subscription[]) {
    state.subscriptions = (data as Subscription[]) || data;
  },
  setCategories(state: FoodcardState, data: Pagination<Category>) {
    state.categories = (data.data as Category[]) || data;
    state.categories.sort((a: Category, b: Category) => a.sortOrder - b.sortOrder);
    if (data.total) {
      state.categoryPagination.total = data.total;
    }
  },
  setArticles(state: FoodcardState, data: Pagination<Article>) {
    state.articles = (data.data as Article[]) || data;
    if (data.total) {
      state.articlePagination.total = data.total;
    }
  },
  setArticlesOfSubscription(state: FoodcardState, data: Pagination<Article>) {
    state.articlesOfSubscription = (data.data as Article[]) || data;
  },
  removeArticle(state: FoodcardState, data: { _id: string }) {
    state.articles = state.articles.filter((art: Article) => art._id !== data._id);
  },
  updateArticle(state: FoodcardState, data: Article) {
    state.articles = [...state.articles.map((item: Article) => (item._id !== data._id ? item : { ...item, ...data }))];
  },
  updateOption(state: FoodcardState, data: OptionGroup) {
    state.options = [...state.options.filter((o: OptionGroup) => o._id !== data._id), data];
  },
  setOptions(state: FoodcardState, data: Pagination<OptionGroup>) {
    state.options = (data.data as OptionGroup[]) || data;

    if (data.total) {
      state.optionPagination.total = data.total;
    }
  },
  setActiveOptions(state: FoodcardState, data: Pagination<OptionGroup>) {
    state.activeOptions = (data.data as OptionGroup[]) || data;
  },
  concatOptions(state: FoodcardState, data: Pagination<OptionGroup>) {
    state.options = [...state.options, ...(data.data as OptionGroup[])] || data;

    if (data.total) {
      state.optionPagination.total = data.total;
    }
  },
  concatArticlesOfSubscription(state: FoodcardState, data: Pagination<Article>) {
    state.articlesOfSubscription = [...state.articlesOfSubscription, ...(data.data as Article[])] || data;
  },
  setCategoryFilter(state: FoodcardState, filter: any) {
    state.categoryFilter = filter;
  },
  updateCategory(state: FoodcardState, data: Category) {
    state.categories = [
      ...state.categories.map((item: Category) => (item._id !== data._id ? item : { ...item, ...data })),
    ];
  },
  updateSubscriptionCategory(state: FoodcardState, data: SubscriptionCategory) {
    state.subscriptionCategories = [
      ...state.subscriptionCategories.map((item: SubscriptionCategory) =>
        item._id !== data._id ? item : { ...item, ...data },
      ),
    ];
  },
  updateSubscription(state: FoodcardState, data: Subscription) {
    state.subscriptions = [
      ...state.subscriptions.map((item: Subscription) => (item._id !== data._id ? item : { ...item, ...data })),
    ];
  },
  removeCategory(state: FoodcardState, data: { _id: string }) {
    state.categories = state.categories.filter((cat: Category) => cat._id !== data._id);
  },
  removeSubscriptionCategory(state: FoodcardState, data: { _id: string }) {
    state.subscriptionCategories = state.subscriptionCategories.filter(
      (cat: SubscriptionCategory) => cat._id !== data._id,
    );
  },
  removeSubscription(state: FoodcardState, data: { _id: string }) {
    state.subscriptions = state.subscriptions.filter((cat: Subscription) => cat._id !== data._id);
  },
  removeOption(state: FoodcardState, data: { _id: string }) {
    state.options = state.options.filter((opt: OptionGroup) => opt._id !== data._id);
  },
  removeOptions(state: FoodcardState, ids: string[]) {
    state.options = state.options.filter((opt: OptionGroup) => !ids.includes(opt._id));
  },
  setMultiplyCategory(state: FoodcardState, categories: string[]) {
    state.multiplyCategory = categories;
  },
  setOptionFilter(state: FoodcardState, filter: any) {
    state.optionFilter = filter;
  },
  setArticleFilter(state: FoodcardState, filter: any) {
    state.articleFilter = filter;
  },
  setOptionArticleFilter(state: FoodcardState, filter: any) {
    state.optionArticleFilter = filter;
  },
  setArticlesOfSubscriptionFilter(state: FoodcardState, filter: any) {
    state.articleOfSubscriptionFilter = filter;
  },
  setActiveArticle(state: FoodcardState, data: Article) {
    state.activeArticle = data;
  },
  setActiveOption(state: FoodcardState, data: OptionGroup) {
    state.activeOption = data;
  },
  storeOptionArticle(state: FoodcardState, data: OptionArticle) {
    state.activeOption!.articles.push(data);
  },
  updateOptionArticle(state: FoodcardState, data: OptionArticle) {
    state.activeOption!.articles = [
      ...state.activeOption!.articles.map((item: OptionArticle) =>
        item._id !== data._id ? item : { ...item, ...data },
      ),
    ];
  },
  deleteOptionArticle(state: FoodcardState, data: { id: string }) {
    state.activeOption!.articles = state.activeOption!.articles.filter((art: OptionArticle) => art._id !== data.id);
  },
  toggleCategoryVisibility(state: FoodcardState, data: { value: boolean; id: string }) {
    const category: Category = state.categories.find((c: Category) => c._id === data.id)!;
    category.visible = data.value;
  },
  toggleArticleVisibility(state: FoodcardState, data: { value: boolean; articles: string[] }) {
    const articles: Article[] = state.articles.filter((a: Article) => data.articles.indexOf(a._id) > -1);
    for (const article of articles) {
      article.visible = data.value;
    }
  },
  toggleArticleActivation(state: FoodcardState, data: { value: boolean; articles: string[] }) {
    const articles: Article[] = state.articles.filter((a: Article) => data.articles.indexOf(a._id) > -1);
    for (const article of articles) {
      article.isActive = data.value;
    }
  },
  toggleArticleControl(state: FoodcardState, data: { value: boolean; articles: string[] }) {
    const articles: Article[] = state.articles.filter((a: Article) => data.articles.indexOf(a._id) > -1);
    for (const article of articles) {
      article.isControlArticle = data.value;
    }
  },
  toggleOptionVisibility(state: FoodcardState, data: { value: boolean; options: string[] }) {
    const options: OptionGroup[] = state.options.filter((a: OptionGroup) => data.options.indexOf(a._id) > -1);
    for (const option of options) {
      option.visible = data.value;
    }
  },
  toggleOptionArticleVisibility(state: FoodcardState, data: { value: boolean; articles: string[] }) {
    const articles: OptionArticle[] = state.activeOption!.articles.filter(
      (a: OptionArticle) => data.articles.indexOf(a._id) > -1,
    );
    for (const article of articles) {
      article.visible = data.value;
    }
  },
  toggleOptionArticleActivation(state: FoodcardState, data: { value: boolean; articles: string[] }) {
    const articles: OptionArticle[] = state.activeOption!.articles.filter(
      (a: OptionArticle) => data.articles.indexOf(a._id) > -1,
    );
    for (const article of articles) {
      article.isActive = data.value;
    }
  },
  setArticleNames(state: FoodcardState, data: Array<Partial<Article>>) {
    state.articleNames = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
