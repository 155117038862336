import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { PromoCode } from '@/interfaces/models/PromoCode';
import Filter from '@/interfaces/api/Filter';

export class PromoCodeTeedeliApiService extends ApiService<any> {
  constructor() {
    super('general/promoCode/teedeli');
  }

  public activate(data: { id: string }): AxiosPromise<PromoCode> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/activate`);
  }

  public promoCodeTeedeliZip(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.getBaseUrl()}/generateQRCodes`, {
      params: filter,
      responseType: 'arraybuffer',
    });
  }
}
