import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { Tag } from '@/interfaces/models/Tag';

export default class TagServiceApi extends ApiService<Tag> {
  constructor() {
    super('general/tag');
  }

  public getTagsByVenue(page: Page, filter: Filter): AxiosPromise<Tag[] | Pagination<Tag>> {
    if (page) {
      return axios.get(
        `${this.getBaseUrl()}/venue/${filter.venue}?page=${page.page}&recommendationTagsOnly=${
          filter.recommendationTagsOnly || false
        }`,
      );
    }

    return axios.get(
      `${this.getBaseUrl()}/venue/${filter.venue}?recommendationTagsOnly=${filter.recommendationTagsOnly || false}`,
    );
  }

  public delete(data: { id: string }) {
    return axios.delete(`${this.version}/general/tag`, {
      data: {
        tags: [data.id],
      },
    });
  }
}
