import ApiService from '@/api/http/ApiService';
import { Customer } from '@/interfaces/models/Customer';
import axios, { AxiosPromise } from 'axios';
export default class CustomerApiService extends ApiService<Customer> {
  protected exportServiceUrl: string = 'v1/customer';
  constructor() {
    super('session/customer');
  }

  public importCustomer(param: { id: string }, data: FormData) {
    return axios.post(`${this.getBaseUrl()}/venue/${param.id}/import`, data);
  }

  public storeCustomer(data: Partial<Customer>): AxiosPromise<Customer> {
    return axios.post(`${this.getBaseUrl()}/venue/${data.venue}/create`, data);
  }

  public updateCustomer(data: Partial<Customer>): AxiosPromise<Customer> {
    return axios.patch(`${this.getBaseUrl()}/id/${data.id}`, data);
  }

  public getCustomers(ids: string[]): AxiosPromise<Customer[]> {
    return axios.get(`${this.getBaseUrl()}/ids/`, { params: { ids } });
  }

  public customerCsv(param: { from: string; to: string; venue: string; flavor: string; lang?: string }): AxiosPromise {
    return axios.get(`${this.exportServiceUrl}/export/csv`, { params: param });
  }
}
