
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { TranslateResult } from 'vue-i18n';

interface BreadCrumbItem {
  text: string | TranslateResult;
  to: string;
  disabled: boolean;
}

@Component
export default class BreadCrumb extends Vue {
  public $route!: Route;

  get items() {
    const routeParts: string[] = this.$route.name!.split('.');
    const items: BreadCrumbItem[] = [];

    if (routeParts.length === 2 && routeParts[1] !== 'index') {
      items.push({
        text: this.$t(`routes.${routeParts[0]}.index`),
        to: `${routeParts[0]}.index`,
        disabled: false,
      });
    }

    items.push({
      text: this.$t(`routes.${this.$route.name}`),
      to: this.$route.name!,
      disabled: true,
    });

    return items;
  }
}
