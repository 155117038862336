import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import Filter from '@/interfaces/api/Filter';
import ScreensaverApiService from '@/api/http/ScreensaverApiService';
import Screensaver from '@/interfaces/models/Screensaver';
import ScreensaverStep from '@/interfaces/models/ScreensaverStep';
import { Role } from '@/interfaces/models/Role';
import { Information } from '@/interfaces/models/Information';

interface ScreensaverState {
  items: Screensaver[];
  active: Screensaver | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: ScreensaverState = {
  items: [],
  active: null,
  pagination: {
    total: 0,
  },
  filter: {},
};

const api: ScreensaverApiService = new ScreensaverApiService();

const actions: ActionTree<ScreensaverState, RootState> = {
  fetch({ commit, state }) {
    if (state.filter.venues?.length || state.filter.customerGroups?.length) {
      return api.fetch(state.filter).then((res: AxiosResponse<Screensaver[]>) => {
        commit('setItems', res.data);
      });
    } else {
      commit('setItems', []);
    }
  },
  store({ commit, state }, screensaver: Screensaver) {
    return api.store(screensaver).then((res: AxiosResponse<Screensaver>) => {
      commit('setActive', res.data);
      return res;
    });
  },
  show({ commit, state }, id) {
    return api.show(id).then((res: AxiosResponse<Screensaver>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit, state }, screensaver: any) {
    return api.update(screensaver).then((res) => {
      commit(
        'setItems',
        state.items.map((item) => {
          if (item._id === res.data._id) {
            return res.data;
          }
          return item;
        }),
      );
    });
  },
  delete({ commit, state }, id: string) {
    return api.delete(id).then(() => {
      commit('delete', id);
    });
  },
  storeStep({ commit, state }, data: { screensaverId: string; step: ScreensaverStep }) {
    return api.storeStep(data).then((res: AxiosResponse<ScreensaverStep>) => {
      commit('addStep', res.data);
      return res.data;
    });
  },
  updateStep({ commit, state }, data: { screensaverId: string; stepId: string; step: ScreensaverStep }) {
    return api.updateStep(data).then((res: AxiosResponse<ScreensaverStep>) => {
      commit('updateStep', res.data);
      return res.data;
    });
  },
  deleteStep({ commit, state }, data: { screensaverId: string; stepId: string }) {
    return api.deleteStep(data).then((res: AxiosResponse<ScreensaverStep>) => {
      commit('deleteStep', data.stepId);
      return res.data;
    });
  },

  uploadStepImage({ commit, state }, data: { id: string; stepId: string; image: FormData }) {
    return api.uploadStepImage(data).then((res: AxiosResponse<ScreensaverStep>) => {
      commit('updateStep', res.data);
      return res.data;
    });
  },

  updateLevels({ state }, data: { screensaverId: string; levels: any }) {
    return api.updateLevels(data);
  },

  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<ScreensaverState> = {
  setItems(state: ScreensaverState, data: Screensaver[]) {
    state.items = (data as Screensaver[]) || data;
  },
  setActive(state: ScreensaverState, data: Screensaver) {
    state.active = (data as Screensaver) || data;
  },
  delete(state: ScreensaverState, id: string) {
    state.items = [...state.items.filter((s: Screensaver) => s._id !== id)];
  },
  addStep(state: ScreensaverState, step: ScreensaverStep) {
    state.active!.steps!.push(step);
  },
  deleteStep(state: ScreensaverState, id: string) {
    state.active!.steps = [...state.active!.steps!.filter((s: ScreensaverStep) => s._id !== id)];
  },
  updateStep(state: ScreensaverState, step: ScreensaverStep) {
    const index = state.active!.steps!.findIndex((s) => {
      return s._id === step._id;
    });
    state.active!.steps!.splice(index, 1, step);
  },

  updateSteps(state: ScreensaverState, steps: ScreensaverStep[]) {
    state.active!.steps = steps;
  },
  setFilter(state: ScreensaverState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
