import ApiService from '@/api/http/ApiService';
import { Payment } from '@/interfaces/models/Payment';
import { PaymentTotalSum } from '@/interfaces/api/PaymentTotalSum';
import { Page } from '@/interfaces/api/Page';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';

export default class ProvisionApiService extends ApiService<Payment> {
  constructor() {
    super('payment/provision');
  }

  public index(page: Page, filter?: any): AxiosPromise<Pagination<Payment>> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    return axios.get(`${this.getBaseUrl('v3')}/?page=${page.page}&${query}`);
  }

  public totalSum(filter?: any): AxiosPromise<PaymentTotalSum> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    return axios.get(`${this.getBaseUrl('v3')}/total/?${query}`);
  }

  public claimProvision(data: { venue: string; month: string }): AxiosPromise<AxiosResponse> {
    return axios.patch(`${this.getBaseUrl('v3')}/claim/${data.venue}/`, {}, { params: { month: data.month } });
  }
}
