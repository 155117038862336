import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import Filter from '@/interfaces/api/Filter';
import { AutomaticReportApiService } from '@/api/http/AutomaticReportApiService';
import { Job } from '@/interfaces/models/Job';

interface AutomaticReportState {
  items: Job[];
  active: Job | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: AutomaticReportApiService = new AutomaticReportApiService();

const state: AutomaticReportState = {
  active: null,
  items: [],
  filter: {},
  pagination: { total: 0 },
};

const actions: ActionTree<AutomaticReportState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<Job[] | Pagination<Job>>) => {
      commit('setItems', res.data);
    });
  },
  fetchAutomaticReportByGroup({ commit, state }, page: Page) {
    return api.getJobByGroup(page, state.filter).then((res: AxiosResponse<Job[] | Pagination<Job>>) => {
      commit('setItems', res.data);
    });
  },
  fetchAutomaticReportByVenue({ commit, state }, page: Page) {
    return api.getJobByVenue(page, state.filter).then((res: AxiosResponse<Job[] | Pagination<Job>>) => {
      commit('setItems', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Job>) => {
      commit('setActive', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  store({ commit }, data: Partial<Job>) {
    return api.store(data).then((res: AxiosResponse<Job>) => {
      commit('addItem', res.data);
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: { id: string; job: Job }) {
    return api.update(data).then((res: AxiosResponse<Job>) => {
      commit('update', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroy(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
};

const mutations: MutationTree<AutomaticReportState> = {
  setItems(state: AutomaticReportState, data: Pagination<Job>) {
    state.items = (data.data as Job[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: AutomaticReportState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: AutomaticReportState, data: Job) {
    state!.items.push(data);
  },
  update(state: AutomaticReportState, data: Job) {
    state.items = [...(state.items as Job[]).filter((item: Job) => item._id !== data._id), data];
  },
  setActive(state: AutomaticReportState, data: Job) {
    state.active = data;
  },
  removeItem(state: AutomaticReportState, data: Job) {
    state.items = state.items.filter((item: Job) => item._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
