import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

export default class MaintenanceApiService extends ApiService<any> {
  constructor() {
    super('general/maintenance');
  }

  public status(): AxiosPromise<boolean> {
    return axios.get(`${this.getBaseUrl()}/status`);
  }

  public enable(): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/enable`);
  }

  public disable(): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/disable`);
  }
}
