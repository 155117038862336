import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';

interface NotificationState {
  type: NotificationType | null;
  text: string;
}

type NotificationType = 'success' | 'error' | 'info' | 'warning';

const state: NotificationState = {
  type: null,
  text: '',
};

const actions: ActionTree<NotificationState, RootState> = {
  success({ commit }, text: string) {
    commit('notify', { text, type: 'success' });
  },
  error({ commit }, text: string) {
    commit('notify', { text, type: 'error' });
  },
  warning({ commit }, text: string) {
    commit('notify', { text, type: 'warning' });
  },
  info({ commit }, text: string) {
    commit('notify', { text, type: 'info' });
  },
  reset({ commit }) {
    commit('reset');
  },
};

const mutations: MutationTree<NotificationState> = {
  notify(state: NotificationState, data: { text: string; type: NotificationType }) {
    state.text = data.text;
    state.type = data.type;
  },
  reset() {
    state.text = '';
    state.type = null;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
