import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import MembershipApiService from '@/api/http/MembershipApiService';
import { SubscriptionCategory } from '@/interfaces/models/SubscriptionCategories';
import { Membership } from '@/interfaces/models/Membership';

interface MembershipState {
  memberships: Membership[];
  activeMembership: Membership | null;
}

const state: MembershipState = {
  memberships: [],
  activeMembership: null,
};

const api: MembershipApiService = new MembershipApiService();

const actions: ActionTree<MembershipState, RootState> = {
  fetchMemberships({ commit }, page?: Page) {
    return api.getMemberships().then((res: AxiosResponse<any[]>) => {
      commit('setMemberships', res.data);
    });
  },
  storeMembership({ commit }, data: Partial<Membership>) {
    return api.storeMembership(data).then((res: AxiosResponse) => {
      const membership: any = res.data;
      commit('addMembership', membership);
      commit('setActiveMembership', membership);
    });
  },
  updateMembership({ commit }, data: any) {
    return api.updateMembership(data).then((res: AxiosResponse<any>) => {
      commit('setActiveMembership', res.data);
      commit('updateMembership', res.data);
    });
  },
  uploadMembershipImage({ commit }, data: { _id: string; image: FormData }) {
    return api.uploadMembershipImage(data).then((res: AxiosResponse<any>) => {
      commit('setActiveMembership', res.data);
    });
  },
  destroyMembership({ commit }, data: { _id: string }) {
    return api.destroyMembership(data).then(() => {
      commit('removeMembership', { _id: data._id });
    });
  },
  setActiveMembership({ commit }, data: SubscriptionCategory) {
    commit('setActiveMembership', data);
  },
};

const mutations: MutationTree<MembershipState> = {
  setMemberships(state: MembershipState, data: Membership[]) {
    state.memberships = (data as any[]) || data;
  },
  setActiveMembership(state: MembershipState, data: Membership) {
    state.activeMembership = data;
  },
  addMembership(state: MembershipState, data: Membership) {
    state.memberships.push(data);
  },
  removeMembership(state: MembershipState, data: { _id: string }) {
    state.memberships = state.memberships.filter((member: Membership) => member._id !== data._id);
  },
  updateMembership(state: MembershipState, data: Membership) {
    state.memberships = [
      ...state.memberships.map((item: any) => (item._id !== data._id ? item : { ...item, ...data })),
    ];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
