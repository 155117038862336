import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const notification = namespace('notification');

@Component
export default class Notification extends Vue {
  @notification.Action('success') public notifySuccess!: (text: string) => void;
  @notification.Action('error') public notifyError!: (text: string) => void;
  @notification.Action('info') public notifyInfo!: (text: string) => void;
  @notification.Action('warning') public notifyWarning!: (text: string) => void;
}
