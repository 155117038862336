import ApiService from '@/api/http/ApiService';
import { Information } from '@/interfaces/models/Information';
import axios, { AxiosPromise } from 'axios';

export default class InformationApiService extends ApiService<Information> {
  constructor() {
    super('general/information');
  }

  public uploadPhoto(param: { id: string }, data: FormData): AxiosPromise<Information> {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/photo`, data);
  }
}
