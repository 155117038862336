import ApiService from '@/api/http/ApiService';
import { Page } from '@/interfaces/api/Page';
import axios, { AxiosPromise } from 'axios';
import { SanifairLog } from '../../interfaces/models/SanifairLog';

export class SanifairLogsApiService extends ApiService<any> {
  constructor() {
    super('order', 'v2');
  }

  public index(page?: Page | null | undefined, filter?: any): AxiosPromise<SanifairLog[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }
    if (page) {
      return axios.get(`${this.getBaseUrl()}/sanifair/logs/paginate/?page=${page.page}&limit=${page.limit}&${query}`);
    }

    return axios.get(`${this.getBaseUrl()}/sanifair/logs/paginate`);
  }
}
