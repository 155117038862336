import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { DialogType } from '@/enums/DialogType';

interface DialogState {
  venueSelector: boolean;
}

const state: DialogState = {
  venueSelector: false,
};

const actions: ActionTree<DialogState, RootState> = {
  show({ commit }, dialog: DialogType) {
    commit('show', dialog);
  },
  hide({ commit }, dialog: DialogType) {
    commit('hide', dialog);
  },
};

const mutations: MutationTree<DialogState> = {
  show(state: DialogState, dialog: DialogType) {
    // @ts-ignore
    state[dialog] = true;
  },
  hide(state: DialogState, dialog: DialogType) {
    // @ts-ignore
    state[dialog] = false;
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
