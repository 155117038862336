
import { mixins } from 'vue-class-component';
import { Download } from '@/interfaces/models/Download';
import Notification from '@/mixins/Notification';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import moment from 'moment';
import PaymentApiService from '@/api/http/PaymentApiService';
import { toUrl } from '@/util/helper';
import { AxiosResponse } from 'axios';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import ExportService from '@/api/http/ExportService';

const download = namespace('download');

interface DownloadInterval {
  interval: ReturnType<typeof setInterval>;
  d: Download;
}

@Component
export default class DownloadHandler extends mixins(Notification) {
  @download.State('downloads') public downloads!: Download[];

  @download.Action('removeDownload') public removeDownload!: (d: Download) => void;
  @download.Action('updateDownload') public updateDownload!: (d: Download) => void;

  public intervals: DownloadInterval[] = [];

  @Watch('downloads')
  public onDownloadChange() {
    for (const d of this.downloads) {
      if (d.inProgress && this.isTimedOut(d)) {
        const item: DownloadInterval = this.intervals.find((i: DownloadInterval) => i.d.file === d.file)!;
        if (item) {
          this.intervals.splice(this.intervals.indexOf(item), 1);
        }
        clearInterval(d.intervalId as any);
        this.removeDownload(d);
        this.notifyError('notification.downloadFailed');
      } else if (!d.inProgress) {
        const self: DownloadHandler = this;
        // @ts-ignore
        const intervalId: ReturnType<typeof setInterval> = setInterval(
          (self: DownloadHandler, d: Download) => {
            if (d.handler === 'payment') {
              const api: PaymentApiService = new PaymentApiService();
              api
                .downloadInvoice({ file: d.file })
                .then((res: AxiosResponse) => {
                  if (!d.done) {
                    self.notifyInfo('notification.downloadSuccess');
                    toUrl(res.data, `billing-${moment().format('DD-MM-YYYY')}.pdf`);
                  }
                  d.done = true;
                  const item: DownloadInterval = self.intervals.find((i: DownloadInterval) => i.d.file === d.file)!;
                  if (item) {
                    clearInterval(item.interval);
                    self.intervals.splice(self.intervals.indexOf(item), 1);
                  }
                  self.removeDownload(d);
                })
                .catch(() => {
                  // ignore error
                });
            } else if (d.handler === 'order') {
              const api: OrderServiceApi = new OrderServiceApi();
              api
                .downloadReport({ file: d.file, contentType: d.contentType as string })
                .then((res: AxiosResponse) => {
                  if (!d.done) {
                    self.notifyInfo('notification.downloadSuccess');
                    if (d.contentType === 'text/csv') {
                      toUrl(res.data, 'report.csv');
                    } else {
                      toUrl(res.data, 'reports.zip');
                    }
                  }
                  d.done = true;
                  const item: DownloadInterval = self.intervals.find((i: DownloadInterval) => i.d.file === d.file)!;
                  if (item) {
                    clearInterval(item.interval);
                    self.intervals.splice(self.intervals.indexOf(item), 1);
                  }
                  self.removeDownload(d);
                })
                .catch(() => {
                  // ignore error
                });
            } else if (d.handler === 'sales') {
              const api: ExportService = new ExportService();
              api
                .downloadSales({ file: d.file })
                .then((res: AxiosResponse) => {
                  if (!d.done) {
                    self.notifyInfo('notification.downloadSuccess');
                    toUrl(res.data, `sales-${moment().format('DD-MM-YYYY')}.pdf`);
                  }
                  d.done = true;
                  const item: DownloadInterval = self.intervals.find((i: DownloadInterval) => i.d.file === d.file)!;
                  if (item) {
                    clearInterval(item.interval);
                    self.intervals.splice(self.intervals.indexOf(item), 1);
                  }
                  self.removeDownload(d);
                })
                .catch(() => {
                  // ignore error
                });
            }
          },
          3000,
          self,
          { ...d },
        );
        this.intervals.push({ d, interval: intervalId });
        this.updateDownload({ ...d, intervalId, inProgress: true, startedAt: moment() });
      }
    }
  }

  public isTimedOut(d: Download) {
    return moment().isSameOrAfter(moment(d.startedAt).add(d.timeout, 'seconds'));
  }
}
