import Filter from '@/interfaces/api/Filter';
import { Pagination } from '../interfaces/api/Pagination';
import { RootState } from '../interfaces/stores/RootState';
import { Page } from '../interfaces/api/Page';
import { PiggyRewardApiService } from '../api/http/PiggyRewardApiService';
import { ActionTree, MutationTree } from 'vuex';
import { AxiosResponse } from 'axios';
import { PiggyReward } from '@/interfaces/models/PiggyReward';

interface PiggyRewardState {
  items: PiggyReward[];
  active: PiggyReward | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: PiggyRewardApiService = new PiggyRewardApiService();

const state: PiggyRewardState = {
  active: null,
  filter: {},
  items: [],
  pagination: { total: 0 },
};

const actions: ActionTree<PiggyRewardState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<PiggyReward[] | Pagination<PiggyReward>>) => {
      commit('setItems', res.data);
    });
  },
  getByCustomerGroup({ commit, state }, page?: Page) {
    return api
      .getByCustomerGroup(page, state.filter)
      .then((res: AxiosResponse<PiggyReward[] | Pagination<PiggyReward>>) => {
        commit('setItems', res.data);
      });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  resetRewards({ commit, state }) {
    commit('setItems', { data: [] });
  },
};

const mutations: MutationTree<PiggyRewardState> = {
  setItems(state: PiggyRewardState, data: Pagination<PiggyReward>) {
    state.items = (data.data as PiggyReward[]) || data;
  },
  addItem(state: PiggyRewardState, data: PiggyReward) {
    state.items.push(data);
  },
  setActive(state: PiggyRewardState, data: PiggyReward) {
    state.active = data;
  },
  setFilter(state: PiggyRewardState, data: Filter) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
