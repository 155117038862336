import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import CartRecommendationApiService from '@/api/http/CartRecommendationApiService';
import { CartRecommendation } from '@/interfaces/models/CartRecommendation';
import { Pagination } from '@/interfaces/api/Pagination';
import Filter from '@/interfaces/api/Filter';

interface CartRecommendationState {
  items: CartRecommendation[];
  filter: Filter;
  active: CartRecommendation | null;
}

const api: CartRecommendationApiService = new CartRecommendationApiService();

const state: CartRecommendationState = {
  items: [],
  filter: {},
  active: null,
};

const actions: ActionTree<CartRecommendationState, RootState> = {
  fetch({ commit, state }) {
    return api
      .index(null, state.filter)
      .then((res: AxiosResponse<CartRecommendation[] | Pagination<CartRecommendation>>) => {
        commit('setItems', res.data);
      });
  },
  store({ commit }, data: Partial<CartRecommendation>) {
    return api.store(data).then((res: AxiosResponse<CartRecommendation>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<CartRecommendation>) => {
      commit('setActive', res.data);
    });
  },
  showByVenue({ commit }, data: { venue: string }) {
    return api.showByVenue(data).then((res: AxiosResponse<CartRecommendation>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<CartRecommendation>) {
    return api.update(data).then((res: AxiosResponse<CartRecommendation>) => {
      commit('update', res.data);
    });
  },
  delete({ commit }, data: { id: string }) {
    return api.softDelete(data).then(() => {
      commit('delete', data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<CartRecommendationState> = {
  setItems(state: CartRecommendationState, data: Pagination<CartRecommendation>) {
    state.items = (data.data as CartRecommendation[]) || data;
  },
  setActive(state: CartRecommendationState, data: CartRecommendation) {
    state.active = data;
  },
  setFilter(state: CartRecommendationState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: CartRecommendationState, data: CartRecommendation) {
    state.items.push(data);
  },
  update(state: CartRecommendationState, data: CartRecommendation) {
    state.items = [...state.items.filter((r: CartRecommendation) => r._id !== data._id), data];
    state.active = data;
  },
  delete(state: CartRecommendationState, data: { id: string }) {
    state.items = [...state.items.filter((r: CartRecommendation) => r._id !== data.id)];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
