import { Table } from '@/interfaces/models/Table';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import TableServiceApi from '@/api/http/TableServiceApi';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { AxiosResponse } from 'axios';

interface TableState {
  items: Table[];
  active: Table | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: TableState = {
  active: null,
  items: [],
  filter: {},
  pagination: {
    total: 0,
  },
};

const api: TableServiceApi = new TableServiceApi();

const actions: ActionTree<TableState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.getTablesByVenue(page, state.filter).then((res: AxiosResponse<Table[] | Pagination<Table>>) => {
      commit('setItems', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Table>) => {
      commit('setActive', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  store({ commit }, data: Partial<Table>) {
    return api.store(data).then((res: AxiosResponse<Table>) => {
      commit('addItem', res.data);
    });
  },
  update({ commit }, data: Partial<Table>) {
    return api.update(data).then((res: AxiosResponse<Table>) => {
      commit('update', res.data);
    });
  },
  togleActive({ commit }, data: Partial<Table>) {
    return api
      .update(data)
      .then((res: AxiosResponse<Table>) => {
        commit('update', res.data);
      })
      .catch(() => commit('update', { ...data, active: !data.active }));
  },
  destroy({ commit }, data: { _id: string; venue: string }) {
    return api.destroy(data).then(() => {
      commit('removeTable', { _id: data._id });
    });
  },
};

const mutations: MutationTree<TableState> = {
  setItems(state: TableState, data: Pagination<Table>) {
    state.items = (data.data as Table[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: TableState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: TableState, data: Table) {
    state!.items.push(data);
  },
  update(state: TableState, data: Table) {
    const tableIndex: number = state.items.findIndex((a: Table) => data._id === a._id);
    state.items[tableIndex] = data;
    state.items = [...state.items];
  },
  setActive(state: TableState, data: Table) {
    state.active = data;
  },
  removeTable(state: TableState, data: Table) {
    state.items = state.items.filter((item: Table) => item._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
