import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Client } from '@/interfaces/models/Client';
import ClientApiService from '@/api/http/ClientApiService';

interface ClientState {
  items: Client[];
  active: Client | null;
  pagination: {
    total: number;
  };
}

const state: ClientState = {
  items: [],
  active: null,
  pagination: {
    total: 0,
  },
};

const api: ClientApiService = new ClientApiService();

const actions: ActionTree<ClientState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.index(page).then((res: AxiosResponse<Client[] | Pagination<Client>>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<Client>) {
    return api.store(data).then((res: AxiosResponse<Client>) => {
      commit('addItem', res.data);
    });
  },
  addSecret({ commit }, data: Partial<Client>) {
    return api.addClient(data as { clientId: string });
  },
  showSecrets({ commit }, data: { id: string }) {
    return api.showSecrets(data).then((res: AxiosResponse<Client>) => {
      commit('setActive', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Client>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<Client>) {
    return api.update(data).then((res: AxiosResponse<Client>) => {
      commit('update', res.data);
    });
  },
};

const mutations: MutationTree<ClientState> = {
  setItems(state: ClientState, data: Pagination<Client>) {
    state.items = (data.data as Client[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  addItem(state: ClientState, data: Client) {
    state!.items.push(data);
  },
  update(state: ClientState, data: Client) {
    state.items = [...(state.items as Client[]).filter((item: Client) => item._id !== data._id), data];
  },
  setActive(state: ClientState, data: Client) {
    state.active = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
