import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import Filter from '@/interfaces/api/Filter';
import { Banner } from '@/interfaces/models/Banner';
import BannerApiService from '@/api/http/BannerApiService';

interface BannerState {
  items: Banner[];
  active: Banner | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: BannerState = {
  items: [],
  active: null,
  pagination: {
    total: 0,
  },
  filter: {},
};

const api: BannerApiService = new BannerApiService();

const actions: ActionTree<BannerState, RootState> = {
  fetch({ commit, state }, venueId: string) {
    return api.fetch(venueId).then((res: AxiosResponse<Banner[]>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit, state }, data: { venueId: string; banner: Banner }) {
    return api.store(data).then((res: AxiosResponse<Banner>) => {
      commit('setActive', res.data);
      return res.data;
    });
  },
  show({ commit, state }, data: { venueId: string; id: string }) {
    return api.show(data).then((res: AxiosResponse<Banner>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit, state }, data: { venueId: string; id: string; banner: Banner }) {
    return api.update(data).then((res: AxiosResponse<Banner>) => {
      return res.data;
    });
  },
  delete({ commit, state }, data: { venueId: string; bannerId: string }) {
    return api.delete(data).then(() => {
      commit('delete', data.bannerId);
    });
  },
  uploadBannerImage({ commit, state }, data: { venueId: string; id: string; image: FormData; locale: string }) {
    return api.uploadBannerImage(data);
  },
};

const mutations: MutationTree<BannerState> = {
  setItems(state: BannerState, data: Banner[]) {
    state.items = (data as Banner[]) || data;
  },
  setActive(state: BannerState, data: Banner) {
    state.active = (data as Banner) || data;
  },
  delete(state: BannerState, id: string) {
    state.items = [...state.items.filter((s: Banner) => s._id !== id)];
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
