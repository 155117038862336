export enum CustomerGroup {
  Default = '',
  DefaultInPreparation = 'default_in_preparation',
  Subway = 'subway',
  SubwayFr = 'subway_fr',
  SubwayLu = 'subway_lu',
  SubwayTerminal = 'subway_terminal',
  DB = 'db',
  Lunchroom = 'lunchroom',
  FoodByFriends = 'foodbyfriends',
  Weilands = 'weilands',
  Cinemaxx = 'cinemaxx',
  District = 'district',
  Slush = 'slush',
  Kochloeffel = 'kochloeffel',
  Immergruen = 'immergruen',
  MyMonza = 'mymonza',
  Aspasia = 'aspasia',
  Cotidiano = 'cotidiano',
  Bigburger = 'bigburger',
  KOOS = 'koos',
  Frittenwerk = 'frittenwerk',
  Ottosburger = 'ottosburger',
  Purino = 'purino',
  DeanAndDavid = 'dean_and_david',
  ThreeMensKebab = '3menskebab',
  PerfectDay = 'perfectday',
  Hausmanns = 'hausmanns',
  Doenerwerk = 'doenerwerk',
  Foodlounge = 'foodlounge',
  Mado = 'mado',
  Nordsee = 'nordsee',
  Depot = 'depot',
  Jamies = 'jamies',
  BurgerKing = 'burger_king',
  Saray = 'saray',
  Moschmosch = 'moschmosch',
  Starbucks = 'starbucks',
  Kamps = 'kamps',
  Coffee_Fellows = 'coffee_fellows',
  Hofbrauhaus = 'hofbrauhaus',
  Atlantik_Fisch = 'atlantik_fisch',
  DIMATEST = 'dima_test',
  MALOA = 'maloa',
  WERK5 = 'werk5',
  EPI = 'epi',
  IAMLOVE = 'iamlove',
  BEETS_AND_ROOTS = 'beets_and_roots',
  BEETS_AND_ROOTS_SECOND = 'beets_and_roots_second',
  LOSTERIA = 'losteria',
  SHISO = 'shiso',
  SNACK_ELIZE = 'snack_elize',
  TABILO = 'tabilo',
  TABILO_SIM = 'tabilo_sim',
  TUR_ABNAHME_RES = 'tur_abnahme_res',
  TUR_ABNAHME_SIM = 'tur_abnahme_sim',
  EFFE_GOLD = 'effe_gold',
  LACANTINE = 'lacantine',
  EAM = 'eam',
  NAUPAKA = 'naupaka',
  SPORTSBAR = 'sportsbar',
  CIGKOFTEM = 'cigkoftem',
  SUSHISUPPLY = 'sushisupply',
  THE_ASH = 'the_ash',
  MARKTHALLE = 'markthalle',
  SASHIMI = 'sashimi',
  BONBONCHA = 'bonboncha',
  HULALA = 'hulala',
  BACKWERK_SCHWEIZ = 'backwerk_schweiz',
  BACKWERK_CH = 'backwerk_ch',
  BACKFACTORY = 'backfactory',
  TEEDELI = 'teedeli',
  WOHLFUHLER = 'wohlfuhler',
  FRITTENWERK_SECOND = 'frittenwerk_second',
  ASIA_GOURMET = 'asia_gourmet',
  RICE_REPUBLIC = 'rice_republic',
  GOODBYTZ = 'goodbytz',
  PIRATEN_BURGER = 'piraten_burger',
  TOURNESOL = 'tournesol',
  BREZEL_KOENIG = 'brezel_koenig',
  VINCENT_VEGAN = 'vincent_vegan',
  PAPAIS = 'papais',
  THE_CUP = 'the_cup',
  MARSEILLE_FOOD_MARKET = 'marseille_food_market',
  OLEARYS = 'olearys',
  ICELAND = 'iceland',
  TABLE_DOT = 'table_dot',
  SEVEN_BY_BAT = 'seven_by_bat',
  BREAK_BURGER = 'break_burger',
  WOK = 'wok',
  OAKBERRY = 'oakberry',
  TUR_AUTOHOF = 'tur_autohof',
  TUR_ABNAHME_AUTOHOF = 'tur_abnahme_autohof',
  TRZ = 'trz',
  MONKEY_BURGER = 'monkey_burger',
  PASTA_FEE = 'pasta_fee',
  POTATOE = 'potatoe',
  PIBOSA = 'pibosa',
}
