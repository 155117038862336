import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Order } from '@/interfaces/models/Order';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import OrderServiceApi from '@/api/http/OrderServiceApi';
import StatisticsApiService from '@/api/http/StatisticsApiService';
import { StatisticOverviewItem } from '@/interfaces/models/StatisticOverviewItem';
import { OrderStatus } from '@/enums/OrderStatus';

const api: OrderServiceApi = new OrderServiceApi();
const statisticApi: StatisticsApiService = new StatisticsApiService();

interface StatisticsState {
  items: Order[];
  filter: Filter;
  pagination: {
    total: number;
  };
  overview: StatisticOverviewItem[];
}

const state: StatisticsState = {
  pagination: {
    total: 0,
  },
  filter: {},
  items: [],
  overview: [],
};

const actions: ActionTree<StatisticsState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api
      .index(page, { ...state.filter, hidden: true })
      .then((res: AxiosResponse<Order[] | Pagination<Order>>) => {
        commit('setItems', res.data);
      });
  },
  overview({ commit, state }) {
    return statisticApi.getOverview(state.filter).then((res: AxiosResponse<StatisticOverviewItem[]>) => {
      commit('setOverview', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    Object.keys(filter).forEach((key) => {
      if (filter[key] === undefined) {
        delete filter[key];
      }
    });
    commit('setFilter', filter);
  },
  setItem({ commit, state }, data: Order) {
    commit('updateStatusItem', data);
  },
};

const mutations: MutationTree<StatisticsState> = {
  setItems(state: StatisticsState, data: Pagination<Order>) {
    state.items = (data.data as Order[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: StatisticsState, data: Filter) {
    state.filter = data;
  },
  setOverview(state, data: StatisticOverviewItem[]) {
    state.overview = data;
  },
  updateStatusItem(state, data: Order) {
    state.items = state.items.map((item: Order) => {
      if (item._id === data._id) {
        item.status = data.status;
        return item;
      }
      return item;
    });
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
