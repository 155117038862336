import uuid from 'uuid/v4';
import { Permission } from '@/enums/Permission';
import { CustomerGroup } from '@/enums/CustomerGroup';

export default [
  {
    key: uuid(),
    title: 'nav.dashboard',
    icon: 'mdi-view-dashboard',
    to: 'dashboard.index',
    can: Permission.STATISTICS_OVERVIEW,
  },
  {
    key: uuid(),
    title: 'nav.foodcard',
    to: '',
    can: [Permission.CATEGORY_VIEW, Permission.ARTICLE_VIEW, Permission.OPTION_VIEW],
    children: [
      {
        key: uuid(),
        title: 'nav.category',
        icon: 'mdi-shape',
        to: 'category.index',
        can: Permission.CATEGORY_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.article',
        icon: 'mdi-food-fork-drink',
        to: 'article.index',
        can: Permission.ARTICLE_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.articleManager',
        icon: 'mdi-food-fork-drink',
        to: 'articleManager.index',
        can: Permission.ARTICLE_VIEW,
      },
      { key: uuid(), title: 'nav.option', icon: 'mdi-food-variant', to: 'option.index', can: Permission.OPTION_VIEW },
    ],
  },
  { key: uuid(), title: 'nav.venue', icon: 'mdi-glass-cocktail', to: 'venue.index', can: Permission.VENUE_VIEW },
  {
    key: uuid(),
    title: 'nav.venueLabel',
    icon: 'mdi-label-multiple',
    to: 'venueLabel.index',
    can: Permission.LABEL_VIEW,
  },
  {
    key: uuid(),
    title: 'nav.company',
    icon: 'mdi-domain',
    to: 'company.index',
    can: Permission.DND_COMPANY_VIEW,
    isCustomerGroup: CustomerGroup.DeanAndDavid,
  },
  {
    key: uuid(),
    title: 'nav.productCombos',
    icon: 'mdi-basket-outline',
    to: 'productCombos.index',
    can: Permission.VENUE_UPDATE,
  },
  {
    key: uuid(),
    title: 'nav.subscriptionCategories',
    icon: 'mdi-shape',
    to: 'subscriptionCategories.index',
    can: Permission.CATEGORY_VIEW_NAV,
  },
  {
    key: uuid(),
    title: 'nav.subscription',
    icon: 'mdi-shape',
    to: 'subscription.index',
    can: Permission.CATEGORY_VIEW_NAV,
  },
  {
    key: uuid(),
    title: 'nav.membership',
    icon: 'mdi-shape',
    to: 'membership.index',
  },
  {
    key: uuid(),
    title: 'nav.sepa',
    icon: 'mdi-bank',
    to: 'sepa.index',
    can: Permission.VENUE_SEPA_CREATE,
  },
  {
    key: uuid(),
    title: 'nav.screensaver',
    icon: 'mdi-monitor-screenshot',
    to: 'screensaver.index',
    can: Permission.SCREENSAVER_VIEW,
  },
  { key: uuid(), title: 'nav.banner', icon: 'mdi-image-filter-frames', to: 'banner.index' },
  { key: uuid(), title: 'nav.user', icon: 'mdi-account', to: 'user.index', can: Permission.USER_VIEW },
  { key: uuid(), title: 'nav.role', icon: 'mdi-shield-account', to: 'role.index', can: Permission.ROLE_VIEW },
  {
    key: uuid(),
    title: 'nav.customerGroupProprty',
    icon: 'mdi-account-multiple',
    to: 'customerGroupProprty.index',
    can: Permission.ADMIN,
  },
  { key: uuid(), title: 'nav.tag', icon: 'mdi-label', to: 'tag.index', can: Permission.TAG_VIEW },
  {
    key: uuid(),
    title: 'nav.translation',
    icon: 'mdi-text-to-speech',
    to: 'translation.index',
    can: Permission.FOODCARD_IMPORT,
  },
  {
    key: uuid(),
    title: 'nav.recommendations',
    icon: 'mdi-star-half',
    to: 'recommendation.index',
    can: Permission.RECOMMENDATIONS_VIEW,
  },
  { key: uuid(), title: 'nav.promo', icon: 'mdi-sale', to: 'promo.index', can: Permission.PROMO_CODE_VIEW },
  { key: uuid(), title: 'nav.cupCode', icon: 'mdi-cup', to: 'cupCode.index', can: Permission.ADMIN },
  { key: uuid(), title: 'nav.coupon', icon: 'mdi-ticket-percent', to: 'coupon.index', can: Permission.COUPON_VIEW },
  {
    key: uuid(),
    title: 'nav.information',
    icon: 'mdi-bullhorn',
    to: 'information.index',
    can: Permission.INFORMATION_VIEW,
  },
  { key: uuid(), title: 'nav.printer', icon: 'mdi-printer', to: 'printer.index', can: Permission.PRINTER_VIEW },
  {
    key: uuid(),
    title: 'nav.customer',
    icon: 'mdi-account-box-multiple',
    to: 'customer.index',
    can: Permission.CUSTOMER_VIEW,
  },
  { key: uuid(), title: 'nav.workflow', icon: 'mdi-sitemap', to: 'workflow.index', can: Permission.WORKFLOW_VIEW },
  { key: uuid(), title: 'nav.client', icon: 'mdi-devices', to: 'client.index', can: Permission.CLIENT_VIEW },
  { key: uuid(), title: 'nav.table', icon: 'mdi-table-chair', to: 'table.index', can: Permission.TABLE_VIEW },
  { key: uuid(), title: 'nav.tradegroup', icon: 'mdi-group', to: 'constrain.index', can: Permission.CONSTRAIN_VIEW },
  {
    key: uuid(),
    title: 'nav.printGroups',
    icon: 'mdi-group',
    to: 'printGroups.index',
    can: Permission.PRINTGROUPS_VIEW,
  },
  { key: uuid(), title: 'nav.payment', icon: 'mdi-currency-usd', to: 'payment.index', can: Permission.PAYMENT_VIEW },
  {
    key: uuid(),
    title: 'nav.provision',
    icon: 'mdi-sack-percent',
    to: 'provision.index',
    can: Permission.PROVISION_VIEW,
  },
  {
    key: uuid(),
    title: 'nav.inventory',
    icon: 'mdi-format-list-checks',
    to: 'inventory.index',
    can: Permission.INVENTORY_VIEW,
  },
  {
    key: uuid(),
    title: 'nav.maintenance',
    icon: 'mdi-hammer-wrench',
    to: 'maintenance.index',
    can: [Permission.MAINTENANCE_CREATE, Permission.MAINTENANCE_UPDATE],
  },
  {
    key: uuid(),
    title: 'nav.employeeNotification',
    icon: 'mdi-bell',
    to: 'employeeNotification.create',
    can: Permission.SCREENSAVER_VIEW,
  },
  {
    key: uuid(),
    title: 'nav.loyaltyPrograms',
    icon: 'mdi-gift',
    to: 'loyaltyPrograms.index',
  },
  {
    key: uuid(),
    title: 'nav.sync',
    icon: 'mdi-sync',
    to: 'sync.index',
    can: [Permission.ADMIN],
  },
  {
    key: uuid(),
    title: 'nav.aggregatorSyncs',
    icon: 'mdi-sync',
    to: 'aggregatorSyncs.index',
    can: [Permission.JET_SYNC_JOB_VIEW, Permission.UBER_EATS_SYNC_JOB_VIEW, Permission.WOLT_SYNC_JOB_VIEW],
  },
  {
    key: uuid(),
    title: 'nav.reporting',
    can: [
      Permission.STATISTICS_VIEW,
      Permission.ANALYTICS_VIEW,
      Permission.RECEIPT_VIEW,
      Permission.SALES_VIEW,
      Permission.REPORTING_VIEW,
    ],
    children: [
      {
        key: uuid(),
        title: 'nav.billing',
        icon: 'mdi-currency-usd',
        to: 'billing.index',
        can: Permission.BILLING_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.userExport',
        icon: 'mdi-file-account',
        to: 'userExport.index',
        can: Permission.USER_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.activityLogExport',
        icon: 'mdi-file-export',
        to: 'activityLogExport.index',
        can: Permission.ADMIN,
      },
      {
        key: uuid(),
        title: 'nav.reporting',
        icon: 'mdi-file-chart',
        to: 'reporting.index',
        can: Permission.REPORTING_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.statistic',
        icon: 'mdi-chart-pie',
        to: 'statistics.index',
        can: Permission.STATISTICS_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.analytic',
        icon: 'mdi-chart-line',
        to: 'analytics.index',
        can: Permission.ANALYTICS_VIEW,
      },
      { key: uuid(), title: 'nav.sales', icon: 'mdi-cash-multiple', to: 'sales.index', can: Permission.SALES_VIEW },
      { key: uuid(), title: 'nav.receipts', icon: 'mdi-printer', to: 'receipt.index', can: Permission.RECEIPT_VIEW },
      { key: uuid(), title: 'nav.tips', icon: 'mdi-cash-100', to: 'tips.index', can: Permission.BILLING_VIEW },
      {
        key: uuid(),
        title: 'nav.eposJobs',
        icon: 'mdi-format-list-checks',
        to: 'eposJobs.index',
        can: Permission.EPOS_JOB_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.sanifairLogs',
        icon: 'mdi-format-list-checks',
        to: 'sanifairLogs.index',
        can: Permission.SANIFAIR_VIEW,
      },
      {
        key: uuid(),
        title: 'nav.automatedReports',
        icon: 'mdi-format-list-numbered',
        to: 'automatedReports.index',
        can: Permission.REPORTING_VIEW,
      },
    ],
  },
];
