import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Subscription } from '@/interfaces/models/Subscription';

export default class MembershipApiService extends ApiService<any> {
  constructor() {
    super('loyalty/membership');
  }

  public getMemberships(): AxiosPromise<Subscription[]> {
    return axios.get(`${this.version}/loyalty/membership`);
  }

  public storeMembership(data: Partial<Subscription>): AxiosPromise {
    return axios.post(`${this.version}/loyalty/membership`, data);
  }

  public updateMembership(data: Partial<any>): AxiosPromise<any> {
    return axios.patch(`${this.version}/loyalty/membership/${data._id}`, data);
  }

  public uploadMembershipImage(data: { _id: string; image: FormData }) {
    return axios.patch(`${this.version}/loyalty/membership/${data._id}/image`, data.image);
  }

  public destroyMembership(data: { _id: string }): AxiosPromise<void> {
    return axios.delete(`${this.version}/loyalty/membership/${data._id}`);
  }
}
