import ApiService from '@/api/http/ApiService';
import { Venue } from '@/interfaces/models/Venue';
import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import { Table } from '@/interfaces/models/Table';
import { Pagination } from '@/interfaces/api/Pagination';

export default class TableServiceApi extends ApiService<Venue> {
  constructor() {
    super('general/venue');
  }

  public getTablesByVenue(page: Page, filter: Filter): AxiosPromise<Table[] | Pagination<Table>> {
    if (page) {
      return axios.get(`${this.getBaseUrl()}/${filter.venue}/tables?page=${page.page}`);
    }

    return axios.get(`${this.getBaseUrl()}/${filter.venue}/tables`);
  }

  public store(data: Partial<Table>) {
    return axios.post(`${this.getBaseUrl()}/${data.venue}/table`, data);
  }

  public update(data: Partial<Table>) {
    return axios.patch(`${this.getBaseUrl()}/table/${data.id}`, data);
  }

  public show(data: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/table/${data.id}`);
  }

  public destroy(data: Partial<Table>) {
    return axios.delete(`${this.getBaseUrl()}/${data.venue}/table/${data._id}`);
  }
}
