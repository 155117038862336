import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import Filter from '@/interfaces/api/Filter';
import { Loyalty } from '@/interfaces/models/Loyalty';
import { LoyaltyApiService } from '@/api/http/LoyaltyApiService';
import { Pagination } from '@/interfaces/api/Pagination';

interface LoyaltyState {
  items: Loyalty[];
  active: Loyalty | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: LoyaltyApiService = new LoyaltyApiService();

const state: LoyaltyState = {
  active: null,
  filter: {},
  items: [],
  pagination: { total: 0 },
};

const actions: ActionTree<LoyaltyState, RootState> = {
  fetch({ commit, state }) {
    return api.index().then((res: AxiosResponse<Loyalty[] | Pagination<Loyalty>>) => {
      commit('setItems', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Loyalty>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<Loyalty>) {
    return api.update(data).then((res: AxiosResponse<Loyalty>) => {
      commit('update', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroy(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  store({ commit }, data: Partial<Loyalty>) {
    return api.store(data).then((res: AxiosResponse<Loyalty>) => {
      commit('addItem', res.data);
    });
  },
  setActiveLoyalty({ commit }, data: Loyalty) {
    commit('setActive', data);
  },
};

const mutations: MutationTree<LoyaltyState> = {
  setItems(state: LoyaltyState, data: Pagination<Loyalty>) {
    state.items = (data.data as Loyalty[]) || data;
  },
  addItem(state: LoyaltyState, data: Loyalty) {
    state.items.push(data);
  },
  setActive(state: LoyaltyState, data: Loyalty) {
    state.active = data;
  },
  setFilter(state: LoyaltyState, data: Filter) {
    state.filter = data;
  },
  removeItem(state: LoyaltyState, data: { _id: string }) {
    state.items = state.items.filter((item: Loyalty) => item._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
