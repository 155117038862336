import ApiService from '@/api/http/ApiService';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import axios, { AxiosPromise } from 'axios';

export default class VenueLabelApiService extends ApiService<VenueLabel> {
  constructor() {
    super('general/label');
  }

  public validateVenueLabel(id: string, except: { except?: string }): AxiosPromise<void> {
    return axios.post(`${this.version}/general/validation/venueLabel`, { readableId: id, ...except });
  }
}
