import Filter from '@/interfaces/api/Filter';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import moment from 'moment';
import { SanifairLog } from '@/interfaces/models/SanifairLog';
import { SanifairLogsApiService } from '@/api/http/SanifairLogsApiService';

interface SanifairLogsState {
  filter: Filter;
  items: SanifairLog[];
  pagination: {
    total: number;
  };
}

const state: SanifairLogsState = {
  filter: {},
  items: [],
  pagination: {
    total: 0,
  },
};

const api: SanifairLogsApiService = new SanifairLogsApiService();
const actions: ActionTree<SanifairLogsState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<SanifairLog[]>) => {
      commit('setItems', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<SanifairLogsState> = {
  setItems(state: SanifairLogsState, data: Pagination<SanifairLog>) {
    state.items = (data.data as SanifairLog[]) || data;
    if (Number.isInteger(data.total)) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: SanifairLogsState, filter: Filter) {
    state.filter = filter;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
