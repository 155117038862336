import axios, { AxiosPromise } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { Query } from '@/interfaces/api/Query';
import _ from 'lodash';
import { Delfi } from '@/interfaces/models/Delfi';

export default class ApiService<S> {
  protected apiEndpoint: string;
  protected version: string;

  constructor(apiEndpoint: string, version = 'v1') {
    this.version = version;
    this.apiEndpoint = `${apiEndpoint}`;
  }

  public index(page?: Page | null | undefined, filter?: any): AxiosPromise<Pagination<S> | S[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    if (page) {
      return axios.get(`${this.getBaseUrl()}/?page=${page.page}&${query}&articles=true&sort=createdAt&direction=desc`);
    }

    return axios.get(`${this.getBaseUrl()}/?${query}&sort=createdAt&direction=desc`);
  }

  public all(): AxiosPromise<S[]> {
    return axios.get(`${this.getBaseUrl()}/all`);
  }

  public query(param: any): AxiosPromise<S[]> {
    let query = '?';
    for (const key of Object.keys(param)) {
      query += `${key}=${param[key]}&`;
    }
    query = query.slice(0, -1);
    return axios.get(`${this.getBaseUrl()}/query${query}`);
  }

  public store(param: any): AxiosPromise<S> {
    return axios.post(this.getBaseUrl(), param);
  }

  public storeTeedeliCode(param: any): AxiosPromise<S> {
    return axios.post(`${this.getBaseUrl()}/generatePromoTeedeli`, param);
  }

  public update(param: any): AxiosPromise<S> {
    return axios.patch(`${this.getBaseUrl()}/${param.id}`, param);
  }

  public updateCheckoutIntegrationProps(param: any): AxiosPromise<S> {
    return axios.patch(`${this.getBaseUrl()}/${param.venue}/setCheckoutIntegration`, param.payload);
  }

  public getCheckoutIntegration(param: { venue: string; integrationType: string }) {
    return axios.get(
      `${this.getBaseUrl()}/${param.venue}/getCheckoutIntegration?integrationType=${param.integrationType}`,
    );
  }

  public show(param: { id: string }): AxiosPromise<S> {
    return axios.get(`${this.getBaseUrl()}/${param.id}`);
  }

  public destroy(param: { id: string }): AxiosPromise<S> {
    return axios.delete(`${this.getBaseUrl()}/${param.id}/hard`);
  }

  public softDelete(param: { id: string }): AxiosPromise<S> {
    return axios.delete(`${this.getBaseUrl()}/${param.id}`);
  }

  public mapToQuery(param: Query) {
    let query = '';
    for (const key in param) {
      if (_.isArray(param[key]) && !_.isEmpty(param[key])) {
        for (const field of param[key]) {
          query += `${key}[]=${field}&`;
        }
      } else if (!_.isArray(param[key])) {
        query += `${key}=${param[key]}&`;
      }
    }
    query = query.slice(0, -1);
    return query;
  }

  protected getBaseUrl(version?: string) {
    if (version) {
      return `${version}/${this.apiEndpoint}`;
    }
    return `${this.version}/${this.apiEndpoint}`;
  }
}
