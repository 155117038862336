import ApiService from '@/api/http/ApiService';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { CartRecommendation } from '@/interfaces/models/CartRecommendation';
import { CartRecommendationTagResult } from '@/interfaces/recommendation/CartRecommendationTagResult';
import axios, { AxiosPromise } from 'axios';

export default class CartRecommendationApiService extends ApiService<CartRecommendation> {
  constructor() {
    super('general/cartrecommendation');
  }

  public index(
    page?: Page | null | undefined,
    filter?: any,
  ): AxiosPromise<Pagination<CartRecommendation> | CartRecommendation[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    if (page) {
      return axios.get(`${this.getBaseUrl()}/?page=${page.page}&${query}`);
    }

    return axios.get(`${this.getBaseUrl()}/?${query}`);
  }

  public store(param: any): AxiosPromise<CartRecommendation> {
    return axios.post(`${this.getBaseUrl()}/`, param);
  }

  public update(param: any): AxiosPromise<CartRecommendation> {
    return axios.patch(`${this.getBaseUrl()}/${param.id}`, param);
  }

  public show(param: { id: string }): AxiosPromise<CartRecommendation> {
    return axios.get(`${this.getBaseUrl()}/${param.id}`);
  }

  public showByVenue(param: { venue: string }): AxiosPromise<CartRecommendation> {
    return axios.get(`${this.getBaseUrl()}/venue/${param.venue}`);
  }

  public softDelete(param: { id: string }): AxiosPromise<CartRecommendation> {
    return axios.delete(`${this.getBaseUrl()}/${param.id}`);
  }

  public recommendation(
    filter: Filter,
    articles: Array<{
      // @ts-ignore
      _id: string;
      quantity: number;
    }>,
  ): AxiosPromise<CartRecommendationTagResult[]> {
    return axios.post(`${this.getBaseUrl()}/result`, {
      venue: filter.venue,
      orderType: filter.orderType,
      subOrderType: filter.subOrderType,
      articles,
    });
  }
}
