import Filter from '@/interfaces/api/Filter';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { EposJob } from '@/interfaces/models/EposJob';
import { EposJobsApiService } from '@/api/http/EposJobsApiService';
import moment from 'moment';

interface EposJobsState {
  filter: Filter;
  items: EposJob[];
  pagination: {
    total: number;
  };
}

const state: EposJobsState = {
  filter: {},
  items: [],
  pagination: {
    total: 0,
  },
};

const api: EposJobsApiService = new EposJobsApiService();
const actions: ActionTree<EposJobsState, RootState> = {
  fetch({ commit, state }, page?: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<EposJob[]>) => {
      commit('setItems', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  setEndOfDayFilter({ commit }, filter: Filter) {
    commit('setEndOfDayFilter', filter);
  },
  sortByCreatedAt({ commit, state }, filter: Filter) {
    if (filter && filter.sortType) {
      if (filter.sortType === 'asc') {
        const sortedArray = state.items.slice().sort((a, b) => {
          return moment(a.createdAt).diff(moment(b.createdAt));
        });
        commit('setItems', { data: sortedArray });
      } else {
        const sortedArray = state.items.slice().sort((a, b) => {
          return moment(b.createdAt).diff(moment(a.createdAt));
        });
        commit('setItems', { data: sortedArray });
      }
    }
  },
};

const mutations: MutationTree<EposJobsState> = {
  setItems(state: EposJobsState, data: Pagination<EposJob>) {
    state.items = (data.data as EposJob[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: EposJobsState, filter: Filter) {
    state.filter = filter;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
