import Vue from 'vue';
import { Localization } from '@/interfaces/models/Localization';
import moment from 'moment';
import i18n from '@/i18n';
import { Venue } from './interfaces/models/Venue';

Vue.filter('prettyArray', (value: any[]) => {
  if (value && value.length) {
    return value.join(', ');
  }
  return '';
});

Vue.filter('localized', (value: Localization): string | Localization => {
  // @ts-ignore
  if (value && value[i18n.locale] !== undefined && value[i18n.locale] !== null) {
    // @ts-ignore
    return value[i18n.locale] || value.de;
  }

  return value;
});

Vue.filter('decimal', (value: { $numberDecimal: string } | number) => {
  let val: number | null;
  if (typeof value === 'object') {
    if (value && value.$numberDecimal) {
      val = parseFloat(value.$numberDecimal);
    } else {
      val = 0;
    }
  } else {
    val = value;
  }

  const num: string = Number(Math.round(parseFloat(val + 'e2')) + 'e-2').toFixed(2);
  return num.replace('.', ',');
});

Vue.filter('moment', (value: string, format: string) => {
  if (value !== undefined) {
    const momentStr = moment(value);
    if (momentStr.isValid()) {
      return momentStr.format(format);
    }
  }
  return '-';
});

Vue.filter('toLocalTime', (time: string) => {
  if (!moment(time, 'HH:mm').isValid()) {
    return null;
  }

  if (moment().isDST()) {
    return moment(time, 'HH:mm').add(moment().utcOffset(), 'minutes').format('HH:mm');
  }

  return moment(time, 'HH:mm')
    .add(moment().utcOffset() + 60, 'minutes')
    .format('HH:mm');
});
