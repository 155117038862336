import ApiService from '@/api/http/ApiService';
import { Client } from '@/interfaces/models/Client';
import axios, { AxiosPromise } from 'axios';

export default class AuthApiService extends ApiService<Client> {
  constructor() {
    super('auth/client');
  }

  public addClient(data: { clientId: string }) {
    return axios.post(`${this.getBaseUrl()}/device`, data);
  }

  public showSecrets(param: { id: string }): AxiosPromise<Client> {
    return axios.get(`${this.getBaseUrl()}/${param.id}/secrets`);
  }
}
