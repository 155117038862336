import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Tag } from '@/interfaces/models/Tag';
import Filter from '@/interfaces/api/Filter';
import TagServiceApi from '@/api/http/TagServiceApi';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';

interface TagState {
  active: Tag | null;
  items: Tag[];
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: TagState = {
  active: null,
  items: [],
  filter: {},
  pagination: {
    total: 0,
  },
};

const api: TagServiceApi = new TagServiceApi();

const actions: ActionTree<TagState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.getTagsByVenue(page, state.filter).then((res: AxiosResponse<Tag[] | Pagination<Tag>>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<Tag>) {
    return api.store(data).then((res: AxiosResponse<Tag>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Tag>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<Tag>) {
    return api.update(data).then((res: AxiosResponse<Tag>) => {
      commit('update', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.delete(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};
const mutations: MutationTree<TagState> = {
  setItems(state: TagState, data: Pagination<Tag>) {
    state.items = (data.data as Tag[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: TagState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: TagState, data: Tag) {
    state!.items.push(data);
  },
  update(state: TagState, data: Tag) {
    state.items = [...(state.items as Tag[]).filter((item: Tag) => item._id !== data._id), data];
  },
  setActive(state: TagState, data: Tag) {
    state.active = data;
  },
  removeItem(state: TagState, data: { _id: string }) {
    state.items = state.items.filter((item: Tag) => item._id !== data._id);
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
