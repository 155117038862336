import ApiService from '@/api/http/ApiService';
import { Credentials } from '@/interfaces/auth/Credentials';
import axios, { AxiosPromise } from 'axios';
import { AuthResponse } from '@/interfaces/auth/AuthResponse';
import { Role } from '@/interfaces/models/Role';
import { User } from '@/interfaces/models/User';

export default class AuthApiService extends ApiService<any> {
  constructor() {
    super('auth');
  }

  public login(data: Credentials): AxiosPromise<AuthResponse> {
    return axios.post(`${this.getBaseUrl()}/login`, data);
  }

  public forgotPassword(data: { email: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/password/request`, data);
  }

  public resetPassword(data: { token: string; password: string; confirmation: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/password`, data);
  }

  public register(data: User): AxiosPromise<User> {
    return axios.post(`${this.getBaseUrl()}/register`, data);
  }

  public registerFranchisee(data: Partial<User>): AxiosPromise<User> {
    return axios.post(`${this.getBaseUrl()}/register/franchisee`, data);
  }

  public validateEmail(email: string, body: { id?: string }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/validation/email`, { email, ...body });
  }

  public getRoles(): AxiosPromise<Role[]> {
    return axios.get(`${this.getBaseUrl()}/role`);
  }

  public showRole(data: { id: string }): AxiosPromise<Role> {
    return axios.get(`${this.getBaseUrl()}/role/${data.id}`);
  }

  public storeRole(data: Partial<Role>): AxiosPromise<Role> {
    return axios.post(`${this.getBaseUrl()}/role`, data);
  }

  public deleteRole(data: { id: string }): AxiosPromise<void> {
    return axios.delete(`${this.getBaseUrl()}/role/${data.id}`);
  }

  public updateRole(data: Partial<Role>): AxiosPromise<Role> {
    return axios.patch(`${this.getBaseUrl()}/role/${data.id}`, data);
  }
}
