import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { WoltApiService } from '@/api/http/WoltApiService';
import { AxiosResponse } from 'axios';
import { UberEatsApiService } from '@/api/http/UberEatsApiService';
import { JetApiService } from '@/api/http/JetApiService';
import { AggregatorSyncJob } from '@/interfaces/models/AggregatorSyncJob';

const woltApiService: WoltApiService = new WoltApiService();
const uberApiService: UberEatsApiService = new UberEatsApiService();
const jetApiService: JetApiService = new JetApiService();

type SyncKey = 'wolt' | 'uberEats' | 'jet';

interface SyncState {
  items: AggregatorSyncJob[];
  filter: Filter;
  pagination: {
    total: number;
  };
}

interface AggregatorSyncsState {
  wolt: SyncState;
  uberEats: SyncState;
  jet: SyncState;
}

const state: AggregatorSyncsState = {
  jet: { items: [], filter: {}, pagination: { total: 0 } },
  wolt: { items: [], filter: {}, pagination: { total: 0 } },
  uberEats: { items: [], filter: {}, pagination: { total: 0 } },
};

const actions: ActionTree<AggregatorSyncsState, RootState> = {
  fetchWolt({ commit, state }) {
    return woltApiService
      .getSyncJobs({ ...state.wolt.filter })
      .then((res: AxiosResponse<AggregatorSyncJob[] | Pagination<AggregatorSyncJob>>) => {
        commit('setItems', { key: 'wolt', data: res.data });
      });
  },
  fetchJet({ commit, state }) {
    return jetApiService
      .getSyncJobs({ ...state.jet.filter })
      .then((res: AxiosResponse<AggregatorSyncJob[] | Pagination<AggregatorSyncJob>>) => {
        commit('setItems', { key: 'jet', data: res.data });
      });
  },
  fetchUberEats({ commit, state }) {
    return uberApiService
      .getSyncJobs({ ...state.uberEats.filter })
      .then((res: AxiosResponse<AggregatorSyncJob[] | Pagination<AggregatorSyncJob>>) => {
        commit('setItems', { key: 'uberEats', data: res.data });
      });
  },
  setFilter({ commit }, { filter, key }) {
    Object.keys(filter).forEach((key) => {
      if (!filter[key]) {
        delete filter[key];
      }
    });
    commit('setFilter', { filter, key });
  },
};

const mutations: MutationTree<AggregatorSyncsState> = {
  setItems(state: AggregatorSyncsState, { key, data }: { key: SyncKey; data: Pagination<any> }) {
    state[key].items = (data.data as any[]) || data;
    if (data.total) {
      state[key].pagination.total = data.total;
    }
  },
  setFilter(state: AggregatorSyncsState, { key, filter }: { key: SyncKey; filter: Filter }) {
    state[key].filter = filter;
  },
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
};
