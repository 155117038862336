import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Constrain } from '@/interfaces/models/Constrain';
import Filter from '@/interfaces/api/Filter';
import ConstrainServiceApi from '@/api/http/ConstrainServiceApi';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';

interface ConstrainState {
  items: Constrain[];
  active: Constrain | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: ConstrainState = {
  items: [],
  active: null,
  filter: {},
  pagination: {
    total: 0,
  },
};

const api: ConstrainServiceApi = new ConstrainServiceApi();

const actions: ActionTree<ConstrainState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api
      .getConstrainsByVenue(page, state.filter)
      .then((res: AxiosResponse<Constrain[] | Pagination<Constrain>>) => {
        commit('setItems', res.data);
      });
  },
  store({ commit }, data: Partial<Constrain>) {
    return api.store(data).then((res: AxiosResponse<Constrain>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Constrain>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<Constrain>) {
    return api.update(data).then((res: AxiosResponse<Constrain>) => {
      commit('update', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<ConstrainState> = {
  setItems(state: ConstrainState, data: Pagination<Constrain>) {
    state.items = (data.data as Constrain[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: ConstrainState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: ConstrainState, data: Constrain) {
    state!.items.push(data);
  },
  update(state: ConstrainState, data: Constrain) {
    state.items = [...(state.items as Constrain[]).filter((item: Constrain) => item._id !== data._id), data];
  },
  setActive(state: ConstrainState, data: Constrain) {
    state.active = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
