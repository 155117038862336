import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import Filter from '@/interfaces/api/Filter';
import { PrinterResponse } from '@/interfaces/api/PrinterResponse';
import { Printer } from '@/interfaces/models/Printer';

export default class PrinterApiService extends ApiService<Printer> {
  constructor() {
    super('general/venue');
  }

  public fetch(data: Filter): AxiosPromise<PrinterResponse> {
    return axios.get(`${this.getBaseUrl()}/${data.venue}/printer/`, data);
  }

  public fetchAll(filter: Filter): AxiosPromise<PrinterResponse> {
    return axios.get(`${this.getBaseUrl()}/printer`, { params: filter });
  }

  public update(data: { venue: string; printer: string; articles: string[] }) {
    return axios.put(`${this.getBaseUrl()}/${data.venue}/printer/${data.printer}`, data);
  }

  public restart(data: { venue: string; printer: string }) {
    return axios.post(`${this.getBaseUrl()}/${data.venue}/printer/restart/${data.printer}`);
  }
}
