import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Customer } from '@/interfaces/models/Customer';
import CustomerApiService from '@/api/http/CustomerApiService';
import { AxiosResponse } from 'axios';

const api: CustomerApiService = new CustomerApiService();

interface CustomerState {
  items: Customer[];
  active: Customer | null;
}

const state: CustomerState = {
  items: [],
  active: null,
};

const actions: ActionTree<CustomerState, RootState> = {
  fetch({ commit }, data: string[]) {
    return api.getCustomers(data).then((res: AxiosResponse<Customer[]>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<Customer>) {
    return api.storeCustomer(data).then((res: AxiosResponse<Customer>) => {
      commit('addItem', res.data);
    });
  },
  update({ commit }, data: Partial<Customer>) {
    return api.updateCustomer(data).then((res: AxiosResponse<Customer>) => {
      commit('update', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.getCustomers([data.id]).then((res: AxiosResponse<Customer[]>) => {
      commit('setActive', res.data.pop());
    });
  },
};

const mutations: MutationTree<CustomerState> = {
  setItems(state: CustomerState, data: Customer[]) {
    state.items = data;
  },
  addItem(state: CustomerState, data: Customer) {
    state.items.push(data);
  },
  update(state: CustomerState, data: Customer) {
    state.active = data;
    state.items = [...state.items.filter((i: Customer) => i._id !== data._id), data];
  },
  setActive(state: CustomerState, data: Customer) {
    state.active = data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
