import { PromoCode } from '@/interfaces/models/PromoCode';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { PromoCodeApiService } from '@/api/http/PromoCodeApiService';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import Filter from '@/interfaces/api/Filter';
import { Venue } from '@/interfaces/models/Venue';
import { PromoCodeTeedeliApiService } from '@/api/http/PromoCodeTeedeliApiService';
import { PromoCodeTeedeli } from '@/interfaces/models/PromoCodeTeedeli';

interface PromoCodeState {
  items: PromoCode[];
  active: PromoCode | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: PromoCodeApiService = new PromoCodeApiService();
const apiTeedeli: PromoCodeTeedeliApiService = new PromoCodeTeedeliApiService();

const state: PromoCodeState = {
  active: null,
  items: [],
  filter: {},
  pagination: { total: 0 },
};

const actions: ActionTree<PromoCodeState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<PromoCode[] | Pagination<PromoCode>>) => {
      commit('setItems', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<PromoCode>) => {
      commit('setActive', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  store({ commit }, data: Partial<PromoCode>) {
    return api.store(data).then((res: AxiosResponse<PromoCode>) => {
      commit('addItem', res.data);
      commit('setActive', res.data);
    });
  },
  storeTeedeliCode({ commit }, data: Partial<PromoCodeTeedeli>) {
    return apiTeedeli.storeTeedeliCode(data).then((res: AxiosResponse<PromoCodeTeedeli>) => {
      commit('addItem', res.data);
      commit('setActive', res.data);
    });
  },
  generate({ commit }, data: { code: Partial<PromoCode>; amount: number }) {
    return api.generate(data);
  },
  update({ commit }, data: Partial<PromoCode>) {
    return api.update(data).then((res: AxiosResponse<PromoCode>) => {
      commit('update', res.data);
    });
  },
  uploadGiftCards({ commit }, data: { id: string; file: FormData }) {
    return api.uploadGiftCards(data, data.file);
  },
  activatePromoCode({ commit }, data: { id: string }) {
    return api.activate(data).then((res: AxiosResponse<PromoCode>) => {
      commit('update', res.data);
    });
  },
  enablePromoCode({ commit, dispatch }, data: { id: string; venue: string }) {
    return api.enableCodes({ venue: data.venue, promoCodes: [data.id] }).then((res: AxiosResponse<Venue>) => {
      dispatch('venue/setActive', res.data, { root: true });
    });
  },
  deactivatePromoCode({ commit }, data: { id: string }) {
    return api.deactivate(data).then((res: AxiosResponse<PromoCode>) => {
      commit('update', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroy(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  disablePromoCode({ commit, dispatch }, data: { id: string; venue: string }) {
    return api.disableCodes({ venue: data.venue, promoCodes: [data.id] }).then((res: AxiosResponse<Venue>) => {
      dispatch('venue/setActive', res.data, { root: true });
    });
  },
};

const mutations: MutationTree<PromoCodeState> = {
  setItems(state: PromoCodeState, data: Pagination<PromoCode>) {
    state.items = (data.data as PromoCode[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: PromoCodeState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: PromoCodeState, data: PromoCode) {
    state!.items.push(data);
  },
  update(state: PromoCodeState, data: PromoCode) {
    state.items = [...(state.items as PromoCode[]).filter((item: PromoCode) => item._id !== data._id), data];
  },
  setActive(state: PromoCodeState, data: PromoCode) {
    state.active = data;
  },
  removeItem(state: PromoCodeState, data: PromoCode) {
    state.items = state.items.filter((item: PromoCode) => item._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
