import { Page } from '@/interfaces/api/Page';
import ApiService from './ApiService';
import axios, { AxiosPromise } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { CustomerGroupProperty } from '@/interfaces/models/CustomerGroupProperty';
import moment from 'moment';
import { GroupTemplateName } from '@/enums/GroupTemplateName';

export default class CustomerGroupPropertyApiService extends ApiService<any> {
  constructor() {
    super('general/customergroupproperty');
  }

  public index(
    page?: Page | null | undefined,
    filter?: any,
  ): AxiosPromise<Pagination<CustomerGroupProperty> | CustomerGroupProperty[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    if (page) {
      return axios.get(`${this.getBaseUrl()}/paginate?page=${page.page}&${query}`);
    }

    return axios.get(`${this.getBaseUrl()}/paginate?${query}`);
  }

  public show(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/${param.id}`);
  }

  public store(data: Partial<CustomerGroupProperty>) {
    return axios.post(`${this.getBaseUrl()}`, data);
  }

  public updateCustomerGroupProperty(data: Partial<CustomerGroupProperty>) {
    return axios.patch(`${this.getBaseUrl()}/${data.id}`, data);
  }

  //   public uploadCustomerGroupPropertyTemplate(data: Partial<CustomerGroupProperty>) {
  //     return axios.patch(`${this.getBaseUrl()}/${data.id}/template`, data); //
  //   }

  public delete(param: { id: string }) {
    return axios.delete(`${this.getBaseUrl()}/${param.id}`);
  }

  public importTemplate(data: { groupId: string; templateName: string; file: FormData }) {
    return axios.post(`${this.getBaseUrl()}/${data.groupId}/template/${data.templateName}`, data.file);
  }
}
