import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { PrintGroups } from '@/interfaces/models/PrintGroups';

export default class PrintGroupsServiceApi extends ApiService<PrintGroups> {
  constructor() {
    super('general/printgroup');
  }

  public getPrintGroupsByVenue(page: Page, filter: Filter): AxiosPromise<PrintGroups[] | Pagination<PrintGroups>> {
    if (page) {
      return axios.get(`${this.getBaseUrl()}/venue/${filter.venue}?page=${page.page}`);
    }

    return axios.get(`${this.getBaseUrl()}/venue/${filter.venue}/`);
  }

  public destroyPrintGroups(data: { _id: string }): AxiosPromise<void> {
    return axios.delete(`${this.version}/general/printgroup/hard`, { data: { printGroups: [data._id] } });
  }
}
