import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import MaintenanceApiService from '@/api/http/MaintenanceApiService';
import { AxiosResponse } from 'axios';

interface MaintenanceState {
  active: boolean;
}

const state: MaintenanceState = {
  active: false,
};

const api: MaintenanceApiService = new MaintenanceApiService();

const actions: ActionTree<MaintenanceState, RootState> = {
  getStatus({ commit }) {
    return api.status().then((res: AxiosResponse<boolean>) => {
      commit('setActive', res.data);
    });
  },
  enable({ commit }) {
    return api.enable().then(() => {
      commit('enable');
    });
  },
  disable({ commit }) {
    return api.disable().then(() => {
      commit('disable');
    });
  },
};

const mutations: MutationTree<MaintenanceState> = {
  setActive(state: MaintenanceState, active: boolean) {
    state.active = active;
  },
  enable(state: MaintenanceState) {
    state.active = true;
  },
  disable(state: MaintenanceState) {
    state.active = false;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
