import ApiService from '@/api/http/ApiService';
import { Category } from '@/interfaces/models/Category';
import axios, { AxiosPromise } from 'axios';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { Article } from '@/interfaces/models/Article';
import { OptionGroup } from '@/interfaces/models/OptionGroup';
import { OptionArticle } from '@/interfaces/models/OptionArticle';
import { ChangeCategoryRequest } from '@/interfaces/models/ChangeCategoryRequest';
import { SubscriptionCategory } from '@/interfaces/models/SubscriptionCategories';
import { Subscription } from '@/interfaces/models/Subscription';

export default class FoodcardApiService extends ApiService<any> {
  constructor() {
    super('general/articlecategory');
  }

  public getSubscriptionCategories(
    page: Page | null | undefined,
    filter?: any,
  ): AxiosPromise<SubscriptionCategory[] | Pagination<SubscriptionCategory>> {
    return axios.get(`${this.version}/general/subscriptioncategory `);
  }

  public updateSubscriptionCategory(data: Partial<SubscriptionCategory>): AxiosPromise<SubscriptionCategory> {
    return axios.patch(`${this.version}/general/subscriptioncategory/${data._id}`, data);
  }

  public addSubscriptionToSubscriptionCategory(data: {
    subscriptionCategory: string;
    subscription: string;
  }): AxiosPromise<SubscriptionCategory> {
    return axios.post(`${this.version}/general/subscriptioncategory/${data.subscriptionCategory}/addSubscription`, {
      subscription: data.subscription,
    });
  }

  public removeSubscriptionToSubscriptionCategory(data: {
    subscriptionCategory: string;
    subscription: string;
  }): AxiosPromise<SubscriptionCategory> {
    return axios.post(`${this.version}/general/subscriptioncategory/${data.subscriptionCategory}/removeSubscription`, {
      subscription: data.subscription,
    });
  }

  public updateSubscription(data: Partial<Subscription>): AxiosPromise<Subscription> {
    return axios.patch(`${this.version}/general/subscription/${data._id}`, data);
  }

  public getSubscriptions(): AxiosPromise<Subscription[]> {
    return axios.get(`${this.version}/general/subscription`);
  }

  public getCategories(page: Page | null | undefined, filter?: any): AxiosPromise<Category[] | Pagination<Category>> {
    return this.index(page, filter);
  }

  public getArticleNames(data: { venue: string; categories?: string[] }): AxiosPromise<Array<Partial<Article>>> {
    if (data.categories) {
      return axios.get(`${this.getBaseUrl()}/${data.venue}/articles/names`, {
        params: { categories: data.categories },
      });
    }
    return axios.get(`${this.getBaseUrl()}/${data.venue}/articles/names`);
  }

  public getCategoriesByVenue(data: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/byvenue/${data.id}`);
  }

  public getArticles(page: Page | null | undefined, filter?: any): AxiosPromise<Article[] | Pagination<Article>> {
    if (filter.category && filter.search && page) {
      return axios.get(
        `${this.getBaseUrl()}/${filter.category}/articles?page=${page.page}&search=${filter.search}&limit=${
          filter.limit
        }`,
      );
    }

    if (filter.category && page) {
      return axios.get(`${this.getBaseUrl()}/${filter.category}/articles?page=${page.page}&limit=${filter.limit}`);
    }

    return axios.get(`${this.getBaseUrl()}/${filter.category}/articles`);
  }

  public searchArticles(
    page: Page | null | undefined,
    venue: string,
    filter?: any,
  ): AxiosPromise<Article[] | Pagination<Article>> {
    return axios.get(
      `${this.getBaseUrl()}/${venue}/byvenue/articles?${page ? `&page=${page}` : ''}${
        filter.category ? `&category=${filter.category}` : ''
      }${filter.search ? `&search=${filter.search}` : ''}${filter.priceSort ? `&priceSort=${filter.priceSort}` : ''}${
        filter.limit ? `&limit=${filter.limit}` : ''
      }${filter.hasOwnProperty('filterVisible') ? `&filterVisible=${filter.filterVisible}` : ''}
      `,
    );
  }

  public getCategoriesArticle(venueId: string): AxiosPromise<Article[]> {
    return axios.get(`${this.version}/general/articlecategory/${venueId}/byvenue/articles`);
  }

  public getOptions(data: { venue: string }): AxiosPromise<OptionGroup[]> {
    return axios.get(`${this.version}/general/articleoption/byvenue/${data.venue}`);
  }

  public getPaginatedOptions(
    page: Page | null | undefined,
    filter?: any,
  ): AxiosPromise<OptionGroup[] | Pagination<OptionGroup>> {
    return axios.get(`${this.version}/general/articleoption/byvenue/${filter.venue}/paginated`, {
      params: { page, ...filter },
    });
  }

  public getOptionByArticleId(data: any) {
    return axios.get(`${this.version}/general/articlecategory/${data.categoryId}/article/${data.articleId}/options`);
  }

  public getArticleVisibility(venue: string, filter?: any) {
    return axios.get(`${this.version}/general/articlecategory/visibility/search/${venue}`, {
      params: { ...filter },
    });
  }
  public getGroupedArticlesByNumber(venue: string, number: string) {
    return axios.get(`${this.version}/general/articlecategory/bynumber/${venue}/${number}`);
  }
  public getGroupedOptionArticlesByNumber(venue: string, number: string) {
    return axios.get(`${this.version}/general/articleoption/bynumber/${venue}/${number}`);
  }
  public storeOption(data: Partial<OptionGroup>): AxiosPromise<OptionGroup> {
    return axios.post(`${this.version}/general/articleoption/${data.venue}/`, data);
  }

  public updateOption(data: Partial<OptionGroup>): AxiosPromise<OptionGroup> {
    return axios.patch(`${this.version}/general/articleoption/${data.id}`, data);
  }

  public storeOptionArticle(data: Partial<OptionArticle>): AxiosPromise<OptionArticle> {
    return axios.post(`${this.version}/general/articleoption/${data.group}/article`, data);
  }

  public updateOptionArticle(data: Partial<OptionArticle>): AxiosPromise<OptionArticle> {
    return axios.patch(`${this.version}/general/articleoption/${data.group}/article/${data.id}`, data);
  }

  public deleteOptionArticle(data: { group: string; id: string }): AxiosPromise<OptionArticle> {
    return axios.delete(`${this.version}/general/articleoption/${data.group}/article/${data.id}/hard`);
  }

  public uploadOptionArticleImage(data: { group: string; id: string; image: FormData }): AxiosPromise<OptionArticle> {
    return axios.patch(`${this.version}/general/articleoption/${data.group}/article/${data.id}/photo`, data.image);
  }

  public showOption(data: { id: string }): AxiosPromise<OptionGroup> {
    return axios.get(`${this.version}/general/articleoption/${data.id}`);
  }

  public copyOption(data: { id: string }): AxiosPromise<OptionGroup> {
    return axios.patch(`${this.version}/general/articleoption/${data.id}/copyOption`);
  }

  public storeCategory(data: Partial<Category>): AxiosPromise<{ articleCategories: Category[] }> {
    return axios.post(`${this.getBaseUrl()}/${data.venue}/`, data);
  }

  public storeSubscriptionCategory(data: Partial<SubscriptionCategory>): AxiosPromise {
    return axios.post(`${this.version}/general/subscriptioncategory`, data);
  }

  public storeSubscription(data: Partial<Subscription>): AxiosPromise {
    return axios.post(`${this.version}/general/subscription`, data);
  }

  public storeArticle(data: Partial<Article>): AxiosPromise<{ articles: Article[] }> {
    return axios.post(`${this.getBaseUrl()}/${data.category}/article`, data);
  }

  public updateArticle(data: Partial<Article>): AxiosPromise<Article> {
    return axios.patch(`${this.getBaseUrl()}/${data.category}/article/${data.id}`, data);
  }

  public changeCategory(data: ChangeCategoryRequest): AxiosPromise<Category> {
    return axios.patch(`${this.getBaseUrl()}/${data.category}/article/${data.id}/changeCategory/${data.newId}`);
  }

  public copyArticle(data: ChangeCategoryRequest): AxiosPromise<Category> {
    return axios.patch(`${this.getBaseUrl()}/${data.category}/article/${data.id}/copy/${data.newId}`);
  }

  public makeControlArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venue/${data.venue}/articles/control/activate`, data);
  }

  public undoControlArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venue/${data.venue}/articles/control/deactivate`, data);
  }

  public activateArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venue/${data.venue}/articles/activate`, data);
  }

  public deactivateArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venue/${data.venue}/articles/deactivate`, data);
  }

  public makeArticleVisible(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venue/${data.venue}/articles/visible`, data);
  }

  public hideArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venue/${data.venue}/articles/hide`, data);
  }

  public activateOptionArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.version}/general/articleoption/venue/${data.venue}/articles/activate`, data);
  }

  public deactivateOptionArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.version}/general/articleoption/venue/${data.venue}/articles/deactivate`, data);
  }

  public makeArticleOptionVisible(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.version}/general/articleoption/venue/${data.venue}/articles/visible`, data);
  }

  public makeOptionsVisible(data: { venue: string; options: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.version}/general/articleoption/venue/${data.venue}/options/visible`, data);
  }

  public hideOptions(data: { venue: string; options: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.version}/general/articleoption/venue/${data.venue}/options/hide`, data);
  }

  public hideOptionArticle(data: { venue: string; articles: string[] }): AxiosPromise<void> {
    return axios.patch(`${this.version}/general/articleoption/venue/${data.venue}/articles/hide`, data);
  }

  public makeCategoryVisible(data: { id: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/visible`);
  }

  public hideCategory(data: { id: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/hide`);
  }

  public updateCategory(data: Partial<Category>): AxiosPromise<Category> {
    return this.update(data);
  }

  public destroySubscriptionCategory(data: { _id: string }): AxiosPromise<void> {
    return axios.delete(`${this.version}/general/subscriptioncategory/${data._id}`);
  }

  public destroySubscription(data: { _id: string }): AxiosPromise<void> {
    return axios.delete(`${this.version}/general/subscription/${data._id}`);
  }

  public destroyCategory(data: { id: string }): AxiosPromise<void> {
    return this.destroy(data);
  }

  public destroyOption(data: { id: string }): AxiosPromise<void> {
    return axios.delete(`${this.version}/general/articleoption/${data.id}/hard`);
  }

  public destroyGroupOfOption(data: { ids: string[] }): AxiosPromise<void> {
    return axios.delete(`${this.version}/general/articleoption/batch`, { data });
  }

  public uploadCategoryImage(data: { id: string; venue: string; image: FormData }) {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/photo?venue=${data.venue}`, data.image);
  }

  public uploadSubscriptionCategoryImage(data: { _id: string; image: FormData }) {
    return axios.patch(`${this.version}/general/subscriptioncategory/${data._id}`, data.image);
  }

  public uploadCategoryIcon(data: { id: string; venue: string; icon: FormData }) {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/icon?venue=${data.venue}`, data.icon);
  }

  public uploadArticleImage(data: { id: string; category: string; image: FormData }): AxiosPromise<Article> {
    return axios.patch(`${this.getBaseUrl()}/${data.category}/article/${data.id}/photo`, data.image);
  }

  public uploadArticleIcon(data: { id: string; category: string; image: FormData }): AxiosPromise<Article> {
    return axios.patch(`${this.getBaseUrl()}/${data.category}/article/${data.id}/icon`, data.image);
  }

  public uploadArticleBanner(data: { id: string; category: string; image: FormData }): AxiosPromise<Article> {
    return axios.patch(`${this.getBaseUrl()}/${data.category}/article/${data.id}/banner`, data.image);
  }

  public uploadOptionImage(data: { id: string; image: FormData }): AxiosPromise<OptionGroup> {
    return axios.patch(`${this.version}/general/articleoption/${data.id}/photo`, data.image);
  }

  public destroyArticle(data: { id: string; category: string }): AxiosPromise<void> {
    return axios.delete(`${this.getBaseUrl()}/${data.category}/article/${data.id}/hard`);
  }

  public showArticle(data: { id: string; category: string }): AxiosPromise<any> {
    return axios.get(`${this.getBaseUrl()}/${data.category}/article/${data.id}`);
  }

  public importFoodcard(data: { id: string; file: FormData }) {
    return axios.post(`${this.version}/general/venue/${data.id}/foodcard/csv/import`, data.file);
  }

  public importNutritions(data: { id: string; articleQueryField: string; optionsQueryField: string; file: FormData }) {
    return axios.post(
      `${this.version}/general/data/nutritions/csv/${data.id}?articleQueryField=${data.articleQueryField}&optionsQueryField=${data.optionsQueryField}`,
      data.file,
    );
  }

  public importArticlesInfo(data: { id: string; file: FormData }) {
    return axios.post(`${this.version}/general/data/import/articles-info/${data.id}`, data.file);
  }

  public exportFoodcard(data: { id: string }): AxiosPromise<BlobPart> {
    return axios.get(`${this.version}/general/venue/${data.id}/foodcard/csv/export`);
  }

  public exportCsvFoodcard(data: { id: string }): AxiosPromise<BlobPart> {
    return axios.get(`${this.version}/general/venue/${data.id}/foodcard/export/?format=csv`, { responseType: 'blob' });
  }

  public exportXlsxFoodcard(data: { id: string }): AxiosPromise<BlobPart> {
    return axios.get(`${this.version}/general/venue/${data.id}/foodcard/export?format=xlsx&lang=de`, {
      responseType: 'blob',
    });
  }

  public copyArticles(data: { sourceCategory: string; targetCategory: string; articles: string[] }): AxiosPromise<any> {
    return axios.post(`${this.version}/general/venue/articles/copy`, data);
  }
}
