import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Download } from '@/interfaces/models/Download';

interface DownloadState {
  downloads: Download[];
}

const state: DownloadState = {
  downloads: [],
};

const actions: ActionTree<DownloadState, RootState> = {
  addDownload({ commit }, d: Download) {
    commit('addDownload', d);
  },
  updateDownload({ commit }, d: Download) {
    commit('updateDownload', d);
  },
  removeDownload({ commit }, d: Download) {
    commit('removeDownload', d);
  },
};

const mutations: MutationTree<DownloadState> = {
  addDownload(state: DownloadState, d: Download) {
    state.downloads.push(d);
  },
  updateDownload(state: DownloadState, d: Download) {
    state.downloads = [...state.downloads.filter((download: Download) => download.file !== d.file), d];
  },
  removeDownload(state: DownloadState, d) {
    state.downloads = [...state.downloads.filter((download: Download) => download.file !== d.file)];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
