import ApiService from '@/api/http/ApiService';
import { Delfi } from '@/interfaces/models/Delfi';
import axios, { AxiosPromise } from 'axios';

export class DelfiApiService extends ApiService<any> {
  constructor() {
    super('delfi');
  }

  public getDelfi(venue: string): AxiosPromise<Delfi> {
    return axios.get(`${this.getBaseUrl()}/venueInfo/${venue}`);
  }

  public storeDelfi(param: { payload: Partial<Delfi> }): AxiosPromise<Delfi> {
    return axios.post(`${this.getBaseUrl()}/venueInfo`, param.payload);
  }

  public updateDelfi(param: { venue: string; payload: Partial<Delfi> }): AxiosPromise<Delfi> {
    return axios.patch(`${this.getBaseUrl()}/venueInfo/${param.venue}`, param.payload);
  }
}
