import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Job } from '@/interfaces/models/Job';
import { Page } from '@/interfaces/api/Page';

export class AutomaticReportApiService extends ApiService<any> {
  constructor() {
    super('general/job');
  }

  public index(page?: Page | null | undefined, filter?: any): AxiosPromise<Job[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }
    if (page) {
      return axios.get(`${this.getBaseUrl()}/?page=${page.page}&limit=${page.limit}&${query}`);
    }

    return axios.get(`${this.getBaseUrl()}`);
  }

  public show(param: { id: string }): AxiosPromise<Job> {
    return axios.get(`${this.getBaseUrl()}/${param.id}`);
  }

  public getJobByVenue(page?: Page | null | undefined, filter?: any): AxiosPromise<Job[]> {
    return axios.get(`${this.getBaseUrl()}/byvenue/${filter.venueId}`);
  }

  public getJobByGroup(page?: Page | null | undefined, filter?: any): AxiosPromise<Job[]> {
    return axios.get(`${this.getBaseUrl()}/bygrpoup/${filter.customerGroup}`);
  }
  public store(data: Partial<Job>) {
    return axios.post(`${this.version}/general/job`, data);
  }

  public update(data: { id: string; job: Job }): AxiosPromise<Job> {
    return axios.patch(`${this.getBaseUrl()}/general/job/${data.id}`, data.job);
  }
}
