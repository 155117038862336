
import { Component, Vue, Watch } from 'vue-property-decorator';
import { lang } from '@/language';

@Component
export default class LanguagePicker extends Vue {
  public language: string = 'en';

  get languages() {
    return [
      { value: 'de', text: this.$t('languages.de'), icon: require('@/assets/svg/German.svg') },
      { value: 'en', text: this.$t('languages.en'), icon: require('@/assets/svg/GB.svg') },
      { value: 'fr', text: this.$t('languages.fr'), icon: require('@/assets/svg/French.svg') },
    ];
  }

  public mounted(): void {
    const langPreference: string = localStorage.getItem('language')!;
    if (langPreference) {
      this.$i18n.locale = langPreference;
      this.language = langPreference;
    } else {
      this.$i18n.locale = lang;
    }
  }

  @Watch('language')
  public onLanguageChange(val: string) {
    this.$i18n.locale = val;
    localStorage.setItem('language', val);
  }
}
