/* tslint:disable:no-console */

import { register } from 'register-service-worker';

register(`${process.env.BASE_URL}service-worker.js`, {
  registered() {
    console.log('Service worker has been registered.');
  },
  cached() {
    console.log('Content has been cached for offline use.');
  },
  updatefound() {
    console.log('New content is downloading.');
  },
  updated() {
    console.log('New content is available.');
    // @ts-ignore
    const version: string = VUE_APP_VERSION;
    const usedVersion: string | null = localStorage.getItem('version');

    if (!usedVersion || version !== usedVersion) {
      localStorage.setItem('version', version);
      location.reload(true);
      console.log('Updated version.');
      return;
    }
  },
  offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error(error) {
    console.error('Error during service worker registration:', error);
  },
});
