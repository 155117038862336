import Filter from '@/interfaces/api/Filter';
import { Payment } from '@/interfaces/models/Payment';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import ProvisionApiService from '@/api/http/ProvisionApiService';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';

interface ProvisionState {
  items: Payment[];
  filter: Filter;
  pagination: {
    total: number;
  };
}

const state: ProvisionState = {
  filter: {},
  items: [],
  pagination: {
    total: 0,
  },
};

const api: ProvisionApiService = new ProvisionApiService();

const actions: ActionTree<ProvisionState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<Pagination<Payment> | Payment[]>) => {
      commit('setItems', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
};

const mutations: MutationTree<ProvisionState> = {
  setFilter(state: ProvisionState, filter: Filter) {
    state.filter = filter;
  },
  setItems(state: ProvisionState, data: Pagination<Payment>) {
    state.items = (data.data as Payment[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
