import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Pagination } from '@/interfaces/api/Pagination';
import { AxiosResponse } from 'axios';
import { InventoryApiService } from '@/api/http/InventoryApiService';
import Filter from '@/interfaces/api/Filter';
import { Article } from '@/interfaces/models/Article';
import { StockManagementType } from '@/enums/StockManagementType';
import { Inventory } from '@/interfaces/models/Inventory';

interface GroupedArticle {
  article: Article;
  type: StockManagementType;
}

interface InventoryState {
  groupedArticles: { article: GroupedArticle[]; option: GroupedArticle[] };
  active: any | null;
  items: any[];
  pagination: {
    total: number;
  };
  filter: Filter;
}

const state: InventoryState = {
  groupedArticles: { article: [], option: [] },
  active: null,
  items: [],
  pagination: {
    total: 0,
  },
  filter: {},
};

const api: InventoryApiService = new InventoryApiService();

const actions: ActionTree<InventoryState, RootState> = {
  fetch({ commit, state }, venue) {
    return api.getInventory({ venue, params: state.filter }).then((res: AxiosResponse<any[] | Pagination<any>>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<any>) {
    return api.storeInventory(data).then((res: AxiosResponse<any>) => {
      commit('addItem', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.softDelete(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  updateInventory({ commit }, data: { venue: string; id: string; inventory: Partial<Inventory> }) {
    return api.updateInventory(data);
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  setGroupedArticle({ commit }, data: { article: GroupedArticle[]; option: GroupedArticle[] }) {
    commit('setGroupedArticle', data);
  },
};
const mutations: MutationTree<InventoryState> = {
  setItems(state: InventoryState, data: Pagination<Inventory>) {
    state.items = (data.data as Inventory[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setActive(state: InventoryState, data: Inventory) {
    state.active = data;
  },
  addItem(state: InventoryState, data: Inventory) {
    state!.items.push(data);
  },
  removeItem(state: InventoryState, data: Inventory) {
    state.items = state.items.filter((item: Inventory) => item._id !== data._id);
  },
  setFilter(state: InventoryState, filter: Filter) {
    state.filter = filter;
  },
  setGroupedArticle(state: InventoryState, data: { article: GroupedArticle[]; option: GroupedArticle[] }) {
    state.groupedArticles = data;
  },
};

const getters: GetterTree<InventoryState, RootState> = {
  filteredArticles: (state) => {
    const searchTerm = state.filter?.search?.toLowerCase() || '';

    const filterByFields = (item: any) => {
      if (
        state.filter?.ignoreByNumber?.includes(item.article?.number) ||
        state.filter?.ignoreByNumber?.includes(item.article?.optionNumber)
      ) {
        return true;
      }
      if (!searchTerm) {
        return true;
      }
      return (
        item.article.name?.de?.toLowerCase().includes(searchTerm) ||
        item.article.number?.toLowerCase().includes(searchTerm) ||
        item.article.optionNumber?.toLowerCase().includes(searchTerm)
      );
    };

    const articles = state.groupedArticles?.article?.filter(filterByFields) || [];
    const optionArticles = state.groupedArticles?.option?.filter(filterByFields) || [];

    return [...articles, ...optionArticles];
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
