import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Workflow } from '@/interfaces/models/Workflow';
import WorkflowApiService from '@/api/http/WorkflowApiService';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import { Venue } from '@/interfaces/models/Venue';

const api: WorkflowApiService = new WorkflowApiService();

interface WorkflowState {
  items: Workflow[];
  pagination: {
    total: number;
  };
}

const state: WorkflowState = {
  items: [],
  pagination: {
    total: 0,
  },
};

const actions: ActionTree<WorkflowState, RootState> = {
  fetch({ commit }) {
    return api.index().then((res: AxiosResponse<Workflow[] | Pagination<Workflow>>) => {
      commit('setItems', res.data);
    });
  },
};

const mutations: MutationTree<WorkflowState> = {
  setItems(state: WorkflowState, data: Pagination<Workflow>) {
    state.items = (data.data as Workflow[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
