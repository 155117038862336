import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Page } from '@/interfaces/api/Page';
import Filter from '@/interfaces/api/Filter';
import { Pagination } from '@/interfaces/api/Pagination';
import { Constrain } from '@/interfaces/models/Constrain';

export default class ConstrainServiceApi extends ApiService<Constrain> {
  constructor() {
    super('general/constrain');
  }

  public getConstrainsByVenue(page: Page, filter: Filter): AxiosPromise<Constrain[] | Pagination<Constrain>> {
    if (page) {
      return axios.get(`${this.getBaseUrl()}/venue/${filter.venue}?page=${page.page}`);
    }

    return axios.get(`${this.getBaseUrl()}/venue/${filter.venue}/`);
  }
}
