export default {
  common: {
    edit: 'Edit',
    yes: 'Yes',
    no: 'No',
    search: 'Search',
    none: 'None',
    save: 'Save',
    error: 'Error',
    reset: 'Reset',
    input: 'Input',
    cancel: 'Cancel',
    next: 'Next',
    back: 'Back',
    selectHeaders: 'Select visible headers',
    weekday: 'Weekday',
    from: 'From',
    delete: 'Delete',
    to: 'To',
    deliveryAt: 'Delivery At',
    ok: 'Ok',
    add: 'Add',
    disable: 'Disable',
    enable: 'Enable',
    remove: 'Remove',
    empty: 'There is nothing to see here.',
    selectToImport: 'Please select a file to import',
    selectVenue: 'Please select a venue.',
    import: 'Import',
    export: 'Export',
    legacyExport: 'Legacy Export',
    file: 'File',
    apply: 'Apply',
    applySelectedFilters: 'Apply selected filters',
    filters: 'Filter',
    orders: 'Orders',
    overview: 'Overview',
    others: 'Others',
    skip: 'Skip',
    time: 'Time',
    date: 'Date',
    register: 'Register',
    submit: 'Submit',
    confirmDelete: 'Are you sure you want to delete this item? This action cannot be undone.',
    form: {
      file: 'File',
    },
    all: 'All',
    single: 'Single',
    teedeli: 'Teedeli',
    selectItems: 'Please select at least one item.',
    noSelection: 'No Selection',
    description: 'Description',
    invoice: 'Invoice',
    name: 'Name',
    tag: 'Tag',
    tags: 'Tags',
    test: 'Test',
    shoppingCart: 'Shopping Cart',
    send: 'Send',
    recommendation: 'Recommendation',
    generate: 'Generate',
    selectAll: 'Select all',
  },
  languageField: {
    add: 'Add other',
    picker: 'Language',
  },
  countries: {
    de: 'Germany',
    au: 'Austria',
    fr: 'France',
    li: 'Liechtenstein',
    lu: 'Luxembourg',
    ch: 'Switzerland',
    es: 'Spain',
    is: 'Iceland',
    ic: 'Canary Islands',
    pt: 'Portugal',
  },
  toolbar: {
    selectOrSearchVenue: 'Select or search venue...',
    noSelection: 'No Venue Selected',
    copyShortId: 'Copy short id',
  },
  masterTypes: {
    web: 'Web',
    terminal: 'Terminal',
  },
  login: {
    caption: 'Smoothr is the shortcut for the digitization of your restaurant.',
  },
  dashboard: {
    index: 'Dashboard',
    totalSum: 'Total Revenue',
    totalVenues: 'Showing results of {total} venues.',
    totalNetSum: 'Total Net Revenue',
    orderSum: 'Number of Orders',
    provisionSum: 'Provision',
    form: {
      customerGroup: 'Select Customer Group',
    },
    table: {
      total: 'Total',
      totalNet: 'Total Net',
      numberOfOrders: 'Number Of Orders',
      venue: 'Venue',
      readableId: 'Short ID',
      provision: 'Provision',
    },
  },
  provision: {
    index: 'Provision',
    overview: 'Overview',
    claim: 'Claim Provision',
    select: 'Select or search venue to show payments',
    claimHelp: 'Select a venue and a period to claim the provision.',
    success: 'Success',
    sum: 'Sum',
    waiting: 'Waiting',
    notClaimed: 'Not claimed',
    notification: {
      claiming: 'Provision will be claimed. After a few minutes, please refresh the site to see the changes.',
    },
    form: {
      venue: 'Venue',
    },
    table: {
      sum: 'TotalPrice',
      method: 'Method',
      status: 'Status',
      createdAt: 'Created At',
      provision: 'Provision Status',
      provisionClaimedAt: 'Claimed At',
    },
  },
  inventory: {
    index: 'Inventory',
    overview: 'Overview',
    stock: 'Stock',
    product: 'Products',
    add: 'Create',
    form: {
      stock: 'Stock',
      product: 'Select products',
    },
    table: {
      stock: 'Stock',
      inventoryItems: 'Products',
      createdAt: 'Created At',
    },
  },
  company: {
    index: 'Company',
    overview: 'Overview',
    general: 'Details',
    deliveryAddress: 'Delivery Address',
    invoiceAddress: 'Invoice Address',
    add: 'Create',
    create: 'Create',
    edit: 'Edit',
    form: {
      venue: 'Venue',
      name: 'Name',
      companyCode: 'Company Code',
      email: 'E-Mail',
      phoneNumber: 'Phone Number',
      vatNumber: 'VAT',
      subsidy: 'Subsidy',
      subsidyOrderMinimum: 'Subsidy Order Minimum',
      paymentMethod: 'Payment Method',
      iban: 'Iban',
      storeDiscount: 'Store Discout (%)',
      isPaidByCompany: 'Paid by Company',
      city: 'City',
      area: 'Nearest City',
      street: 'Street',
      streetAddress: 'Street Address',
      number: 'Number',
      company: 'Company',
      postalCode: 'Postal Code',
      gpsManualInput: 'Manual Input (GPS Coordinates)',
      gpsCorrect: 'GPS Coordinates ok?',
      latitude: 'Latitude',
      longitude: 'Longitude',
      deliveryTime: 'Delivery Time',
      useDeliveryAddressAsInvoice: 'Use Delivery Address for Invoice Address',
    },
    table: {
      venue: 'Venue',
      name: 'Name',
      createdAt: 'Created At',
      companyCode: 'Company Code',
      email: 'E-Mail',
      phoneNumber: 'Phone Number',
      vatNumber: 'VAT',
    },
    tabs: {
      general: 'General',
      openingHours: 'Opening Hours',
      deliveryTime: 'Delivery Time',
    },
    notification: {
      emptyOpeningHours: 'The opening hours are empty. Please set the opening hours.',
    },
  },
  notification: {
    form: 'Please fill out the form.',
    400: "The request wasn't valid.",
    401: "Your credentials aren't valid.",
    422: 'There was an unexpected validation error. Please check the form.',
    404: "The requested resource couldn't be found.",
    403: 'You are not allowed to view this page.',
    500: 'There was an internal server error.',
    unknown: 'There was an unknown error.',
    downloadInProgress:
      'Your download will be prepared. You can still navigate on the site. The download of your file will start automatically, when it is ready.',
    downloadFailed: 'One of your downloads failed. Please try again later.',
    downloadSuccess: 'Your download is ready!',
    gpsError:
      'GPS position for provided address was not found. Please change the address or enter gps coordinates manually.',
    successfulDownload: 'The export has been sent to your email address',
    retryCheckoutIntegrationAction: 'Retry successfully',
  },
  gender: {
    index: 'Gender',
    male: 'Male',
    female: 'Female',
    other: 'Other',
  },
  displayModes: {
    default: 'Default',
    launcher: 'Launcher',
    smartPicker: 'Smart Picker',
    detailOverlay: 'Detail Overlay',
    detailDialog: 'Detail Dialog',
    grid: 'Grid',
    pagedGrid: 'Paged Grid',
    circle: 'Circle',
    topSeller: 'Top Seller',
    matrix: 'Matrix',
    banner: 'Banner',
    drinkFlavor: 'Drink Flavor',
    toggleButton: 'Toggle Button',
    flavor: 'Flavor',
    checkbox: 'Checkbox',
    radio: 'Radio',
    code: 'Code',
  },
  displayIdentifiers: {
    extra: 'Extra',
    extra15: 'Extra 15',
    extra30: 'Extra 30',
    extraCheese15: 'Extra Cheese 15',
    extraCheese30: 'Extra Cheese 30',
    size: 'Size',
    size15: 'Size 15',
    size30: 'Size 30',
    cheese: 'Cheese',
    kids: 'Kids',
    salad: 'Salad',
    bread: 'Bread',
    cookie1: '1x Cookie',
    cookie3: '3x Cookie',
    cookie12: '12x Cookie',
    chips: 'Chips',
    sauce: 'Sauce',
    drink: 'Drink',
    water: 'Water',
    tea: 'Tea',
    hotBeverages: 'Hot Beverages',
    menu: 'Menu',
    main: 'Main',
    sub: 'Sub',
    wrap: 'Wrap',
    sides: 'Sides',
    fullPrice: 'Full Price',
    inverse: 'Inverse',
    code: 'Code',
  },
  venueSelector: {
    title: 'Select Venue',
    search: 'Please select a venue',
    back: 'Back to venues',
  },
  currency: {
    euro: 'Euro',
    chf: 'Swiss Frank',
    isk: 'Icelandic kronur',
  },
  routes: {
    venue: {
      index: 'Venue',
      create: 'Create Venue',
      createSimple: 'Create Venue',
      copyFoodcard: 'Copy foodcard',
      edit: 'Edit Venue',
      panic: 'Panic Settings',
      editSimple: 'Edit Venue',
      verify: 'Verify Venue',
      publish: 'Publish Venue',
      migrate: 'Migrate Venue',
      sync: 'Sync Venue',
      copy: 'Copy Venue',
    },
    screensaver: {
      index: 'Screensaver',
      create: 'Create screensaver',
      edit: 'Edit screensaver',
    },
    employeeNotification: {
      index: 'Employee Notification',
      create: 'Create Employee Notification',
    },
    banner: {
      index: 'Banners',
      create: 'Create banner',
      edit: 'Edit banner',
    },
    venueLabel: {
      index: 'Venue Label',
      create: 'Create Venue Label',
      edit: 'Edit Venue Label',
    },
    sepa: {
      index: 'SEPA settings',
    },
    productCombos: {
      index: 'Product combos',
    },
    promo: {
      index: 'Promo Codes',
      create: 'Create Promo Code',
      createTeedeli: 'Create Teedeli Promo Code',
      edit: 'Edit Promo Code',
      export: 'Export Promo Code',
      exportTeedeli: 'Export Teedeli Promo Code',
      disable: 'Disable Promo Codes',
      createMultiple: 'Create Multiple Promo Codes',
    },
    cupCode: {
      index: 'Cup Codes',
      create: 'Create Cup Code',
      edit: 'Edit Cup Code',
    },
    coupon: {
      index: 'Coupon',
      create: 'Create Coupon',
      edit: 'Edit Coupon',
      export: 'Export Coupon',
      disable: 'Disable Coupon',
      createMultiple: 'Create Multiple Coupon',
    },
    maintenance: {
      index: 'Maintenance',
    },
    provision: {
      index: 'Provision',
    },
    inventory: {
      index: 'Manage Inventory',
      create: 'Create Inventory',
      edit: 'Edit Inventory',
    },
    payment: {
      index: 'Payment',
    },
    home: {
      index: 'Home',
    },
    reporting: {
      index: 'Reporting',
    },
    register: {
      index: 'Franchisee Information',
      venue: 'Register Venues',
      success: 'Registration Success',
    },
    analytics: {
      index: 'Analytics',
    },
    sales: {
      index: 'Sales',
    },
    receipt: {
      index: 'Receipts',
    },
    eposJobs: {
      index: 'Epos Jobs',
    },
    sanifairLogs: {
      index: 'Sanifair Logs',
    },
    automatedReports: {
      index: 'Automated Reports',
      create: 'Create Automated Report',
    },
    billing: {
      index: 'Billing',
    },
    userExport: {
      index: 'User export',
    },
    activityLogExport: {
      index: 'Activity logs',
    },
    lightspeed: {
      index: 'Lightspeed',
      success: 'Authorization successful',
      error: 'Authorization failed',
    },
    statistics: {
      index: 'Statistics',
    },
    error: {
      index: 'Error',
      notFound: '404 Page Not Found',
      forbidden: '401 Forbidden',
    },
    user: {
      index: 'User',
      create: 'Create User',
      edit: 'Edit User',
      createSimple: 'Create User',
      editSimple: 'Edit User',
    },
    role: {
      index: 'Role',
      create: 'Create Role',
      edit: 'Edit Role',
    },
    customerGroupProprty: {
      index: 'Customer Group Proprty',
      create: 'Create Customer Group Proprty',
      edit: 'Edit Customer Group Proprty',
    },
    foodcard: {
      index: 'Import Foodcard',
    },
    printer: {
      index: 'Printer',
      edit: 'Edit Printer',
    },
    tag: {
      index: 'Tag',
      create: 'Create Tag',
      edit: 'Edit Tag',
    },
    information: {
      index: 'Information',
      create: 'Create Information',
      edit: 'Edit Information',
    },
    client: {
      index: 'Client',
      create: 'Create Client',
      edit: 'Edit Client',
    },
    constrain: {
      index: 'Tradegroup',
      create: 'Create Tradegroup',
      edit: 'Edit Tradegroup',
    },
    printGroups: {
      index: 'Printgroups',
      create: 'Create Printgroups',
      edit: 'Edit Printgroups',
    },
    dashboard: {
      index: 'Dashboard',
    },
    table: {
      index: 'Table',
      create: 'Create Table',
      edit: 'Edit Table',
    },
    category: {
      index: 'Category',
      create: 'Create Category',
      edit: 'Edit Category',
    },
    subscriptionCategories: {
      index: 'Subscription Category',
      create: 'Create Subscription Category',
      edit: 'Edit Subscription Category',
    },
    subscription: {
      index: 'Subscription',
      create: 'Create Subscription',
      edit: 'Edit Subscription',
    },
    membership: {
      index: 'Membership',
      create: 'Create Membership',
      edit: 'Edit Membership',
    },
    article: {
      index: 'Article',
      create: 'Create Article',
      edit: 'Edit Article',
      editSimple: 'Edit Article',
    },
    articleManager: {
      index: 'Article Manager',
    },
    recommendation: {
      index: 'Cart Recommendation',
      create: 'Create Cart Recommendation',
      edit: 'Edit Cart Recommendation',
    },
    option: {
      index: 'Option',
      create: 'Create Option',
      edit: 'Edit Option',
      editSimple: 'Edit Option',
    },
    workflow: {
      index: 'Workflow',
      create: 'Create Workflow',
      upload: 'Upload Workflow',
      edit: 'Edit Workflow',
      import: 'Import Workflow',
    },
    customer: {
      index: 'Customer',
      create: 'Create Customer',
      edit: 'Edit Customer',
      import: 'Import Customer',
    },
    translation: {
      index: 'Translation',
    },
    loyaltyPrograms: {
      index: 'Loyalty Programs',
      create: 'Create Loyalty Program',
      edit: 'Edit Loyalty Programs',
    },
    sync: {
      index: 'Sync',
      create: 'Create Sync',
    },
    aggregatorSyncs: {
      index: 'Syncs',
    },
    company: {
      index: 'Companies',
      create: 'Create Company',
      edit: 'Edit Company',
    },
    forgotPassword: 'Forgot Password',
    resetPassword: 'Reset Password',
  },
  weekdays: {
    monday: 'Monday',
    mondayShort: 'Mon',
    tuesday: 'Tuesday',
    tuesdayShort: 'Tue',
    wednesday: 'Wednesday',
    wednesdayShort: 'Wed',
    thursday: 'Thursday',
    thursdayShort: 'Thu',
    friday: 'Friday',
    fridayShort: 'Fri',
    saturday: 'Saturday',
    saturdayShort: 'Sat',
    sunday: 'Sunday',
    sundayShort: 'Sun',
  },
  orderStatus: {
    none: 'Default',
    created: 'Created',
    in_preparation: 'In Preparation',
    ready: 'Ready',
    in_payment: 'In Payment',
    awaiting_confirmation: 'Awaiting Confirmation',
    done: 'Done',
    failed: 'Failed',
    validated: 'Validated',
  },
  mainCategories: {
    dish: 'Dish',
    beverage: 'Beverage',
    other: 'Other',
  },
  autoReset: {
    none: 'None',
    morning: 'Morning',
    noon: 'Noon',
    evening: 'Evening',
    midnight: 'Midnight',
  },
  customerGroups: {
    default: 'default',
    defaultInPreparation: 'default_in_preparation',
    subway: 'Subway',
    db: 'Deutsche Bahn',
    lunchroom: 'Lunchroom',
    weilands: 'Weilands',
    cinemaxx: 'Cinemaxx',
    fbf: 'Food By Friends',
    district: 'District',
    slush: 'Slush',
    kochloeffel: 'Kochlöffel',
    immergruen: 'Immergrün',
    myMonza: 'MyMonza',
    subwayFr: 'Subway France',
    subwayLu: 'Subway Luxembourg',
    subwayTerminal: 'Subway Terminal',
    aspasia: 'Aspasia',
    cotidiano: 'Cotidiano',
    bigburger: 'BigBurger',
    koos: 'KOOS',
    deanAndDavid: 'Dean and David',
    frittenwerk: 'Frittenwerk',
    purino: 'Purino',
    ottosBurger: 'Otto´s Burger',
    threeMensKebab: '3 Mens Kebab',
    perfectDay: 'Perfect Day',
    hausmanns: 'Hausmanns',
    doenerwerk: 'Dönerwerk',
    foodlounge: 'Foodlounge',
    mado: 'Mado',
    depot: 'Depot',
    nordsee: 'Nordsee',
    jamies: 'Jamies',
    burgerKing: 'Burger King',
    atlantikFisch: 'Atlantik Fisch',
    moschmosch: 'Moschmosch',
    kamps: 'Kamps',
    coffeeFellows: 'Coffee Fellows',
    hofbrauhaus: 'Hofbrauhaus',
    saray: 'Saray',
    starbucks: 'Starbucks',
    dimatest: 'Dima Test',
    maloa: 'Maloa',
    werk5: 'Werk 5',
    epi: 'EPI',
    iamlove: 'IAMLOVE',
    beetsAndRoots: 'Beets and Roots',
    beetsAndRootsSecond: 'Beets and Roots 2',
    losteria: "L'Osteria",
    shiso: 'Shiso',
    snackElize: 'Snack Elize',
    tabilo: 'TuR_RES',
    tabiloSim: 'TuR_Simphony',
    turAbnahmeRes: 'TUR Abnahme RES',
    turAbnahmeSim: 'TUR Abnahme Sim',
    turAutohof: 'TUR Autohof',
    turAbnahmeAutohof: 'TUR Abnahme Autohof',
    effeGold: 'Effe & Gold',
    lacantine: 'La cantine',
    eam: 'Eam',
    naupaka: 'Naupaka',
    sportsbar: 'Sportsbar',
    cigkoftem: 'Cigkoftem',
    sushisupply: 'Sushi Supply',
    the_ash: 'The Ash',
    markthalle: 'Markthalle',
    sashimi: 'Sashimi',
    bonboncha: 'Bonboncha',
    hulala: 'Hulala',
    backwerk_schweiz: 'Backwerk Schweiz',
    backwerkCh: 'Backwerk CH',
    backfactory: 'Backfactory',
    teedeli: 'Teedeli',
    wohlfuhler: 'Wohlfuhler',
    asiaGourmet: 'Asia Gourmet',
    frittenwerkSecond: 'Frittenwerk 2',
    ricerepublic: 'RiceRepublic',
    goodbytz: 'Goodbytz',
    piratenBurger: 'Piraten Burger',
    tournesol: 'Tournesol',
    brezelKoenig: 'Brezelkönig',
    vincentVegan: 'Vincent Vegan',
    papais: 'Papais',
    theCup: 'The Cup',
    marseilleFoodMarket: 'Marseille Food Market',
    olearys: 'Olearys',
    iceland: 'Iceland',
    tableDot: 'Table Dot',
    sevenByBat: 'Seven by BAT',
    breakBurger: 'Break Burger',
    wok: 'Wok',
    oakberry: 'Oakberry',
    trz: 'TRZ',
    monkeyBurger: 'Monkey Burger',
    pastaFee: 'Pasta Fee',
    potatoe: 'Potatoe',
    pibose: 'Pibosa',
  },
  error: {
    notFound: 'Sorry, the page could not be found.',
    forbidden: 'Forbidden.',
    goHome: 'Go Back to Start',
    forbiddenText: 'Unauthorized. You are not allowed to view this page.',
    notFoundText: "The page you are looking for couldn't be found.",
  },
  foodcard: {
    index: 'Manage Foodcard',
    export_csv: 'Export Foodcard (*.csv)',
    export_csv_de: 'DE Export Foodcard (*.csv)',
    import: 'Import Foodcard',
    importCvs: 'Import foodcard (*.csv)',
    importNutritionCsv: 'Import nutritions (*.csv)',
    export_xlsx_de: 'DE Export Foodcard (*.xlsx)',
    importCvsWithArticleInfo: 'Import info of Articles (*.csv)',
  },
  home: {
    index: 'Home',
    welcome: 'Welcome!',
  },
  tag: {
    index: 'Manage Tags',
    overview: 'Overview',
    add: 'Add Tag',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you haven't added any tags yet. Create your first tag to assign them to your articles and options.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      recommendationTag: 'Recommendation Tag',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      recommendationTag: 'Recommendation Tag',
    },
  },
  information: {
    index: 'Manage Information',
    overview: 'Overview',
    add: 'Add Information',
    edit: 'Edit Information',
    addAction: 'Add Action',
    actions: 'Actions',
    empty:
      "It looks like you haven't added any information yet. Create your first information to inform customers about upcoming events and promos.",
    actionTypes: {
      primary: 'Primary',
      secondary: 'Secondary',
      cancel: 'Cancel',
    },
    form: {
      image: 'Image',
      title: 'Title',
      from: 'From',
      to: 'To',
      description: 'Description',
      url: 'Url',
      action: 'Action',
      actionType: 'Action Type',
      actionName: 'Action Name',
    },
    table: {
      title: 'Title',
      description: 'Description',
      from: 'From',
      to: 'To',
      url: 'Url',
    },
  },
  client: {
    index: 'Manage Clients',
    overview: 'Overview',
    add: 'Add Client',
    secrets: 'Secrets',
    addSecret: 'Add Secret',
    notification: {
      addSecret: 'Secret successfully added!',
    },
    viewSecrets: 'View Secrets',
    empty: "It looks like you haven't added any clients yet. Create your first client.",
    form: {
      name: 'Name',
      isBlocked: 'Access Denied',
      canRegister: 'Registration',
      whitelist: 'Whitelist',
      role: 'Role',
      type: 'Type',
      venues: 'Venues',
    },
    table: {
      name: 'Name',
      type: 'Type',
      clientId: 'Client-ID',
      isBlocked: 'Access Denied',
      canRegister: 'Registration',
      whitelist: 'Whitelist',
      role: 'Role',
      venues: 'Venues',
    },
  },
  role: {
    index: 'Manage Roles',
    overview: 'Overview',
    create: 'Create Role',
    edit: 'Edit Role',
    empty: "It looks like you haven't created any roles yet. Create your first role to give users granular access.",
    help: 'Attention! Only change a role if you are absolutely sure, what are you doing. Also note that system roles cannot be changed.',
    add: 'Add Role',
    table: {
      name: 'Name',
      slug: 'Slug',
      isSystem: 'System Role',
      permissions: 'Permissions',
    },
    form: {
      name: 'Name',
      slug: 'Slug (unique identifier)',
      isSystem: 'System role (if active, role cannot be changed.)',
      permissions: 'Permissions',
    },
  },
  customerGroupProprty: {
    index: 'Manage Customer Group Property',
    overview: 'Overview',
    create: 'Create Customer Group Proprty',
    edit: 'Edit Customer Group Property',
    add: 'Add Customer Group Property',
    importTemplate: 'Import Customer Invoice Template',
    table: {
      name: 'Name',
      template: 'Invoice Template',
      defaultOrderStatus: 'Default Order Status',
      initialOrderStatusStandard: 'Initial OrderStatus Standard',
      initialOrderStatusTerminal: 'Initial Order Status Terminal',
    },
    form: {
      customerGroupName: 'Name',
      getDefaultOrderStatus: 'Default Order Status',
      getInitialOrderStatusStandard: 'Initial Order Status Standard',
      getInitialOrderStatusTerminal: 'Initial Order Status Terminal',
      getInitialOrderStatusVirtual: 'Initial Order Status Virtual',
    },
  },
  venueLabel: {
    index: 'Manage Venue Labels',
    overview: 'Overview',
    create: 'Create Venue Label',
    edit: 'Edit Venue Label',
    empty: "It looks like you haven't created any venue labels yet.",
    add: 'Add Label',
    table: {
      name: 'Name',
      slug: 'Slug',
      venues: 'Venues',
    },
    form: {
      name: 'Name',
      image: 'Image',
      slug: 'Slug (unique identifier)',
      venues: 'Venues',
      visibleTo: 'Visible to Venues',
    },
  },
  recommendation: {
    index: 'Cart Recommendation',
    create: 'Create Cart Recommendation',
    edit: 'Edit Cart Recommendation',
    overview: 'Overview',
    score: 'Score',
    scoreMatrix: 'Score Matrix',
    scoreMatrixNotFound: 'Score Matrix not found',
    best: 'Best',
    worst: 'Worst',
  },
  user: {
    index: 'Manage Users',
    overview: 'Overview',
    create: 'Create User',
    changePassword: 'Change Password',
    help: 'Attention! Only change a user if you are absolutely sure, what are you doing. Otherwise a user may not be able to login.',
    edit: 'Edit User',
    add: 'Add User',
    table: {
      name: 'Name',
      email: 'E-Mail',
      code: 'Code',
      venues: 'Venues',
      role: 'Role',
    },
    form: {
      name: 'Name',
      customerGroup: 'Customer Group',
      linkedCustomerGroup: 'Linked Customer Group',
      email: 'E-Mail',
      password: 'Password',
      passwordConfirmation: 'Password Confirmation',
      venues: 'Venues',
      venueLabels: 'Venue Labels',
      role: 'Role',
    },
  },
  printer: {
    index: 'Manage Printers',
    overview: 'Overview',
    edit: 'Edit Printer',
    customerGroup: 'Customer Group',
    empty: "It looks there aren't any printers yet.",
    table: {
      name: 'Name',
      articleCount: 'Number of Articles',
      venue: 'Venue',
      startedAt: 'Started at',
      activated: 'Activated',
      lastSignal: 'Last Signal',
      latestActivity: 'Latest Activity',
      restart: 'Restart',
      restartInProgress: 'Restart in progress...',
      order: 'Order',
    },
    form: {
      articles: 'Articles',
      name: 'Name',
    },
  },
  customer: {
    index: 'Manage Customers',
    overview: 'Overview',
    edit: 'Edit Customer',
    create: 'Create Customer',
    add: 'Add Customer',
    import: 'Import Customer',
    empty: "It looks like you havent' added any customers yet. Create your first customer.",
    table: {
      userUid: 'User UID',
      name: 'Name',
      email: 'E-Mail',
      qr: 'QR-Code',
    },
    form: {
      email: 'E-Mail',
      gender: 'Gender',
      name: 'Full Name',
      city: 'City',
      street: 'Street',
      number: 'Number',
      postalCode: 'Postal Code',
    },
  },
  workflow: {
    index: 'Manage Workflows',
    overview: 'Overview',
    add: 'Add Workflow',
    import: 'Import Workflow',
    empty:
      // tslint:disable-next-line:max-line-length
      "It looks like you havent' added any workflows yet. Create your first workflow to allow your customers to order.",
    table: {
      status: 'Status',
      code: 'Order Code',
      type: 'Order Type',
      venues: 'Venues',
    },
    form: {
      file: 'File',
    },
  },
  constrain: {
    index: 'Manage Tradegroups',
    overview: 'Overview',
    add: 'Add Tradegroup',
    empty:
      "It looks like you haven't added any tradegroups yet. Create your first tradegroup to assign them to your articles.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      active: 'Active',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      active: 'Active',
    },
  },
  printGroups: {
    index: 'Manage Printgroups',
    overview: 'Overview',
    add: 'Add Printgroups',
    empty:
      "It looks like you haven't added any Printgroups yet. Create your first Printgroups to assign them to your articles.",
    form: {
      name: 'Name',
      identifier: 'Identifer',
      active: 'Active',
    },
    table: {
      name: 'Name',
      identifier: 'Identifier',
      active: 'Active',
    },
  },
  languages: {
    de: 'German',
    en: 'English',
    fr: 'French',
    nl: 'Dutch',
    it: 'Italian',
    pl: 'Polish',
    es: 'Spanish',
    tr: 'Turkish',
    zh: 'Chinese',
    ru: 'Russian',
    da: 'Danish',
    ja: 'Japanese',
    is: 'Icelandic',
    pt: 'Portuguese',
    cz: 'Czech',
  },
  legalForms: {
    ag: 'AG',
    gmbh: 'GmbH',
    ltd: 'UG',
    kg: 'KG',
    pe: 'Einzelgesellschaft',
    other: 'Andere',
    sa: 'SA',
    sarl: 'SARL',
    eurl: 'EURL',
    sas: 'SAS',
    autres: 'AUTRES',
  },
  periods: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    never: 'Never',
  },
  locTypes: {
    bar: 'Bar',
    club: 'Club',
    restaurant: 'Restaurant',
    db: 'Deutsche Bahn',
    cinema: 'Cinema',
    other: 'Other',
  },
  paymentTypes: {
    cc: 'Credit Card',
    pp: 'PayPal',
    sepa: 'SEPA',
    sb: 'Sofort Banking',
    bankTransfer: 'Bank Transfer',
  },
  nav: {
    dashboard: 'Dashboard',
    foodcard: 'Foodcard',
    customer: 'Customers',
    screensaver: 'Screensaver',
    venue: 'Venues',
    venueLabel: 'Venue Labels',
    productCombos: 'Product Combos',
    payment: 'Payments',
    user: 'User',
    table: 'Tables',
    tag: 'Tags',
    information: 'Information',
    client: 'Clients',
    sales: 'Sales',
    workflow: 'Workflows',
    printer: 'Printer',
    translation: 'Translation',
    tradegroup: 'Tradegroups',
    printGroups: 'Print Groups',
    provision: 'Provision',
    inventory: 'Inventory',
    statistic: 'Statistics',
    analytic: 'Analytics',
    receipts: 'Receipts',
    billing: 'Billing',
    userExport: 'User export',
    selectVenue: 'Select venue',
    category: 'Categories',
    subscriptionCategories: 'Subscription Categories',
    subscription: 'Subscription',
    membership: 'Membership levels',
    article: 'Articles',
    articleManager: 'Articles & Options Visibility',
    recommendations: 'Recommendations',
    option: 'Options',
    reporting: 'Reporting',
    import: 'Import',
    role: 'Role',
    sepa: 'SEPA',
    maintenance: 'Maintenance',
    promo: 'Promo Codes',
    banner: 'Banners',
    tips: 'Tips',
    eposJobs: 'Epos jobs',
    automatedReport: 'Automated Reports',
    coupon: 'Coupon',
    employeeNotification: 'Employee Notification',
    loyaltyPrograms: 'Loyalty Programs',
    automatedReports: 'Automated Reports',
    customerGroupProprty: 'Customer Group',
    sanifairLogs: 'Sanifair Logs',
    cupCode: 'Cup codes',
    activityLogExport: 'Activity Log Export',
    sync: 'Sync',
    aggregatorSyncs: 'Aggregator Syncs',
    company: 'Companies',
  },
  auth: {
    logout: 'Logout',
    login: 'Login',
    forgotPassword: 'Forgot Password',
    forgotPasswordHint: 'Please enter your e-email and click reset passsord.',
    resetPassword: 'Reset Password',
    reset: 'Reset Password',
    form: {
      password: 'Password',
      passwordHint:
        'The password must contain at least 8 characters, 1 capital letter and 1 number or special character.',
      passwordConfirmation: 'Password Confirmation',
      email: 'Email',
    },
    notification: {
      forgotPassword:
        'If we have found your e-mail in our system, we will send you an email with a link to reset your password',
      resetPassword: 'Your password has been reset.',
      forbidden: "You don't have permission to view this page.",
      login: 'You have successfully logged in.',
      logout: 'You have successfully logged out.',
      resetPasswordError: 'Either your token could not be found or the token is expired.',
    },
  },
  maintenance: {
    index: 'Maintenance',
    status: 'Maintenance status',
    enable: 'Enable Maintenance',
    disable: 'Disable Maintenance',
    enabled: 'System is currently running in maintenance mode. Some service may not be available.',
    disabled: 'All systems are working fine.',
  },
  register: {
    index: 'Registration',
    account: 'Account',
    register: 'Register',
    success: 'Registration Success',
    addVenue: 'Add Venue',
    removeVenue: 'Remove Venue',
    venue: 'Create Venues',
    documentInfo:
      'Please fill out the following data to generate your imprint, terms and conditions and privacy policy for your webshop. These documents are necessary.',
    successText: 'Your registration was successful. We will inform you via email, when your venues are created.',
    location: 'Location Details',
    invoiceReceiver: 'Invoice Receiver',
    dialog: {
      venue: 'Would you like to create a venue? If you click no, you can still do this later.',
    },
    form: {
      venue: 'Venue',
      name: 'Full Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Phone',
      email: 'E-Mail',
      password: 'Password (min. 6)',
      passwordHint:
        'The password must contain at least 8 characters, 1 capital letter and 1 number or special character.',
      passwordConfirmation: 'Password Confirmation (min. 6)',
      privacy: 'I accept the Data Protection Regulations',
      privacyLink: 'Data Protection Regulations',
    },
    notification: {
      venue:
        'Your new Venue will be added in a few minutes. Please logout and login in 3 minutes to see and edit your new venue.',
    },
  },
  article: {
    index: 'Manage Articles',
    create: 'Create a new Article',
    edit: 'Edit Article',
    copy: 'Copy Article',
    add: 'Add Article',
    availability: 'Availability',
    overview: 'Overview',
    selectArticle: 'Select Article',
    selectCategory: 'Please Select Category',
    general: 'General',
    prices: 'Prices',
    visibility: 'Visible',
    meta: 'Meta',
    measurement: 'Measurement',
    images: 'Images',
    options: 'Options',
    displayPrices: 'Display Prices',
    mappings: 'Article Mappings',
    requirements: 'Article Requirements',
    nutritions: 'Article nutritions',
    defaults: 'Article Defaults',
    recommendations: 'Article Recommendations',
    depArticles: 'Articles',
    availableHours: 'Available Hours',
    addGroupDependency: 'Add Dependency',
    addGroupReverseDependency: 'Add Reverse Dependencies',
    addMappingArticle: 'Add Mapping Article',
    addDepArticles: 'Add Dependency Articles',
    addReverseDepArticles: 'Add Reverse Dependency Articles',
    dependencies: 'Dependencies',
    reverseDependencies: 'Reverse dependencies',
    addDefault: 'Add Default',
    split: 'Split',
    standardPrices: 'Standard Prices',
    preorderPrices: 'Preorder Prices',
    terminalPrices: 'Terminal Prices',
    standardAvailability: 'Standard Availability',
    terminalAvailability: 'Terminal Availability',
    preorderAvailability: 'Preorder Availability',
    priceLockByType: 'Prices lock by order type (general price lock has precedence)',
    webAvailability: 'Web Availability',
    cateringAvailability: 'Catering Availability',
    addRecommendation: 'Add Recommendation',
    price: 'Price',
    priceLowToHigh: 'Low to high',
    priceHighToLow: 'High to low',
    visible: 'Visible',
    hide: 'Hide',
    fontColor: 'Font Color',
    styleOption: 'Style Options',
    borderColor: 'Border Color',
    backgroundColor: 'Background Color',
    style: 'Styles',
    loyaltyOptions: 'Loyalty Options',
    openingHours: 'Opening Hours',
    manufacturer: 'Manufacturer',
    helloTess: {
      hellotessApiBaseUrl: 'helloTESS! api url',
      hellotessApiKey: 'helloTESS! api key',
      success: 'Foodcard was imported',
    },
    gastrofix: {
      gastrofixBusinessUnit: 'Business Unit',
      gastrofixApiKey: 'Gastrofix api key',
      gastrofixImportLanguage: 'Foodcard Language',
      success: 'Foodcard was imported',
    },
    simphony: {
      orgShortName: 'Organisation Identifier/Short Name',
      locRef: 'Location Identifier',
      rvcRef: 'Revenue Center Identifier',
      serviceUrl: 'Service URL',
      importLanguage: 'Foodcard Language',
      success: 'Foodcard was imported',
    },
    deliverect: {
      account: 'Deliverect Customer Account ID',
      menu: 'Menu ID',
      importLanguage: 'Foodcard Language',
      success: 'Foodcard was imported',
    },
    generalImport: {
      checkoutIntegration: 'Foodcard',
    },
    tabs: {
      general: 'General',
      images: 'Images',
      meta: 'Meta',
      options: 'Options',
      prices: 'Prices',
      displayPrices: 'Display Prices',
      availableHours: 'Available Hours',
      availability: 'Availability',
      allergens_and_additives: 'Allergens and Additives',
      nutritions: 'Nutritions',
      features: 'Features',
      style: 'Styles',
      wolt: 'Wolt',
      uberEats: 'Uber-Eats',
      jet: 'Jet',
      visibility: 'Visibility',
    },
    table: {
      name: 'Name',
      stock: 'Stock',
      price: 'Price',
      active: 'Active',
      assets: 'Image',
      internalNote: 'Internal Note',
      internalName: 'Internal Name',
      isControlArticle: 'Control Article',
      icon: 'Icon',
      banner: 'Banner',
      description: 'Description',
      number: 'Number',
      sortOrder: 'Sort',
      gtin: 'GTIN',
      visible: 'Visible',
      rowsPerPage: 'Rows Per Page',
      externalId: 'External Id',
      preorderDeliveryPrice: 'Preorder Delivery Price',
      preorderTakeAwayPrice: 'Preorder TakeAway Price',
      terminalTakeAwayPrice: 'Terminal TakeAway Price',
      terminalInsidePrice: 'Termina Inside Price',
      tags: 'Tags',
      taxTakeaway: 'Tax Takeaway',
      taxInside: 'Tax Inside',
      uber: 'Uber-Eats',
      wolt: 'Wolt',
      jet: 'Jet',
    },
    copyDialog: {
      sourceCategory: 'Source Category',
      targetCategory: 'Target Category',
      articles: 'Articles',
      venue: 'Select Venue',
      success: 'Article(s) was copied',
    },
    form: {
      name: 'Name',
      internalName: 'Internal Name',
      description: 'Description',
      price: 'Price',
      prices: 'Prices',
      type: 'Type',
      value: 'Value',
      emailDomain: 'E-Mail domain',
      dndCompany: 'DND Company',
      min: 'Min.',
      max: 'Max.',
      standardPrice: 'Standard Price',
      insidePrice: 'Inside Price',
      deliveryPrice: 'Delivery Price',
      takeAwayPrice: 'Take Away Price',
      replacesMainArticle: 'Replaces Main Article',
      gtin: 'GTIN (Global Trade Identifier Number)',
      externalId: 'External ID',
      internalNote: 'Internal Note',
      preparationTime: 'Preparation Time (in minutes)',
      plu: 'PLU',
      standardAvailability: 'Available',
      insideAvailability: 'Available inside',
      takeAwayAvailability: 'Available for take away',
      deliveryAvailability: 'Available for delivery',
      webAvailability: 'Available',
      stock: 'Stock',
      image: 'Image',
      banner: 'Banner',
      icon: 'Icon',
      active: 'Active',
      tags: 'Tags',
      group: 'Optiongroup',
      mainRecommendations: 'Main Recommendations',
      priceIsLocked: 'Price Locked (all)',
      isNoticeable: 'Noticeable',
      isControlArticle: 'Control Article',
      shortDescription: 'Short Description',
      articles: 'Articles',
      article: 'Article',
      visible: 'Visible',
      printerSort: 'Printer Sort',
      quantity: 'Quantity',
      constrains: 'Tradegroups',
      printGroups: 'Printgroups',
      unit: 'Unit',
      amount: 'Amount',
      rateable: 'Rateable',
      taxInside: 'Tax Inside',
      taxTakeaway: 'Tax Takeaway',
      takeaway: 'Takeaway',
      delivery: 'Delivery',
      inside: 'Inside',
      displayPrice: 'Display Price',
      isPrintable: 'Printable',
      isRateable: 'Rateable',
      info: 'Info',
      sort: 'Sort',
      mappedArticle: 'Mapped Article',
      code: 'Code',
      category: 'Category',
      number: 'Number',
      deposit: 'Deposit',
      fsk: 'FSK',
      alcoholPercentage: 'Alkohol (in Percentage)',
      caffeineMg: 'Caffeine (mg)',
      main: 'Main Article',
      kcal: 'Kcal',
      options: 'Options',
      availableDateStart: 'Available Date Start',
      availableDateEnd: 'Available Date End',
      maxItemsPerCart: 'Max. Items per Cart',
      articleRelativeBrand: 'Brand',
      loyaltyId: 'Loyalty Id',
      isPiggyLoyalty: 'Is Piggy Loyalty',
      redeemableAtCheckoutOnly: 'Redeemable',
      piggyPointsNeeded: 'Piggy Points Needed',
      piggyRewardUuid: 'Piggy Reward Uuid',
      requiredLoyaltyPoints: 'Required loyalty points for redemption',
      address: 'Address',
      country: 'Country',
      visibility: 'Visibility',
      company: 'Company',
      priceLockByType: {
        standard: 'Standard',
        terminal: 'Terminal',
        preorder: 'Preorder',
        inside: 'Price locked for inside',
        takeAway: 'Price locked for take away',
        delivery: 'Price locked for delivery',
      },
      allergenes: {
        allergenes: 'Allergenes',
        cerealsWithGluten: 'Cereals Containing Gluten',
        crustaceans: 'Crustaceans and products thereof',
        eggs: 'Eggs and products thereof',
        fish: 'Fish and products thereof',
        peanuts: 'Peanuts and products thereof',
        soya: 'Soya (beans) and products thereof',
        milk: 'Milk and products thereof',
        nuts: 'Nuts',
        celery: 'Celery and products thereof',
        mustard: 'Mustard and products thereof',
        sesame: 'Sesame seeds and products thereof',
        sulfur: 'Sulfur dioxide and sulphites',
        lupins: 'Lupins and products thereof',
        molluscs: 'Molluscs and products thereof',
        corn: 'Corn and products thereof',
        wheat: 'Wheat and products thereof',
        rye: 'Rye and products thereof',
        barley: 'Barley and products thereof',
        oats: 'Oats and products thereof',
        spelt: 'Spelt and products thereof',
        khorasan: 'Khorasan and products thereof',
        walnuts: 'Walnuts and products thereof',
        pecanNuts: 'Pecan nuts and products thereof',
        brazilNuts: 'Brazil nuts and products thereof',
        pistachioNuts: 'Pistachio nuts and products thereof',
        macadamiaNuts: 'Macadamia nuts and products thereof',
        almonds: 'Almonds and products thereof',
        hazelnuts: 'Hazelnuts and products thereof',
        cashews: 'Cashews and products thereof',
        seeds: 'Seeds and products thereof',
        poppySeeds: 'Poppy seeds and products thereof',
        sunflowerSeeds: 'Sunflower seeds and products thereof',
        poultryMeat: 'Poultry meat and products thereof',
        beef: 'Beef and products thereof',
        pigmeat: 'Pigmeat and products thereof',
        yeast: 'Yeast extract',
      },
      additives: {
        additives: 'Additives',
        1: 'with dye',
        2: 'with preservative',
        3: 'with antioxidant',
        4: 'with flavor enhancer',
        5: 'sulfured',
        6: 'blackened',
        7: 'with phosphate',
        8: 'with milk protein (for meat products)',
        9: 'caffeinated',
        10: 'quinine',
        11: 'with sweetener',
        12: 'waxed',
        13: 'new',
        14: 'contains Alcohol',
      },
      nutrition: {
        portionInG: 'Serving size (g)',
        portionInML: 'Serving size (ml)',
        energyKcalPerPortion: 'Energy (kcal)',
        energyKcalPer100G: 'Energy (kcal/100g)',
        energyKcalPer100ML: 'Energy (kcal/100ml)',
        energyKjPerPortion: 'Energy (kj)',
        energyKjPer100G: 'Energy (kj/100g)',
        energyKjPer100ML: 'Energy (kj/100ml)',
        fatPerPortion: 'Fat (g)',
        fatPer100G: 'Fat (g/100g)',
        fatPer100ML: 'Fat (g/100ml)',
        saturatesPerPortion: 'Saturates (g)',
        saturatesPer100G: 'Saturates (g/100g)',
        saturatesPer100ML: 'Saturates (g/100ml)',
        carbohydratePerPortion: 'Carbohydrate (g)',
        carbohydratePer100G: 'Carbohydrate (g/100g)',
        carbohydratePer100ML: 'Carbohydrate (g/100ml)',
        sugarPerPortion: 'Sugar (g)',
        sugarPer100G: 'Sugar (g/100g)',
        sugarPer100ML: 'Sugar (g/100ml)',
        proteinPerPortion: 'Protein (g)',
        proteinPer100G: 'Protein (g/100g)',
        proteinPer100ML: 'Protein (g/100ml)',
        saltPerPortion: 'Salt (g)',
        saltPer100G: 'Salt (g/100g)',
        saltPer100ML: 'Salt (g/100ml)',
      },
      features: {
        basic: 'Basic',
        features: 'Features',
        vegan: 'Vegan',
        vegetarian: 'Vegetarian',
        glutenFree: 'Gluten free',
        noCarb: 'Carbohydrate-free',
        alkaline: 'Alkaline',
        lactoseFree: 'Lactose free',
        new: 'New',
        special: 'Special',
        topSelling: 'Top seller',
        warm: 'Warm',
        cold: 'Cold',
        fish: 'Fish',
        chicken: 'Chicken',
        beef: 'Beef',
        pork: 'Pork',
        prepackaged: 'Prepackaged',
      },
    },
  },
  articleManager: {
    index: 'Articles & Options Visibility',
    overview: 'Overview',
    mainArticle: 'Main Articles',
    optionArticle: 'Option Articles',
    options: 'Options',
    deactivateAll: 'Deactivate All',
    activateAll: 'Activate All',
    onlyOne: 'No, only this one',
    filter: {
      category: 'Choose Category',
      status: 'Choose Status of Article',
      type: 'Article or Option Article',
      search: 'Search',
      allStatus: 'All',
      active: 'Visible',
      notActive: 'Invisible',
      article: 'Article',
      options: 'Options',
    },
    table: {
      visibility: 'Visibility',
      name: 'Name',
      category: 'Category',
      options: 'Options',
      price: 'Price',
      optionGroup: 'Option Group',
      connectedArticles: 'Connected articles',
      description: 'Description',
      loadMore: 'Load more',
    },
    notification: {
      saved: 'Changes saved.',
      sameArticleNumber:
        'We found articles with the same article number. Would you like to activate/deactivate (depending on the action) all of them, or only this one?',
    },
  },
  translation: {
    start: 'Translation started. This can take up to 10 minutes',
    index: 'Translation',
    overview: 'Overview',
    translate: 'translate',
    languages: {
      en: 'English',
      de: 'German',
      fr: 'French',
      nl: 'Dutch',
      it: 'Italian',
      pl: 'Polish',
      es: 'Spanish',
      zh: 'Chinese',
      ru: 'Russian',
      tr: 'Turkish',
      da: 'Danish',
      ja: 'Japanese',
      is: 'Icelandic',
      pt: 'Portuguese',
      cz: 'Czech',
    },
  },
  payment: {
    index: 'Payments',
    overview: 'Overview',
    refundArticle: 'Refund Article',
    refundArticleSmoothr: 'Refund Article Smoothr Only',
    refundOrder: 'Refund Order',
    refundOrderSmoothr: 'Refund Order Smoothr Only',
    refundQuestion: 'Are you sure you want to refund? This action cannot be undone.',
    refunded: 'Is part of a refund',
    order: 'Order',
    refund: 'Refunds available',
    searchCode: 'Search by order code...',
    methods: {
      credit_card: 'Credit Card',
      sepa: 'SEPA',
      sofortbanking: 'Sofort Banking',
      paypal: 'PayPal',
      google_pay: 'Google Pay',
      apple_pay: 'Apple Pay',
    },
    table: {
      sum: 'Sum',
      status: 'Status',
      hasRefunds: 'Refunds',
      codes: 'Codes',
      method: 'Payment Method',
      createdAt: 'Date',
      id: 'Id',
    },
    form: {
      quantity: 'Quantity',
    },
  },
  option: {
    index: 'Manage Options',
    create: 'Create a new Option',
    edit: 'Edit Option',
    add: 'Add Option',
    copy: 'Copy Option',
    unlinkedOptions: 'Options not attached to articles',
    empty:
      "It looks like you haven't added any options yet. Create your first option group to make even better and customizable articles.",
    overview: 'Overview',
    tabs: {
      general: 'General',
      articles: 'Articles',
      features: 'Features',
      availableHours: 'Available Hours',
      compound: {
        features: 'Features',
        new: 'New',
        special: 'Special',
        topSelling: 'Top seller',
      },
    },
    articles: {
      table: {
        name: 'Name',
        description: 'Description',
        price: 'Price',
        sortOrder: 'Sort',
        internalNote: 'Internal Note',
        internalName: 'Internal Name',
        assets: 'Images',
        active: 'Active',
        visible: 'Visible',
        number: 'Number',
      },
      addTitle: 'Add article',
      editTitle: 'Edit article',
    },
    table: {
      name: 'Name',
      description: 'Description',
      requiredAmount: 'Required Amount',
      limit: 'Limit',
      hasMultiple: 'Has Multiple',
      sortOrder: 'Sort',
      visible: 'Visible',
      article: 'Article',
    },
    form: {
      name: 'Name',
      description: 'Description',
      shortDescription: 'Short Description',
      image: 'Image',
      externalId: 'External ID',
      limit: 'Limit',
      active: 'Active',
      visible: 'Visible',
      virtual: 'Virtual',
      sort: 'Sort',
      printerSort: 'Printer Sort',
      requiredAmount: 'Required Amount',
      hasMultiple: 'Multiple',
      displayIdentifiers: 'Display Identifiers',
      displayMode: 'Display Mode',
      displayModeSot: 'Display Mode SOT',
      displayModeMobile: 'Display Mode Mobile',
      tags: 'Tags',
      printGroups: 'Print Groups',
    },
    deleteHeader: 'Delete Options',
    deleteInfo: 'Are you sure you want to delete these options? This action cannot be undone.',
    deleteSelected: 'Delete Selected',
  },
  category: {
    index: 'Manage Categories',
    create: 'Create a new Category',
    edit: 'Edit Category',
    add: 'Add Category',
    overview: 'Overview',
    availableHours: 'Available Hours',
    table: {
      name: 'Name',
      sort: 'Sort',
      mainCategory: 'Main Category',
      visible: 'Visible',
    },
    form: {
      name: 'Name',
      description: 'Description',
      mainCategory: 'Main Category',
      sort: 'Sort',
      displayMode: 'Display Mode',
      mainRecommendations: 'Main Recommendations',
      displayIdentifiers: 'Display Identifiers',
      visible: 'Visible',
      image: 'Image',
      icon: 'Icon',
    },
    tabs: {
      features: 'Features',
      new: 'New',
      special: 'Special',
      topSelling: 'Top seller',
    },
  },
  subscriptionCategories: {
    index: 'Manage Subscription Category',
    create: 'Create a new Subscription Category',
    edit: 'Edit Subscription Category',
    add: 'Add Subscription Category',
    overview: 'Overview',
    table: {
      name: 'Name',
    },
    form: {
      name: 'Name',
      description: 'Description',
      image: 'Image',
      subscriptions: 'Subscriptions',
      venues: 'Venues',
      canCombine: 'Combine',
      isActive: 'Active',
    },
  },
  subscription: {
    index: 'Manage Subscription',
    create: 'Create a new Subscription',
    edit: 'Edit Subscription',
    add: 'Add Subscription',
    overview: 'Overview',
    table: {
      name: 'Name',
    },
    form: {
      name: 'Name',
      description: 'Description',
      image: 'Image',
      useMasterFoodcard: 'Use Master Foodcard',
      maxPricePerOrder: 'Max Price Per Order',
      maxPricePerArticle: 'Max Price Per Article',
      maxArticlesPerOrder: 'Max Articles Per Order',
      maxOrdersPerDay: 'Max Orders Per Day',
      minimumOrderOffset: 'Minimum Order Offset',
      isActive: 'Active',
      automaticallyRenew: 'Automatically Renew',
      tax: 'Tax',
      articles: 'Articles',
      venues: 'Venues',
      linkedCustomerGroup: 'Linked Customer Group',
      price: 'Price',
      factor: 'Factor',
      unit: 'Unit',
      default: 'Default',
    },
  },
  membership: {
    index: 'Manage Membership',
    create: 'Create a new Membership',
    edit: 'Edit Membership',
    add: 'Add Membership',
    overview: 'Overview',
    table: {
      name: 'Name',
    },
    form: {
      name: 'Name',
      description: 'Description',
      image: 'Image',
      multiplier: 'Multiplier',
      neededPoints: 'Needed Points',
      piggyId: 'Piggy Id',
      customerGroup: 'Customer Group',
    },
    tier: {
      membershiptier1: 'membershiptier1',
      membershiptier2: 'membershiptier2',
      membershiptier3: 'membershiptier3',
      membershiptier4: 'membershiptier4',
      membershiptier5: 'membershiptier5',
    },
  },
  table: {
    index: 'Manage Tables',
    create: 'Create a new Table',
    exportQrs: 'Export QR-Codes',
    edit: 'Edit Table',
    add: 'Add Table',
    overview: 'Overview',
    table: {
      number: 'Number',
      description: 'Description',
      active: 'Active',
      tags: 'Tags',
      qr: 'QR-Code',
      sanifairDeviceId: 'Sanifair Device-ID',
    },
    form: {
      number: 'Number',
      description: 'Description',
      sanifairDeviceId: 'Sanifair Device-ID',
    },
  },
  venue: {
    index: 'Manage Venues',
    create: 'Create a new Venue',
    copyFoodcard: 'Copy foodcard',
    editMasterId: 'Edit Master-ID',
    copyArticles: 'Copy articles',
    importHellotess: 'Import foodcard from Hellotess',
    importGastrofix: 'Import foodcard from Gastrofix',
    importDeliverect: 'Import foodcard from Deliverect',
    importSimphony: 'Import foodcard from Simphony',
    generalImport: 'General Foodcard Import',
    panic: 'Panic',
    ppConnected: 'PayPal Account Status',
    ppConnectedSuccess: 'PayPal account successfully connected.',
    ppConnectedError: 'PayPal account is not connected or failed.',
    lightspeedAuthorize: 'Authorize Lightspeed',
    lightspeedAuthorized: 'Lightspeed successfully authorized',
    lightspeedNotAuthorized: 'Lightspeed is not authorized',
    generateSlugAuto: 'Generate slug automatically',
    standardSlots: 'Standard Slots',
    terminalSlots: 'Terminal Slots',
    preorderSlots: 'Preorder Slots',
    createSimple: 'Create a new Venue',
    overview: 'Overview',
    add: 'Add Venue',
    sync: 'Sync Venue',
    tooltipCannotSync: 'Sync is already in progress. Please wait or contact an admin',
    liftSyncLock: 'Lift sync lock',
    copy: 'Copy venue',
    copyVenueOnly: 'Copy venue only',
    deleteFoodcard: 'Delete Foodcard',
    copyConfirm: 'Are you sure you want to create a duplicate of venue?',
    copySuccess: 'Venue was copied',
    archive: 'Archive',
    testOrder: 'Send Test Order',
    registerPayPal: 'Connect PayPal Account',
    checkPayPalOnboarding: 'Check PayPal Account Status',
    deliveryFees: 'Delivery Fees',
    deliveryFeesRadius: 'Delivery Fees by Radius',
    deliveryFeesPostalCode: 'Delivery Fees by Postal Code',
    edit: 'Edit Venue',
    parkCollect: 'Park & Collect',
    migrate: 'Migrate Venue',
    stations: 'Stations',
    migration: 'Migration',
    eodReport: 'EOD report',
    customerSupportMail: 'Customer support',
    generateWebAppSlug: 'Generate Web App Slug',
    panicInfo: 'Information : Panic for the Venue {venue} is activated until {panicEndAt} O´clock.',
    snoozing: 'Snoozing Options',
    notification: {
      testOrderSuccess: 'The test order was successful.',
      testOrderError:
        'The test order failed. Please check your settings or if you still have issues contact an administrator.',
      sendInvoiceSuccess: 'Invoice was successfully sent.',
      createCreditNoteSuccess: 'Credit note was successfully created.',
      registerPayPalError: 'Could not authorise paypal.',
      checkPayPalOnboardingError: 'Could not authorise check paypay onboarding status.',
      checkPayPalOnboardingSuccess: 'Please refresh page, to see changes.',
      sendInvoiceError: 'Sending invoice failed.',
      createCreditNoteError: 'Creating credit note failed.',
      successfulCopy: 'Option was copied',
      authorizePayPal: 'To authorize, please fill in the fields Merchant ID and Email in PayPal Credentials and save.',
      copyRunning: 'Venue copying started and is in progress.',
      copyError: 'Copy venue failed. Please check the logs.',
      webAppSlug:
        'Are you sure you want to generate the Web App Slug field? In this case it will be done for all venue.',
      deleteFoodcard: 'Are you sure you want to delete the foodcard?',
      masterId: 'Master-ID',
      deleteSuccess: 'Foodcard successfully deleted!',
      sepa: {
        createSuccess: 'New SEPA Mandate Registration Link was created successfully',
        createError: 'Could not create new SEPA Mandate Registration Link',
        paySuccess: 'New SEPA Payment was created successfully',
        payError: 'Could not create new SEPA Payment',
      },
    },
    filter: {
      publishedStatus: 'Published Status',
      published: 'Published',
      unpublished: 'Not Published',
      verifiedStatus: 'Verified Status',
      syncStatus: 'Sync Status',
      idle: 'Not Synchronizing',
      sync: 'Synchronizing',
      verified: 'Verified',
      customerGroup: 'Customer Group',
      unverified: 'Not Verified',
      deletedAt: 'View Archived',
    },
    station: {
      placeholder: 'Type station name',
      deleteStation: 'Are you sure you want to delete the station?',
      addStation: '+ Add station',
    },
    confirmation: {
      verify: {
        title: 'Venue Confirmation',
        text: 'By clicking the button below, you agree that all articles, prices and venue settings are correct. Furthermore you have uploaded tos, privacy and the disclaimer. For any incorrect settings, you are liable for them.',
        action: 'Verify Venue',
      },
      publish: {
        title: 'Publish Confirmation',
        text: 'By clicking the button below, you agree that all articles, prices and venue settings are correct. For any incorrect settings, you are liable for them.',
        action: 'Publish Venue',
      },
    },
    verify: 'Verify',
    publish: 'Publish',
    credentials: 'Credentials',
    payment: 'Payment',
    order: 'Order',
    preorder: 'Preorder',
    slotting: 'Slot Settings',
    documents: 'Documents',
    invoices: {
      createCreditNote: 'Create Credit Note',
      createCreditNoteNet: 'Create Credit Note (Net Value)',
      send: 'Send Invoice',
      transactions: 'Transactions',
      balance: 'Balance',
      type: 'Invoice Type',
      refOrNote: 'Invoice Ref / Note',
      createdAt: 'Created At',
      amount: 'Amount',
      setupFee99: 'Setup Fee (99€)',
      setupFee49: 'Setup Fee (49€)',
      premiumPrinterPackage: 'Premium Printer Package',
      printerTablet: 'Tablet with integrated printer (319,00 + 8,00 €)',
      printerTablet200: 'Tablet with integrated printer (200€ + 8€)',
      printerTablet230: 'Tablet with integrated printer (230€ + 8€)',
      provision: 'Provision',
      creditNoteNet: 'Credit Note (Net Value)',
      status: 'Status',
    },
    productCombos: {
      addCombo: 'Add Combo',
      addArticle: 'Add Article',
      addColumn: 'Add Column',
      deleteColumn: 'Delete Column',
      result: 'Select result article',
      column: 'Select article',
    },
    ci: 'Checkout Integration',
    cip: 'Checkout Integration Properties',
    general: 'General',
    details: 'Details',
    contact: 'Contact',
    whiteLabel: 'whiteLabel',
    invoiceReceiver: 'Mailing Address',
    address: 'Address',
    settings: 'Settings',
    sizeSettings: 'Size Settings',
    orderOffsets: 'Order Offsets',
    preorderOffsets: 'Preorder Offsets',
    uberDelivery: 'Uber Delivery',
    miscellaneous: 'Miscellaneous',
    preorderPayment: 'Preorder Payment',
    deliveryPayment: 'Preorder Delivery Payment',
    sepaCredentials: 'SEPA Credentials',
    ccCredentials: 'CreditCard Credentials',
    ppCredentials: 'PayPal Credentials',
    sbCredentials: 'SofortBanking Credentials',
    apCredentials: 'ApplePay Credentials',
    gpCredentials: 'GooglePay Credentials',
    walleeCredentials: 'Wallee Credentials',
    worldlineCredentials: 'Worldline Credentials',
    walleePaymentConfs: 'Wallee Payment',
    openingHours: 'Opening Hours',
    specialOpeningHours: 'Special Opening Hours',
    deliveryHours: 'Delivery Hours',
    salesforce: 'Salesforce',
    sanifair: 'Sanifair',
    delfi: 'Delfi',
    loyalty: 'Loyalty',
    boardingPass: 'Boarding Pass',
    datev: 'Datev',
    storeLink: 'Store link',
    locTypes: {
      bar: 'Bar',
      club: 'Club',
      restaurant: 'Restaurant',
      db: 'Deutsche Bahn',
      cinema: 'Cinema',
      other: 'Other',
    },
    tabs: {
      general: 'General',
      payment: 'Payment',
      terminal: 'Terminal',
      preorder: 'Preorder',
      ci: 'CheckoutIntegration',
      orderOffsets: 'Order Offsets',
      order: 'Order',
      slotting: 'Slotting',
      cateringHours: 'Catering Hours',
      documents: 'Documents',
      openingHours: 'Opening Hours',
      productCombos: 'Product Combos',
      deliveryHours: 'Delivery Hours',
      deliveryProviders: 'Delivery Providers',
      externalDeliveryService: 'External Delivery Service',
      orderProviders: 'Order Providers',
      invoices: 'Invoices',
      sanifair: 'Sanifair',
      salesforce: 'Salesforce',
      delfi: 'Delfi',
      loyalty: 'Loyalty',
      boardingPass: 'Boarding Pass',
      datev: 'Datev',
    },
    table: {
      name: 'Name',
      shortId: 'Short-ID',
      email: 'E-Mail',
      street: 'Street',
      number: 'Number',
      city: 'City',
      legalForm: 'Legal Form',
      locType: 'Type',
      vatNumber: 'VAT-ID',
      vatNumberGermany: 'German Tax Number',
      isServiceActivated: 'Service Activated',
      customerGroup: 'Customer Group',
      checkoutIntegration: 'Checkout Integration',
      piggyLoyaltyEnabled: 'Loyalty',
      isPublished: 'Published',
      webAppSlug: 'Web App Slug',
      masterId: 'Master-ID',
    },
    form: {
      secondaryCodeEnabled: 'Secondary Code Enabled',
      standardOrderOffsets: 'Standard Order Offsets',
      terminalInsideOffsets: 'Terminal Inside Offsets',
      terminalTakeAwayOffsets: 'Terminal Take Away Offsets',
      preorderInsideOffsets: 'Preorder Inside Offsets',
      preorderTakeAwayOffsets: 'Preorder Take Away Offsets',
      preorderDeliveryOffsets: 'Preorder Delivery Offsets',
      cateringTakeAway: 'Allow Catering Take Away',
      cateringDelivery: 'Allow Catering Delivery',
      cateringTakeAwayHours: 'Catering Take Away Hours',
      cateringDeliveryHours: 'Catering Delivery Hours',
      cateringDeliveryOffsets: 'Catering Delivery Offsets',
      cateringTakeAwayOffsets: 'Catering Take Away Offsets',
      couponsEnabled: 'Couponing',
      cateringSlots: 'Catering Slots',
      parkCollectionLocationText: 'Location Text',
      venue: 'Venue',
      masterId: 'Master-ID',
      sourceVenue: 'Source-Venue',
      targetVenue: 'Target-Venue',
      sendEodReport: 'Send EOD report to emails',
      sendEodReportEmails: 'List of emails to send reports to',
      source: 'Source',
      image: 'Image',
      masterType: 'Order Type',
      uberCustomerId: 'Uber Customer Id',
      uberDeliveryEnabled: 'Uber Delivery Enabled',
      externalDeliveryService: {
        name: 'Name',
        link: 'Link',
        icon: 'Icon',
      },
      name: 'Name',
      newName: 'New Name',
      asSlave: 'Create the venue as slave of the selected venue',
      sapNumber: 'SAP Number',
      externalLocationId: 'External Location Id',
      restaurantToken: 'Smoothr Restaurant Token',
      standardSlot: 'Max. Standard Orders per interval',
      takeAwaySlot: 'Max. TakeAway Orders per interval',
      calculateDays: 'Count of days to calculate slots',
      insideSlot: 'Max. Inside Orders per interval',
      deliverySlot: 'Max. Delivery Orders per interval',
      properties: 'Properties',
      syncType: 'Sync Type',
      fullName: 'Company Name',
      currency: 'Currency',
      deliveryPostalCodes: 'Delivery Postal Codes',
      deliveryPostalCodesHint: 'Please insert a postal code with a correlating city (e.g. 50679 Cologne)',
      country: 'Country',
      locType: 'Location Type',
      deliveryFee: 'Delivery fee in {currency} (if not further specified)',
      deliveryByRadius: 'Use Delivery Radius (Otherwise by Postal Codes)',
      provisionRate: 'Provision Rate',
      bcsig: 'BCSIG',
      supplier: 'Supplier',
      webAppUrl: 'WebApp Url',
      panicEndAt: 'Panic (if active, preorders are not possible)',
      provisionFixedRate: 'Fixed Provision Rate',
      readableId: 'Short ID',
      migrationType: 'Migration Type',
      deliveryRadius: 'Delivery Radius',
      deliveryRadiusMax: 'Delivery Radius (max)',
      isPublished: 'Published',
      venues: 'Venues',
      deliveryHours: 'Delivery Hours',
      sanifair: 'Sanifair Settings',
      active: 'Active',
      virtualOrders: 'Allow Virtual Orders',
      stock: 'Stock',
      movDelivery: 'Minimum Order Value -- Delivery (EUR)',
      checkoutIntegration: 'Checkout Integration',
      printerIntegration: 'Printer Integration',
      readyOffset: 'Preparation Offset',
      readyOffsetDelivery: 'Preparation Offset Delivery',
      interval: 'Interval Size',
      reserved: 'Reserved Orders',
      max: 'Max Orders In Interval (deprecated)',
      notPickedOffset: 'Not picked offset',
      isServiceActivated: 'Service status (if not activated, ordering is disabled)',
      smoothrDispatchEnabled: 'Smoothr Dispatch',
      isCallingSystemEnabled: 'Calling system',
      inventoryEnabled: 'Inventory Enabled',
      legalForm: 'Legal Form',
      email: 'E-Mail',
      orderEmail: 'Order E-Mail',
      storeEmail: 'Store E-Mail',
      web: 'Website',
      standardOrders: 'Allow Standard Orders',
      preorderTakeAway: 'Allow Preorder Take Away',
      preorderParkCollect: 'Allow Park & Collect',
      preorderFoodspot: 'Allow Foodspot',
      preorderIn: 'Allow Preorder In House',
      preorderDelivery: 'Allow Delivery',
      autoAccept: 'Auto Accept in',
      autoAcceptTerminal: 'Auto Accept Terminal Order in',
      checkoutIn: 'Checkout In',
      printIn: 'Print In',
      autoReset: 'Auto Reset In',
      orderTimeout: 'Order Timeout',
      orderTimeoutEnabled: 'Activate Order Timeout',
      phone: 'Phone',
      fax: 'Fax',
      city: 'City',
      area: 'Nearest City',
      street: 'Street',
      streetAddress: 'Street Address',
      number: 'Number',
      company: 'Company',
      postalCode: 'Postal Code',
      federalState: 'Federal State',
      deliveryAddress: 'Delivery Address',
      orderPrinterTabletPackageInfo:
        'Order a tablet with integrated printer for {price}€. Without a printer you only receive new orders via email.',
      orderPremiumPackage: 'Order Printer',
      timezone: 'Timezone',
      googlePlacesId: 'Google Places ID',
      customerGroup: 'Customer Group',
      sendProvisionReportPeriod: 'Send Provision Report',
      lastInvoiceNumber: 'Last invoice number',
      sendProvisionReportPeriodNonInvoiceable: 'Send Provision Report (without invoice number)',
      vatNumber: 'VAT-ID',
      vatNumberQuestion: 'Use USt-IdNr.?',
      vatNumberGermany: 'German Tax Number (e.g. 12/345/67890)',
      vatNumberGermanyQuestion: 'No USt-IdNr.?',
      mov: 'MOV',
      from: 'From',
      fee: 'Fee',
      secureCode: 'Secure Code',
      selfCertification: 'Self Certification',
      reviewHyperlink: 'Review hyperlink',
      pdfUpload: 'Upload PDF-File',
      insertLink: 'Insert Link',
      nutritionTable: 'Nutrition Table',
      gpsManualInput: 'Manual Input (GPS Coordinates)',
      gpsCorrect: 'GPS Coordinates ok?',
      latitude: 'Latitude',
      longitude: 'Longitude',
      paymentEnabled: 'Payment Enabled',
      sepaEnabled: 'SEPA Enabled',
      sbEnabled: 'SofortBanking Enabled',
      gpEnabled: 'GooglePay Enabled',
      apEnabled: 'ApplePay Enabled',
      ppEnabled: 'PayPal Enabled',
      vrPaymentEnabled: 'VR Enabled',
      rekaEnabled: 'Reka Enabled',
      twintEnabled: 'Twint Enabled',
      walleeEnabled: 'Wallee Enabled',
      tacEnabled: 'Tac Enabled',
      comoLoyaltyEnabled: 'Como Loyalty Enabled',
      alipayEnabled: 'Alipay Enabled',
      wcEnabled: 'WeChat Pay Enabled',
      bcEnabled: 'Boncard Enabled',
      lchEnabled: 'Luncheck Enabled',
      cwlEnabled: 'Worldline Enabled',
      ccvEnabled: 'CCV Enabled',
      zeroPaymentEnabled: 'Zero Payment Enabled',
      woltPaymentEnabled: 'Wolt Payment Enabled',
      uberEatsPaymentEnabled: 'Uber-Eats-Payment Enabled',
      jetPaymentEnabled: 'JET-Payment Enabled',
      ctCreditCardPaymentEnabled: 'Computop CreditCard Enabled',
      bankTransferPaymentEnabled: 'Bank Transfer enabled',
      sapCCOEnabled: 'SAP CCO Enabled',
      worldlineEnabled: 'Worldline Enabled',
      pfEnabled: 'PostFinance E-Finance Enabled',
      pfcEnabled: 'PostFinance Card Enabled',
      ppV2Enabled: 'PayPal V2 Enabled',
      ppFullIntegrationEnabled: 'PayPal Full Integration Enabled',
      ccEnabled: 'CreditCard Enabled',
      posEnabled: 'POS Enabled',
      cashEnabled: 'Cash Enabled',
      secretKey: 'Secret Key',
      merchantName: 'Merchant Name',
      creditorId: 'Creditor Id',
      merchantId: 'Merchant Id',
      merchantIdPP: 'Merchant Id',
      userId: 'User Id',
      spaceId: 'Space Id',
      userKey: 'User Key',
      reka: 'Reka',
      twint: 'Twint',
      boncard: 'Boncard',
      luncheck: 'Luncheck',
      postfinance: 'Postfinance',
      postfinanceCard: 'Postfinance Card',
      wechat: 'Wechat',
      alipay: 'Alipay',
      worldline: 'Worldline',
      ppEmail: 'Account E-Mail',
      ppEmailConfirmed: 'PayPal Email is Confirmed',
      ppEmailNotConfirmed: 'PayPal Email is not Confirmed',
      ppPaymentsReceivable: 'PayPal Payments are receivable',
      ppPaymentsNotReceivable: 'PayPal Payments are not receivable',
      vrAccessToken: 'VR-Payment Access Token',
      articleSpanCount: 'Article Span Count',
      categorySpanCount: 'Category Span Count',
      fullWidthCategories: 'Categories On full Width',
      showInactiveArticles: 'Show Interactive Articles',
      askForTableNumber: 'Ask For Table Number',
      foodAndBeveragesSeparated: 'Food And Beverages Separated',
      toolbarTitleHidden: 'Toolbar Title Hidden',
      displayCirclePickerDescription: 'Display Circle Picker Description',
      toolbarCategoryListVisible: 'Toolbar Category List Visible',
      toolbarSidesVisible: 'Toolbar Sides Visible',
      multiLanguage: 'Multi language',
      printQr: 'Print QR',
      firstOrderOffset: 'Earliest Order Offset',
      orderOffset: 'Minimum Order Offset',
      lastOrderOffset: 'Latest Order Offset',
      dailyUnsnoozeTime: 'Article Offset',
      pickupBoxKey: 'Pickup Box Key',
      pickupBoxDeviceId: 'Pickup Box Device Id',
      pickupBoxAppId: 'Pickup Box App Id',
      pickupBoxCredentials: 'Pickup Box Credentials',
      id: 'Id',
      apiGateway: 'Api Gateway',
      apiKey: 'Api Key',
      apiSecret: 'Api Secret',
      pspId: 'Psp Id',
      host: 'Host',
      sdCredentials: 'Sd Credentials',
      orderOffsetHint:
        'Please insert how many minutes after opening shall pass before accepting the first order, 60 = 1 hour',
      lastOrderOffsetHint:
        'Bitte geben Sie hier an, wie vielen Minuten vor Ladenschließung eine Bestellung eingehen darf, 60 = 1 Std',

      preorderOffset: 'Minimum Preorder Offset',
      preorderOffsetHint:
        'Please insert how many minutes after opening shall pass before accepting the first order, 60 = 1 hour',
      firstPreorderOffset: 'Earliest Preorder Offset',
      lastPreorderOffset: 'Latest Preorder Offset',
      lastPreorderOffsetHint:
        'Bitte geben Sie hier an, wie vielen Minuten vor Ladenschließung eine Bestellung eingehen darf, 60 = 1 Std',
      autoDeclineTime: 'Cancel time',
      uploadedFile: 'Uploaded file',
      terms: 'Terms and Conditions',
      imprint: 'Imprint',
      privacy: 'Privacy',
      registerCourt: 'Register Court',
      registerNumber: 'Register Number',
      chiefExecutives: 'Chief Executive(s)',
      sepaMandateRef: 'SEPA Mandate Reference',
      sepaMandateRegistrationLink: 'SEPA Mandate Registration Link',
      sepaMandateRegistrationError: 'SEPA Mandate Registration Error',
      piggyLoyaltyEnabled: 'Piggy Loyalty Enabled',
      webAppSlug: 'Web App Slug',
      deliveryProviders: {
        orderLordEnabled: 'OrderLord Enabled',
        orderLordActive: 'OrderLord Available (set by administrator)',
        orderLordToken: 'OrderLord API Token',
        orderLordPassword: 'OrderLord API Password',
        rushhourIntegration: 'Rush Hour Integration Enabled',
        rushhourToken: 'Rush Hour Token',
      },
      externalOrderProviders: {
        useExternalProviders: 'Use external order providers',
        orderDirectEnabled: 'OrderDirect Enabled',
        orderDirectImprint: 'OrderDirect Imprint',
        woltEnabled: 'Wolt Enabled',
        connectWolt: 'Get onto Wolt',
        connectUberEats: 'Get onto Uber Eats',
        syncAllFoodcards: 'Sync all Foodcards',
        uberEatsEnabled: 'Uber Eats Enabled',
        jetEnabled: 'JET Enabled',
        venue: 'Venue-ID',
        username: 'Benutzername',
        password: 'Passwort',
        orderApiKey: 'Order-Api-Key',
        apiKey: 'Api-Key',
        syncFoodcard: 'Sync foodcard',
        syncStatus: 'Sync Venue Status',
        syncOpeningHours: 'Sync Opening hours',
        foodcardSyncStarted: 'Menu synchronization has been added to the queue and will be started soon.',
        online: 'The venue has been activated. Orders are now possible',
        offline: 'The venue has been deactivated. Orders are not possible.',
        openingHoursSynched: 'The opening hours have been synchronized.',
        preparationTime: 'Average preparation time',
        deliveryTime: 'Average delivery time',
        autoAccept: 'Automatically accept orders',
        ocuFlowEnabled: 'OCU Flow',
        clientId: 'Client-Id',
        clientSecret: 'Client-Secret',
        checkoutIntegrationDelayingEnabled: 'Delay checkout integration (POS)',
        autoSyncEnabled: 'Sync foodcard automatically',
        ignorePosStatusEvents: 'Ignore POS Events',
      },
      invoiceReceiver: {
        email: 'Alternative invoice receiver email address (otherwise contact email will be used)',
      },
      deliveryFeesAfterCreation: 'Delivery fees can be specified in venue settings after creation.',
      sanifairEnabled: 'Sanifair-Integration',
      sanifairLocationId: 'Sanifair-Location-Id',
      sanifairDeviceId: 'Sanifair-Device-Id',
      addExternalDelivery: 'Add external delivery service',
      deleteExternalDelivery: 'Delete',
      customerSupportMail: {
        email: 'E-Mail',
      },
      sandboxUrl: 'Sandbox Url',
      clientId: 'ClientId',
      clientSecret: 'Client Secret',
      username: 'Username',
      password: 'Password',
      recordType: 'Record Type',
      channelPc: 'Channel Pc',
      enabled: 'Enabled',
      ccvApikey: 'ccvApikey',
      comoApiKey: 'comoApiKey',
      installationId: 'Installation Id',
      locationId: 'Location Id',
      authenticationToken: 'Authentication Token',
      defaultTemplate: 'Default Template',
      syncMenu: 'Sync Menu',
      activateForm: 'Activate The Form',
      boardingPassVerificationEnabled: 'Validate Boarding-Pass',
      paxControlScanningPositionId: 'Pax Control Scanning Position Id',
      costCenter: 'Cost center',
      account: 'Acccount',
      offsetAccount: 'Offset account',
      checkoutIntegrationProps: {
        serviceUrl: 'Service Url',
        orgname: 'Org name',
        orgShortName: 'Org Short Name',
        locRef: 'Loc Ref',
        rvcRef: 'Rvc Ref',
        username: 'Username',
        password: 'Password',
        clientId: 'Client Id',
        authUrl: 'Auth Url',
        defaultEmployee: 'Default Employee',
        defaultOrderType: 'Default OrderType',
        orderTypeInside: 'Order Type Inside',
        orderTypeTakeAway: 'Order Type Take Away',
        orderTypeDelivery: 'Order Type Delivery',
        orderTypeInsideTerminal: 'Order Type Inside Terminal',
        orderTypeTakeAwayTerminal: 'Order Type Take Away Terminal',
        firetimeOffset: 'Firetime Offset',
        averageDeliveryTime: 'Average Delivery Time',
        useExternalId: 'Use External Id',
        useSanifairAsTender: 'Use Sanifair As Tender',
        useTableAsEmployee: 'Use Table As Employee',
        useCodeAsOrderCode: 'Use Code As Order Code',
        useSecondaryCodeAsOrdercode: 'Use Secondary Code As Ordercode',
        firetimeEnabled: 'Firetime Enabled',
        sendOrderNotes: 'Send Order Notes',
        hideTableName: 'Hide Table Name',
        healthcheckEnabled: 'Health Check Enabled',
        multiConventional: 'Multi Conventional',
        cancelTenderType: 'Cancel Order Type',
        cashPaymentCancelTimeout: 'Cash Payment Cancel Timeout',
        useCancelTenderType: 'Use Cancel Order Type',
        excludedTenders: 'Excluded Tenders (from Multi-Conventional)',
        multiConventionalRevenueCenters: 'Multi Conventional Revenue Centers',
        sentBoardingPassEnabled: 'Sent boarding pass',
        recalculatePayment: 'Recalculate Payment',
        replaceCashOrdersByVirtualOrders: 'Replace Cash Orders with Virtual Orders',
        priceSequence: 'Price Sequence',
        orderProfile: 'Order Profile',
        value: 'Value',
        prices: 'Prices',
      },
    },
    legal: {
      useTemplate: 'Use Template',
      termsHtml: '',
      imprintHtml: '',
      privacyHtml: '',
    },
    infoOpeningHours: {
      openingHours: `- You can add opening hours for individual days by clicking on a day and choosing a time slot. To save
      the opening hours, click on the clock icon.<br />
      - By selecting multiple days a week at once, you can add the same opening hours for those days. To
      save the opening hours, click on the clock icon.<br />
      - You can also add multiple opening hours for one day by repeating the steps for setting individual
      opening hours.`,
      specialOpeningHours: `- If you want your restaurant to have special opening hours on a specific day (e.g. holidays), you can add them here. Just choose a date and add the special hours.<br />
      - If you want to close your restaurant on a specific date, you can choose the day and click the Restaurant closed checkbox. The restaurant will be closed the whole day.<br />
      - Otherwise, if you need to close your restaurant for a specific amount of time on a special date, you can choose the date and a time slot, then click the Restaurant closed checkbox. The restaurant will be closed for this specific timeslot.`,
    },
  },
  analytic: {
    from: 'From',
    to: 'To',
  },
  stats: {
    index: 'Manage Statistics',
    indexAnalytics: 'Manage Analytics',
    totalNumberOfOrders: 'Number of Orders',
    avgOrdersPerDay: 'Ø Number of Orders/Day',
    avgAmount: 'Ø Order value',
    totalAmount: 'Total Sum',
    totalSoldArticles: 'Sold Articles',
    highestDaysSale: 'Highest Daily Revenue',
    totalPreCheckouts: 'Confirmed Precheckouts',
    paymentMethod: 'Payment Method',
    avgOrderDuration: 'Ø Ordertime',
    totalTimeouts: 'Number of timeouts',
    monthSales: 'Month sales',
    selectVenue: 'Select venue first',
    customerGroup: 'Customer Group',
    ui: {
      country: 'Country',
      search: 'Search',
      bestSold: 'Best sold',
      worstSold: 'Worst sold',
      ordersView: 'Orders view',
      articleView: 'Article view',
      quantity: 'Quantity',
      orders: 'Orders',
      price: 'Price',
      refresh: 'Refresh',
      debug: 'TESTING',
      prod: 'LIVE',
      customerGroup: 'CustomerGroup',
      flavor: 'Select Flavor(s)',
      status: 'Select Status(es)',
      table: 'Select Type(s)',
      type: 'Select Table(s)',
      label: 'Select Label',
      externalOrderProvider: 'Select Provider',
      searchId: 'Search ID',
      searchCode: 'Search Code',
      number: 'Number',
      copyOrderId: 'Copy order id',
      orderId: 'Order Id',
      note: 'Note',
      today: 'Today',
      yesterday: 'Yesterday',
      monthly: 'Monthly',
      daily: 'Daily',
      showReceipts: 'Show Receipts',
      showLogs: 'Show Logs',
      logs: 'Logs',
      more: 'more',
    },
    table: {
      order: 'Order',
      paymentMethod: 'Payment Method',
      date: 'Date',
      orderDate: 'Order Date',
      orderTime: 'Order Time',
      customer: 'Customer',
      time: 'Time',
      qty: 'Quantity',
      venue: 'Venue',
      itemName: 'Items',
      allCat: 'All Categories',
      cat: 'Categories',
      type: 'Order Type',
      subType: 'Order Subtype',
      status: 'Status',
      table: 'Table',
      price: 'Price/Article',
      total: 'Total',
      name: 'Name',
      note: 'Note',
      secondaryCode: 'Secondary Code',
      dndCompanyCode: 'Company Code',
      retry: 'Retry',
      createdAt: 'Created',
      level: 'Level',
      source: 'Source',
      logs: 'Logs',
      provider: 'Provider',
      loyaltyArticles: 'Loyalty Articles',
      orderedArticles: 'Ordered Articles',
    },
  },
  sales: {
    index: 'Product Sales',
    ui: {
      category: 'Select Category(ies)',
      item: 'Select Item(s)',
      label: 'Select Venue Label',
      dateType: 'Date Type',
      createdAt: 'Order Date',
      orderAt: 'Pickup/Delivery Date',
    },
    table: {
      name: 'Name',
      sales: 'Sales',
      total: 'Total',
      cat: 'Category',
    },
  },
  billing: {
    index: 'Billing',
    billing: 'Export Billing',
    orderCsv: 'Export Orders (The Export will be sent to the email with which you are currently logged in.)',
    paymentMethodCsv:
      'Export Orders by Payment Method (The Export will be sent to the email with which you are currently logged in.)',
    salesCsv: 'Export Sales (The Export will be sent to the email with which you are currently logged in.)',
    salesGroup: 'Export sales group (The Export will be sent to the email with which you are currently logged in.)',
    summary: 'Export Billing Summary',
    plainSummary: 'Export Billing Plain Summary',
    datev: 'Export VR Datev',
    articlesOverview: 'Order type',
    form: {
      venue: 'Venue',
      label: 'Venue label',
      flavor: 'Flavor',
      customerGroup: 'Customer Group',
      withoutTags: 'Don`t filter "empty" tags',
      subscribedToMailing: 'Subscribed to newsletter',
      filterEmptyByMail: 'Filter empty by mail',
      typeExportFile: 'Type of exported file',
      requiredField: 'Please choose one of the fields',
    },
  },
  userExport: {
    index: 'User export',
    userExport: 'Export user',
    userCsv: 'Export users',
    userWithNewsletterCsv: 'Export users with newsletter',
    form: {},
  },
  activityLogExport: {
    index: 'Activity Log Export',
    activityLogCsv: 'Export logs',
    form: {
      type: 'Type',
      venue: 'Venue',
      typeExportFile: 'Type of exported file',
    },
  },
  sepa: {
    index: 'SEPA',
    title: 'SEPA settings',
    mandate: 'Mandate',
    sepaMandate: 'SEPA Mandate',
    venues: 'Venues',
    createSepa: 'Create new SEPA Mandate',
    debit: 'SEPA Debit',
    payLast: 'Pay invoices of last 30 days',
    notification: {
      cancelError: 'Error while cancelling SEPA Mandate',
      cancelWarning:
        'Are you sure to cancel this SEPA Mandate? It will be removed and can not be used for payments anymore.',
      createError: 'Could not create new SEPA Mandate Registration Link',
      createWarning:
        'Are you sure to create a new SEPA Mandate Registration Link? Existing SEPA Mandates for selected venues will be removed and can not be used for payments anymore.',
      payWarning: 'SEPA Mandate exists for this venue. Do you want to create a new SEPA Payment for this transaction?',
      payLastTransactionsWarning:
        'Are you sure to mandate payments for all invoices of the last 30 days for these venues? Afterwards single payments can be cancelled within 2 days. In this case please contact the support immediately.',
      payLastTransactionsSuccess:
        'invoices of the last 30 days paid. You will receive an email for each mandated payment. Please contact the support within 2 days, if you want to cancel one of the payments.',
    },
    form: {
      merchantId: 'Merchant Id',
      venues: 'Venues',
      merchantName: 'Merchant Name',
      payLastTransactionsAfterCreate: 'Pay open invoices of the last 30 days *',
      payLastTransactionsAfterCreateHint:
        'All open invoice amounts of the last 30 days will be charged by our system after creation of SEPA mandate. You will receive an email for each mandated payment in the next hours. Please contact the support within 2 days, if you want to cancel one of these payments.',
      sepaMandateRef: 'SEPA Mandate Reference',
      sepaMandateRegistrationLink: 'SEPA Mandate Registration Link',
      sepaMandateRegistrationError: 'SEPA Mandate Registration Error',
    },
  },
  productCombos: {
    index: 'Product combos',
    overview: 'Overview',
  },
  tips: {
    terminal: 'Terminal',
    webApp: 'WebApp',
    inside: 'Inside',
  },
  promo: {
    index: 'Manage Promo Codes',
    create: 'Create a new Promo Code',
    edit: 'Edit Promo Code',
    exportCsv: 'Export',
    exportSummary: 'Export Summary',
    exportCount: 'Export Activation Summary',
    overviewCsv: 'Export PromoCodes',
    exportTeedeliZip: 'Export Teedeli Promo Codes (*.zip)',
    overview: 'Overview',
    add: 'Add Promo Code',
    addTeedeli: 'Add Teedeli Promo Code',
    addMultiple: 'Add Multiple Promo Codes',
    disableCodes: 'Disable Codes',
    disableHint: 'Please select a venue first',
    notification: {
      disableSuccess: 'Disabled promo codes successfully.',
    },
    types: {
      absolute: 'Absolute Value',
      relative: 'Relative Value',
      bogo: 'BOGO',
      deliveryFee: 'No Delivery Free',
      reward: 'Reward',
      giftCard: 'Gift Card',
      freeArticle: 'Free Article',
      void: 'Void',
      absoluteArticle: 'Article Discount (absolute)',
      relativeArticle: 'Article Discount (relative)',
      mostExpensiveArticleDiscountRelative: 'Most Expansive Article Discount (relative)',
      teedeliPremium: 'Article Discount (premium)',
    },
    table: {
      name: 'Name',
      code: 'Code',
      customerGroup: 'Customer Group',
      mov: 'Minimum Order Value',
      from: 'Valid from',
      to: 'Valid to',
      disabled: 'Disabled (Venue only)',
      venues: 'Venues',
      type: 'Type',
      value: 'Value',
      active: 'Active (Global)',
      disabledBy: 'Disabled by',
      countries: 'Countries',
      states: 'States',
      amount: 'Max. Usage',
    },
    form: {
      offerPeriod: 'Offer Period',
      lightspeedCode: 'Lightspeed Code',
      name: 'Name',
      code: 'Code',
      venue: 'Venue',
      valid: 'Valid',
      article: 'Article',
      generateAmount: 'Amount to generate',
      label: 'Venue Label',
      isMultipleApplicable: 'Customer can use it more than once',
      applyOnAnyQuantity: 'Apply On Any Quantity',
      customerGroup: 'Customer Group',
      countries: 'Countries',
      states: 'States/Regions',
      codes: 'Promo Codes',
      uploadGiftCards: 'CSV file with Gift Cards',
      venues: 'Venues',
      type: 'Type',
      freeArticle: 'Free Article',
      bogoArticles: 'Possible Articles (cheapest product from cart will be used for bogo)',
      mov: 'Minimum Order Value',
      amount: 'Maximum usage',
      amountTeedeli: 'Amount of promo codes',
      amountUsesTeedeli: ' Max. Usage',
      active: 'Active',
      value: 'Value',
      discount: 'Discount',
      percent: 'Value (e.g. 20% please enter 0.2)',
      standardAvailability: 'Available',
      insideAvailability: 'Available inside',
      takeAwayAvailability: 'Available for take away',
      deliveryAvailability: 'Available for delivery',
      preorderParkCollect: 'Available for park & collect',
      preorderFoodspot: 'Available for foodspot',
      webAvailability: 'Available',
    },
    tabs: {
      general: 'General',
      availability: 'Availability',
    },
    standardAvailability: 'Standard Availability',
    terminalAvailability: 'Terminal Availability',
    preorderAvailability: 'Preorder Availability',
    webAvailability: 'Web Availability',
    weekdaysAvailability: 'Weekdays Availability',
    cateringAvailability: 'Catering Availability',
  },
  cupCode: {
    index: 'Manage Cup Code',
    create: 'Create a new Cup Code',
    edit: 'Edit Cup Code',
    overview: 'Overview',
    add: 'Add Cup Code',
    downloadCodes: 'Export Codes',
    downloadQRCode: 'Download QR codes',
    action: 'Actions on a range of codes',
    form: {
      number: 'Number',
      venue: 'Venue',
      active: 'Active',
      quantity: 'Number of codes to be generated',
      startingFrom: 'Starting number of codes',
      customerGroup: 'Select customer groups',
      format: 'Select format file',
    },
    table: {
      number: 'Number',
      venue: 'Venue',
      customerGroup: 'Customer Groups',
      quantity: 'Quantity',
      startingFrom: 'Starting From',
      active: 'Active',
      qrCode: 'QR Code',
      createdAt: 'Created',
    },
    filter: {
      customerGroup: 'Customer Groups',
      active: 'Active',
      numFrom: 'Number From',
      numTo: 'Number To',
    },
  },
  coupon: {
    index: 'Manage Coupon',
    create: 'Create a new Coupon',
    edit: 'Edit Coupon',
    exportCsv: 'Export',
    exportSummary: 'Export Summary',
    exportCount: 'Export Activation Summary',
    overviewCsv: 'Export Coupon',
    overview: 'Overview',
    add: 'Add Coupon',
    addMultiple: 'Add Multiple Coupon',
    disableCodes: 'Disable Coupon',
    disableHint: 'Please select a venue first',
    notification: {
      disableSuccess: 'Disabled coupon successfully.',
    },
    form: {
      offerPeriod: 'Offer Period',
      lightspeedCode: 'Lightspeed Code',
      name: 'Name',
      code: 'Code',
      venue: 'Venue',
      valid: 'Valid',
      article: 'Article',
      generateAmount: 'Amount to generate',
      label: 'Venue Label',
      isMultipleApplicable: 'Customer can use it more than once',
      applyOnAnyQuantity: 'Apply On An yQuantity',
      customerGroup: 'Customer Group',
      countries: 'Countries',
      states: 'States/Regions',
      codes: 'Coupon',
      uploadGiftCards: 'CSV file with Gift Cards',
      venues: 'Venues',
      type: 'Type',
      freeArticle: 'Free Article',
      bogoArticles: 'Possible Articles (cheapest product from cart will be used for bogo)',
      mov: 'Minimum Order Value',
      amount: 'Maximum usage',
      active: 'Active',
      value: 'Value',
      discount: 'Discount',
      percent: 'Value (e.g. 20% please enter 0.2)',
      standardAvailability: 'Available',
      insideAvailability: 'Available inside',
      takeAwayAvailability: 'Available for take away',
      deliveryAvailability: 'Available for delivery',
      preorderParkCollect: 'Available for park & collect',
      preorderFoodspot: 'Available for foodspot',
      webAvailability: 'Available',
      image: 'Image',
      banner: 'Banner',
      usageFrequency: 'Usage frequency',
      redeemableAtCheckoutOnly: 'Redeemable',
    },
    types: {
      absolute: 'Absolute Value',
      relative: 'Relative Value',
      bogo: 'BOGO',
      deliveryFee: 'No Delivery Free',
      reward: 'Reward',
      giftCard: 'Gift Card',
      freeArticle: 'Free Article',
      void: 'Void',
      absoluteArticle: 'Article Discount (absolute)',
      relativeArticle: 'Article Discount (relative)',
      plusOneFree: '1 + 1 Free',
    },
    usageFrequency: {
      any: 'Any',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
    },
    table: {
      name: 'Name',
      code: 'Code',
      customerGroup: 'Customer Group',
      mov: 'Minimum Order Value',
      from: 'Valid from',
      to: 'Valid to',
      disabled: 'Disabled (Venue only)',
      venues: 'Venues',
      type: 'Type',
      value: 'Value',
      active: 'Active (Global)',
      disabledBy: 'Disabled by',
      countries: 'Countries',
      states: 'States',
      amount: 'Max. Usage',
    },
    tabs: {
      general: 'General',
      availability: 'Availability',
      images: 'Images',
    },
    standardAvailability: 'Standard Availability',
    terminalAvailability: 'Terminal Availability',
    preorderAvailability: 'Preorder Availability',
    webAvailability: 'Web Availability',
    weekdaysAvailability: 'Weekdays Availability',
    cateringAvailability: 'Catering Availability',
  },
  receipt: {
    index: 'Receipts',
    overview: 'Overview',
    receipts: 'Receipts',
    endOfDayReceipts: 'End Of Day Receipts',
    form: {
      flavor: 'Order Flavor',
      venue: 'Venue',
    },
    table: {
      code: 'Order Code',
      at: 'At',
      sum: 'Sum',
      endOfDay: 'End of day',
      merchantReceipt: 'Merchant Receipt',
      customerReceipt: 'Customer Receipt',
      posCheckoutReceipt: 'Checkout Receipt',
      exportCustomer: 'Export customer receipt',
      exportMerchant: 'Export merchant receipt',
      exportCombined: 'Export merchant and customer receipt',
      exportFull: 'Export receipts',
      tableNumber: 'Table Number',
    },
  },
  eposJobs: {
    index: 'Jobs',
    overview: 'Overview',
    form: {
      venue: 'Venue',
      status: 'Status',
      requestId: 'Request Id',
      success: 'Success',
      failed: 'Failed',
      partiallyFailed: 'Partially Failed',
      pending: 'Pending',
      started: 'Started',
      sortByCreatedAt: 'Date last created',
      oldestToNewest: 'Oldest To Newest',
      newestToOldest: 'Newest To Oldest',
    },
    table: {
      sapNumber: 'Sap Number',
      status: 'Status',
      statusMessage: 'Status Message',
      createdAt: 'Created At',
      finalizedAt: 'Finalized At',
      exportFull: 'Export receipts',
      requestId: 'Request Id',
      ID: 'Changes ID',
      articleType: 'Article Type',
      itemId: 'Item ID',
      price: 'Price',
      taxInside: 'Tax Inside',
      taxTakeaway: 'Tax Takeaway',
      visibility: 'Visibility',
      oldValue: 'Old Value',
      newValue: 'New Value',
      priceRuleId: 'Price Rule Id',
      effectiveDateTimestamp: 'Effective Date Timestamp',
      expirationDateTimestamp: 'Expiration Date Timestamp',
      priceRuleSet: 'Price Rule Set',
      statusCode: 'Status Code',
      taxIdInside: 'Tax ID Inside',
      taxIdTakeaway: 'Tax ID Takeaway',
      errorList: 'Error list',
      errorId: 'Error ID',
      articleName: 'Article Name',
      negativeFlag: 'Negative Flag',
    },
  },
  sanifairLogs: {
    index: 'Sanifair',
    overview: 'Overview',
    form: {
      venue: 'Venue',
      status: 'Status',
      search: 'Search',
      actionTypes: 'Action Types',
    },
    table: {
      sanifairActionType: 'Action Types',
      order: 'Order',
      responseTimeSanifair: 'Response Time Sanifair',
      responseTimeSmoothr: 'Response Time Smoothr',
      responseTimeTotal: 'Response Time Total',
      createdAt: 'Created At',
      sanifairRequestReceivedAt: 'Sanifair Request Received At',
      sanifairResponseReceivedAt: 'Sanifair Response Received At',
      smoothrRequestReceivedAt: 'Smoothr Request Received At',
      smoothrResponseReceivedAt: 'Smoothr Response Received At',
    },
  },
  automatedReports: {
    index: 'Automated Reports',
    overview: 'Overview',
    add: 'Add Automated Report',
    form: {
      type: 'Type',
      venue: 'Venue',
      customerGroup: 'Customer Group',
      flavor: 'Flavor',
      lang: 'Lang',
      frequency: 'Frequency',
      venueLabels: 'Venue Labels',
      lastExecuted: 'Last Executed',
      receiver: 'Receiver',
      cc: 'CC',
      active: 'Active',
    },
    tabs: {
      general: 'General',
    },
    table: {
      type: 'Type',
      customerGroup: 'Customer Group',
      flavor: 'Flavor',
      frequency: 'Frequency',
      venueLabels: 'Venue Labels',
      lastExecuted: 'Last Executed',
      receiver: 'Receiver',
      selectVenue: 'Please select venue',
      selectGroup: 'Please select customer group',
    },
    types: {
      orders: 'Orders',
      orderByPaymentMethod: 'Order By Payment Method',
      sales: 'Sales',
      pdfBillingShort: 'PDF Billing Short',
      pdfBillingLong: 'PDF Billing Long',
    },
    frequency: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Yearly',
    },
  },
  paypal: {
    index: 'PayPal',
    success: 'PayPal authorization was successfully',
    description:
      'PayPal authorization was successfully. After our employees have checked your account status, paypal payments will be activated.',
  },
  validations: {
    eod_email_enable: 'The list should contain valid emails before enabling this',
    list_of_emails: 'Your email should be separated by a coma.',
    list_of_integer: 'Excluded tenders should be separated by a coma.',
    numbers_with_comma_or_dot: 'The {field} field must be a number.',
    quantity_number: 'The number cannot be greater.',
    quantity_number_less_100k: 'The difference cannot be more than 100 000 and less than 0',
    quantity_number_less_10k: 'The difference cannot be more than 10 000 thousand and less than 0',
    unique_email: 'The email is already in use',
    readable_id: 'The short id is already taken.',
    venue_label: 'The venue label already exists.',
    password:
      'Password must contain one capital letter, one number or special character and be at least 8 characters long.',
    date_before: 'The {field} field must be before {target}',
    time_before: 'The {field} field must be before {target}',
    unique_dependency: 'The {field} is a duplicate',
    smaller_than: 'The {field} must be smaller than {target}.',
    greater_than: 'The {field} must be greater than {target}.',
    greater_eq_than: 'The {field} must be at least {target}.',
    uppercase: 'Only upper-case letters allowed',
    price: 'The {field} must be a valid price (e.g. 1.99)',
    nutritional_value: 'The {field} must be a valid nutritional value (e.g. 1.99)',
    uniqueFile: 'The uploaded media can not be changed. Please create a new step.',
    time: 'The time format is HH:mm.',
    url: 'Please enter a valid domain without http/https, just the domain and the TLD.',
    uniq_company_code: 'This company code is already taken. Please choose a different code.',
    iban: 'Invalid IBAN format. Please enter a correct IBAN.',
    vatNumber: {
      info: 'Valid VAT Number with minimum length of 5 required',
      error:
        'VAT Number is invalid or already taken by other user. Please enter another VAT number or contact an administrator to solve the issue.',
    },
    vatNumberGermany: {
      info: 'Valid German Tax Number with minimum length of 5 required',
      error:
        'German Tax Number is invalid or already taken by other user. Please enter another VAT number or contact an administrator to solve the issue.',
    },
  },
  footer: {
    imprint: 'Imprint',
    terms: 'Data Policies',
  },
  reporting: {
    index: 'Reporting',
    form: {
      type: 'Type',
      periodType: 'Period',
      label: 'Venue Label',
      country: 'Country',
      venue: 'Venue',
      flavor: 'Flavor',
      customerGroup: 'Customer Group',
    },
    periods: {
      daily: 'Day',
      weekly: 'Week',
      monthly: 'Month',
      total: 'Total',
    },
    types: {
      stores: 'Venue',
      products: 'Article',
      customers: 'Customer',
    },
  },
  screensaver: {
    index: 'Screensaver',
    add: 'Create Screensaver',
    mediaType: 'Media type',
    duration: 'Duration',
    level: 'Level',
    preview: 'preview',
    title: 'Screensaver Step',
    hours: 'Hours',
    overview: 'Overview',
    stepSavingInfo: 'Media will upload in a moment, please wait.',
    category: 'Category',
    form: {
      name: 'Name',
      customerGroup: 'Customer Group',
      linkedCustomerGroup: 'Linked Customer Group',
      venues: 'Venues',
      openingHours: 'Opening Hours',
      file: 'Asset',
      duration: 'Duration in seconds',
      level: 'Level',
      saveOrder: 'Save new order',
      changeOrder: 'Change order',
      slug: 'Slug',
      visible: 'Visible',
      category: 'Category',
    },
    tabs: {
      screensaver: 'Screensaver',
      steps: 'Steps',
      info: 'You can add steps only after creating screensaver',
    },
    table: {
      steps: 'Steps Count',
    },
  },
  employeeNotification: {
    index: 'Employee Notification',
    add: 'Create Employee Notification',
    form: {
      title: 'Title',
      message: 'Message',
    },
  },
  loyaltyPrograms: {
    index: 'Loyalty Programs',
    add: 'Create Loyalty Program',
    create: 'Create Loyalty Program',
    edit: 'Edit Loyalty Programs',
    overview: 'Overview',
    general: 'General',
    clientCredentials: 'Client Credentials',
    rbiCredentials: 'Rbi Credentials',
    form: {
      type: 'Type',
      customerGroup: 'Customer Group',
      apikey: 'Api Key',
      oauthId: 'Oauth ID',
      oauthSecret: 'Oauth Secret',
      operator: 'Operator',
      posType: 'Pos Type',
      storeId: 'Store Id',
      supportingPos: 'Supporting Pos',
      piggy: 'Piggy',
      como: 'Como',
      internal: 'Internal',
      rbi: 'Rbi',
      shopUuid: 'Shop Uuid',
    },
    table: {
      type: 'Type',
      customerGroup: 'Customer Group',
      apikey: 'Api Key',
      oauthId: 'Oauth ID',
      oauthSecret: 'Oauth Secret',
      shopUuid: 'Shop Uuid',
      venue: 'Venue',
      operator: 'Operator',
      posType: 'Pos Type',
      storeId: 'Store Id',
      supportingPos: 'Supporting Pos',
    },
  },
  banner: {
    index: 'Manage Banners',
    add: 'Create banner',
    table: {
      name: 'Name',
      type: 'Type',
      linkedCategory: 'Linked Category',
      linkedArticle: 'Linked Article',
    },
    form: {
      name: 'Name',
      type: 'Type',
      linkedCategory: 'Linked Category',
      linkedArticle: 'Linked Article',
      imageDe: 'Image (de)',
      imageEn: 'Image (en)',
      imageFr: 'Image (fr)',
    },
  },
  lightspeed: {
    syncFoodcard: 'Sync Foodcard',
    updateOnly: 'Update only',
    success: 'Lightspeed has been successfully authorized',
    successDescription:
      'Congratulations! Lightspeed has been successfully authorized. Please contact your key account manager to complete the integration. You can close this page now.',
    error: 'Lightspeed could not be authorized',
    errorDescription:
      'Unfortunately, an unknown error occurred while authorizing Lightspeed. Please contact support or your key account manager. You can close this page now.',
    notification: {
      foodcardSyncSuccess: 'Foodcard was successfully synchronized.',
      foodcardSyncError: 'Foodcard could not be synchronized. Please check logs.',
    },
  },
  copy_venue: {
    not_selected_to: 'You must select a destination to copy to',
    success: 'Venue was copied successfully',
    error: 'something went wrong',
    btn_title: 'Copy Settings',
    from: 'From',
    to: 'To',
  },
  sync: {
    index: 'Sync',
    sync: 'Sync',
    config: 'Config',
    created: 'Created',
    updated: 'Updated',
    deleted: 'Deleted',
    collapseAll: 'Collapse All',
    expandAll: 'Expand All',
    directions: {
      d2d: 'Dev-to-Dev',
      d2p: 'Dev-to-Prod',
      p2d: 'Prod-to-Dev',
      p2p: 'Prod-to-Prod',
    },
    notification: {
      running: 'The sync has been started',
      error: 'There was an error. Please check the logs.',
    },
    modes: {
      'master-to-slaves': 'Master -> Slaves',
      'slave-to-slaves': 'Slave -> Slaves',
    },
    deleteModes: {
      ignore: 'No change/No deletion',
      soft: 'Soft-Delete',
      hard: 'Hard-Delete',
    },
    table: {
      id: 'Id',
      name: 'Name',
      user: 'User',
      status: 'Status',
      createdAt: 'Created',
      finishedAt: 'Finished',
      config: 'Config',
      changes: 'Changes',
    },
    form: {
      main: 'Main-Venue (Slave/Master)',
      slaves: 'Slave-Venues',
      direction: 'From/To',
      mode: 'Mode',
      deleteMode: 'Delete mode',
      articleCategoryProps: 'Category-Props',
      articleProps: 'Article-Props',
      venueProps: 'Venue-Props',
      optionGroupProps: 'Optiongroup-Props',
      optionArticleProps: 'Optionarticle-Props',
      selectAll: 'Select All',
    },
    props: {
      articleCategory: {
        sortOrder: 'Sort order',
        visible: 'Visibility',
        displayMode: 'Display Mode',
        displayIdentifiers: 'Display Identifiers',
        mainCategory: 'Main category',
        availableHours: 'Available hours',
        name: 'Name',
        description: 'Description',
        assets: 'Main image',
        icon: 'Icon',
        tags: 'Tags',
        constrains: 'Constrains',
        features: 'Features',
        mainRecommendations: 'Main recommendations',
      },
      article: {
        price: 'Price',
        basePrice: 'Base price',
        taxTakeaway: 'Tax (Takeaway)',
        taxInside: 'Tax (Insde)',
        sortOrder: 'Sort order',
        visible: 'Visibility',
        isActive: 'Active status',
        deposit: 'Deposit',
        isControlArticle: 'Control article flag',
        internalNote: 'Internal note',
        internalName: 'Internal Name',
        externalId: 'External ID',
        number: 'Number',
        priceIsLocked: 'Price-Lock',
        description: 'Description',
        name: 'Name',
        info: 'Info',
        shortDescription: 'Short description',
        assets: 'Main image',
        icon: 'Icon',
        banner: 'Banner',
        measurement: 'Measurement',
        groupDependencies: 'Group Dependencies',
        groups: 'Optionsgruppen',
        defaults: 'Defaults',
        recommendations: 'Recommendations',
        priceByType: 'Price by order type',
        displayPriceByType: 'Visible Price by order type',
        availability: 'Availability',
        tags: 'Tags',
        constrains: 'Constrains',
        counterArticle: 'Counter article',
        mainRecommendations: 'Main recommendations',
        articleRelativeBrand: 'Brand',
        styles: 'Styles',
        additives: 'Additives',
        allergens: 'Allergens',
        features: 'Features',
        externalOrderProviderSettings: 'External Order Provider Settings',
        loyaltyId: 'Loyalty Id',
        requiredLoyaltyPoints: 'Required Loyalty Points',
        plu: 'PLU',
        gtin: 'GTIN',
      },
      venue: {
        movDelivery: 'MOV',
        preorderTakeAway: 'Preorder (Takeaway)',
        preorderIn: 'Preorder (Inside)',
        deliveryEnabled: 'Preorder (Delivery)',
        sendProvisionReportPeriod: 'Provision report',
        isServiceActivated: 'Service-Status',
        offsets: 'Offsets',
        invoiceReceiver: 'Invoice receiver',
        slot: 'Slotting',
        legal: 'Legal',
        openingHours: 'Opening hours',
        deliveryHours: 'Delivery hours',
      },
      optionGroup: {
        hasMultiple: 'Multiple selection',
        limit: 'Limit',
        sortOrder: 'Sort order',
        requiredAmount: 'Required amount',
        displayMode: 'DisplayMode',
        name: 'Name',
        description: 'Description',
        backgroundImage: 'Background image',
        tags: 'Tags',
      },
      optionArticle: {
        price: 'Price',
        basePrice: 'Base price',
        taxTakeaway: 'Tax (Takeaway)',
        taxInside: 'Tax (Insde)',
        sortOrder: 'Sort order',
        visible: 'Visibility',
        isActive: 'Active status',
        deposit: 'Deposit',
        isControlArticle: 'Control article flag',
        internalNote: 'Internal note',
        internalName: 'Internal Name',
        externalId: 'External ID',
        optionNumber: 'Number',
        number: 'Number',
        priceIsLocked: 'Price-Lock',
        description: 'Description',
        name: 'Name',
        info: 'Info',
        shortDescription: 'Short description',
        assets: 'Main image',
        icon: 'Icon',
        banner: 'Banner',
        measurement: 'Measurement',
        groupDependencies: 'Group Dependencies',
        groups: 'Optionsgruppen',
        defaults: 'Defaults',
        recommendations: 'Recommendations',
        priceByType: 'Price by order type',
        displayPriceByType: 'Visible Price by order type',
        availability: 'Availability',
        tags: 'Tags',
        constrains: 'Constrains',
        counterArticle: 'Counter article',
        mainRecommendations: 'Main recommendations',
        requirements: 'Article requirements',
        articleRelativeBrand: 'Brand',
        styles: 'Styles',
        additives: 'Additives',
        allergens: 'Allergens',
        features: 'Features',
        externalOrderProviderSettings: 'External Order Provider Settings',
        loyaltyId: 'Loyalty Id',
        requiredLoyaltyPoints: 'Required Loyalty Points',
        plu: 'PLU',
        gtin: 'GTIN',
      },
    },
  },
  aggregatorSyncs: {
    index: 'Aggregator Syncs',
    wolt: 'Wolt',
    uberEats: 'Uber-Eats',
    jet: 'Jet',
    noWolt: 'No Wolt data available.',
    noJet: 'No Jet data available.',
    noUberEats: 'No Uber-Eats data available.',
    table: {
      name: 'Name',
      user: 'User',
      status: 'Status',
      createdAt: 'Created',
      finishedAt: 'Finished',
    },
    filter: {
      status: 'Select Status',
    },
    status: {
      created: 'Created',
      in_progress: 'In Progress',
      done: 'Done',
      failed: 'Failed',
    },
  },
  orderProviders: {
    wolt: {
      success: {
        title: 'Venue has been successfully connected to Wolt',
        description:
          'Your venue has been successfully connected to Wolt. You can now synchronize your foodcard and receive orders.',
      },
      error: {
        title: 'Venue could not be connected to Wolt',
        description: 'An error has occurred. Please contact our support:',
      },
    },
    uberEats: {
      success: {
        title: 'Venue has been successfully connected to Uber Eats',
        description:
          'Your venue has been successfully connected to Uber Eats. You can now synchronize your foodcard and receive orders.',
      },
      error: {
        title: 'Venue could not be connected to Uber Eats',
        description: 'An error has occurred. Please contact our support:',
      },
    },
  },
  simphony: {
    syncFoodcard: 'Sync prices/visibility',
    notification: {
      foodcardSyncSuccess: 'Prices/visibilities have been successfully synchronized.',
      foodcardSyncError: 'Could not synchronize prices/visibilities. Please check the logs.',
    },
  },
};
