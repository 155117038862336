import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import Screensaver from '@/interfaces/models/Screensaver';
import ScreensaverStep from '@/interfaces/models/ScreensaverStep';

export default class ScreensaverApiService extends ApiService<Screensaver> {
  constructor() {
    super('general/screensaver', 'v1');
  }

  public fetch(filter?: any): AxiosPromise<Screensaver[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    return axios.get(`${this.getBaseUrl()}/search?${query}`);
  }

  public delete(id: string): AxiosPromise<Screensaver[]> {
    return axios.delete(`${this.getBaseUrl()}/${id}`);
  }

  public storeStep(data: { screensaverId: string; step: ScreensaverStep }): AxiosPromise<ScreensaverStep> {
    return axios.post(`${this.getBaseUrl()}/${data.screensaverId}/step`, data.step);
  }

  public updateStep(data: {
    screensaverId: string;
    stepId: string;
    step: ScreensaverStep;
  }): AxiosPromise<ScreensaverStep> {
    return axios.patch(`${this.getBaseUrl()}/${data.screensaverId}/step/${data.stepId}`, data.step);
  }

  public deleteStep(data: { screensaverId: string; stepId: string }): AxiosPromise<ScreensaverStep> {
    return axios.delete(`${this.getBaseUrl()}/${data.screensaverId}/step/${data.stepId}`);
  }

  public uploadStepImage(data: { id: string; stepId: string; image: FormData }) {
    return axios.post(`${this.getBaseUrl()}/${data.id}/step/${data.stepId}/upload`, data.image);
  }

  public updateLevels(data: { screensaverId: string; levels: any }) {
    return axios.patch(`${this.getBaseUrl()}/${data.screensaverId}/step/levels`, data.levels);
  }
}
