import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import VenueLabelApiService from '@/api/http/VenueLabelApiService';
import { AxiosResponse } from 'axios';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { Venue } from '@/interfaces/models/Venue';

interface VenueLabelState {
  items: VenueLabel[];
  active: VenueLabel | null;
  pagination: {
    total: number;
  };
}

const api: VenueLabelApiService = new VenueLabelApiService();

const state: VenueLabelState = {
  items: [],
  active: null,
  pagination: {
    total: 0,
  },
};

const actions: ActionTree<VenueLabelState, RootState> = {
  fetch({ commit }, page: Page) {
    return api.index(page).then((res: AxiosResponse<VenueLabel[] | Pagination<VenueLabel>>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<VenueLabel>) {
    return api.store(data).then((res: AxiosResponse<VenueLabel>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<VenueLabel>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<VenueLabel>) {
    return api.update(data).then((res: AxiosResponse<VenueLabel>) => {
      commit('update', res.data);
    });
  },
  delete({ commit }, data: { id: string }) {
    return api.destroy(data).then(() => {
      commit('delete', data);
    });
  },
};

const mutations: MutationTree<VenueLabelState> = {
  setItems(state: VenueLabelState, data: Pagination<VenueLabel>) {
    state.items = (data.data as VenueLabel[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setActive(state: VenueLabelState, data: VenueLabel) {
    state.active = data;
  },
  addItem(state: VenueLabelState, data: VenueLabel) {
    state.items.push(data);
  },
  update(state: VenueLabelState, data: VenueLabel) {
    state.items = [...state.items.filter((r: VenueLabel) => r._id !== data._id), data];
  },
  delete(state: VenueLabelState, data: { id: string }) {
    state.items = [...state.items.filter((r: VenueLabel) => r._id !== data.id)];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
