import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Venue } from '@/interfaces/models/Venue';
import { Coupon } from '@/interfaces/models/Coupon';
import { Article } from '@/interfaces/models/Article';
import { OptionGroup } from '@/interfaces/models/OptionGroup';

export class CouponApiService extends ApiService<any> {
  constructor() {
    super('general/coupons');
  }

  public disableCoupon(data: { venue: string; coupon: string[] }): AxiosPromise<Venue> {
    return axios.patch(`${this.version}/general/venue/${data.venue}/coupons/disable`, data);
  }

  public generate(param: { code: Partial<Coupon>; amount: number }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/generate`, param);
  }

  public enableCoupon(data: { venue: string; promoCodes: string[] }): AxiosPromise<Venue> {
    return axios.patch(`${this.version}/general/venue/${data.venue}/coupons/enable`, data);
  }

  public activate(data: { id: string }): AxiosPromise<Coupon> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/activate`);
  }

  public deactivate(data: { id: string }): AxiosPromise<Coupon> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/deactivate`);
  }

  public couponCountCsv(lang?: string): AxiosPromise<string> {
    return axios.get(`v1/general/data/coupons/count/csv`, { params: { lang } });
  }

  public couponCsv(lang?: string): AxiosPromise<string> {
    return axios.get(`v1/general/data/coupons/csv`, { params: { lang } });
  }

  public uploadGiftCards(data: { id: string }, file: FormData): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/gift_cards`, file);
  }

  public uploadCouponBanner(data: { id: string; category: string; image: FormData }): AxiosPromise<Article> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/banners`, data.image);
  }

  public uploadCouponImage(data: { id: string; image: FormData }): AxiosPromise<OptionGroup> {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/photo`, data.image);
  }
}
