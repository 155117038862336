import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#B62E81',
        secondary: '#3C088C',
        pink: '#F05874',
        yellow: '#FFDA77',
        grey: '#606161',
        'grey-light': '#F5F5F5',

        error: '#F05874',
        info: '#B62E81',
        success: '#3C088C',
        warning: '#FFC107',
      },
    },
  },
});
