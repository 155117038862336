
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const notification = namespace('notification');

@Component
export default class Notification extends Vue {
  @notification.State('type') public type!: string;
  @notification.State('text') public text!: string;
  @notification.Action('reset') public reset!: () => void;
  public timeout: number = 6000;
  public visible: boolean = false;

  public timeoutInstance: number | ReturnType<typeof setTimeout> = -1;

  get notification() {
    return {
      type: this.type,
      text: this.text,
    };
  }

  get style() {
    return {
      position: 'fixed',
      'z-index': '9999',
      left: '50%',
      top: '80px',
      'min-width': '200px',
      'max-width': '100%',
    };
  }

  public close() {
    this.visible = false;
    this.reset();
    clearTimeout(this.timeoutInstance as any);
    this.timeoutInstance = -1;
  }

  @Watch('notification', { deep: true })
  public onNotificationChange() {
    if (this.type && this.text.length > 0) {
      this.visible = true;
      const self: Notification = this;
      if (this.timeoutInstance === -1) {
        clearTimeout(this.timeoutInstance);
      }
      this.timeoutInstance = setTimeout(() => {
        self.visible = false;
        self.reset();
      }, this.timeout);
    }
  }
}
