import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';

export class JetApiService extends ApiService<any> {
  constructor() {
    super('jet');
  }

  public getSyncJobs(params: any): AxiosPromise<any> {
    return axios.get(`${this.getBaseUrl()}/jobs`, {
      params,
    });
  }

  public syncFoodcard(payload: { venue: string }): AxiosPromise<void> {
    return axios.put(`${this.getBaseUrl()}/foodcards/venues/${payload.venue}`);
  }

  public updateOpeningHours(payload: { venue: string }): AxiosPromise<void> {
    return axios.patch(`${this.getBaseUrl()}/venues/${payload.venue}/opening-hours`);
  }
}
