import ApiService from '@/api/http/ApiService';
import axios from 'axios';
import Filter from '@/interfaces/api/Filter';
import { Page } from '@/interfaces/api/Page';

export class PiggyRewardApiService extends ApiService<any> {
  constructor() {
    super('general/loyalty', 'v1');
  }

  public index(page: Page | undefined, filter?: Filter) {
    return axios.get(`${this.version}/loyalty/reward/byvenue/${filter?.venue}`);
  }

  public getByCustomerGroup(page: Page | undefined, filter?: Filter) {
    return axios.get(`${this.version}/loyalty/reward/bygroup/${filter?.customerGroup}`);
  }
}
