import ApiService from '@/api/http/ApiService';
import { Venue } from '@/interfaces/models/Venue';
import axios, { AxiosPromise } from 'axios';
import { Tag } from '@/interfaces/models/Tag';
import { Constrain } from '@/interfaces/models/Constrain';
import { PrintGroups } from '@/interfaces/models/PrintGroups';
import { Page } from '@/interfaces/api/Page';
import { Pagination } from '@/interfaces/api/Pagination';
import { CustomerGroup } from '@/enums/CustomerGroup';
import Filter from '@/interfaces/api/Filter';
import { Receipt } from '@/interfaces/models/Receipt';

export default class VenueApiService extends ApiService<Venue> {
  constructor() {
    super('general/venue');
  }

  public index(page?: Page | null | undefined, filter?: any): AxiosPromise<Pagination<Venue> | Venue[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    if (page) {
      return axios.get(`${this.getBaseUrl()}/list?page=${page.page}&${query}`);
    }

    return axios.get(`${this.getBaseUrl()}/list?${query}`);
  }

  public getVenueName(param: { id: string }): AxiosPromise<Partial<Venue>> {
    return axios.get(`${this.getBaseUrl()}/${param.id}/name`);
  }

  public getVenueTerms(param: { id: string }): AxiosPromise<string> {
    return axios.get(`${this.getBaseUrl()}/${param.id}/terms`);
  }

  public getVenueImprint(param: { id: string }): AxiosPromise<string> {
    return axios.get(`${this.getBaseUrl()}/${param.id}/imprint`);
  }

  public getVenuePrivacy(param: { id: string }): AxiosPromise<string> {
    return axios.get(`${this.getBaseUrl()}/${param.id}/privacy`);
  }

  public getVenueIdsByCustomerGroup(customerGroup: string): AxiosPromise<Partial<Venue[]>> {
    return axios.get(`${this.getBaseUrl()}/customer/${customerGroup}`);
  }

  public uploadPhoto(param: { id: string }, data: FormData): AxiosPromise<Venue> {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/photo`, data);
  }

  public uploadNutritionTable(param: { id: string }, data: FormData) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/nutritionTable`, data);
  }

  public uploadSelfCertification(param: { id: string }, data: FormData) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/self-certification`, data);
  }

  public uploadTermsAndConditions(param: { id: string }, data: FormData) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/terms`, data);
  }

  public uploadImprint(param: { id: string }, data: FormData) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/imprint`, data);
  }

  public uploadPrivacy(param: { id: string }, data: FormData) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/privacy`, data);
  }

  public uploadExternalDeliveryIcon(param: { id: string }, data: FormData) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/external-delivery-service/icon`, data);
  }

  public show(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/${param.id}/full`);
  }

  public panic(param: { id: string; panicEndAt: string }) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/panic`, param);
  }

  public showSimple(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/${param.id}/?legal=true`);
  }

  public showPayment(param: { id: string }) {
    return axios.get(`${this.getBaseUrl()}/${param.id}/payment`);
  }

  public verify(param: { id: string }) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/verify`);
  }

  public publish(param: { id: string }) {
    return axios.patch(`${this.getBaseUrl()}/${param.id}/publish`);
  }

  public exportTableQrs(items: string[]) {
    return axios.post(`${this.getBaseUrl()}/tables/export`, { items }, { responseType: 'arraybuffer' });
  }

  public register(data: any) {
    return axios.post(`${this.getBaseUrl()}/franchisee`, data);
  }

  public storeTag(data: Partial<Tag>): AxiosPromise<Tag> {
    return axios.post(`${this.version}/general/tag/`, data);
  }

  public updateTag(data: Partial<Tag>): AxiosPromise<Tag> {
    return axios.patch(`${this.version}/general/tag/${data.id}/`, data);
  }

  public storeConstrain(data: Partial<Constrain>): AxiosPromise<Constrain> {
    return axios.post(`${this.version}/general/constrain/`, data);
  }

  public updateConstrain(data: Partial<Constrain>): AxiosPromise<Constrain> {
    return axios.patch(`${this.version}/general/constrain/${data.id}/`, data);
  }

  public storePrintGroups(data: Partial<PrintGroups>): AxiosPromise<PrintGroups> {
    return axios.post(`${this.version}/general/printgroup/`, data);
  }

  public updatePrintGroups(data: Partial<PrintGroups>): AxiosPromise<PrintGroups> {
    return axios.patch(`${this.version}/general/printgroup/${data.id}/`, data);
  }

  public startSync(data: { venues: string[]; venue: string }): AxiosPromise<Venue> {
    return axios.patch(`${this.getBaseUrl()}/${data.venue}/sync/start`, data);
  }

  public liftSyncLock(id: string): AxiosPromise<void> {
    // return axios.get(`${this.getBaseUrl()}/${id}/liftSyncLock`);
    return axios.patch(`${this.getBaseUrl()}/${id}`, { 'sync.canSync': true, 'sync.inProgress': false });
  }

  public validateReadableId(id: string, except: { except?: string }): AxiosPromise<void> {
    return axios.post(`${this.version}/general/validation/readableId`, { readableId: id, ...except });
  }

  public validateVatNumber(vatNumber: string, isVenue: boolean = false): AxiosPromise<void> {
    return axios.post(`${this.version}/general/validation/vatNumber${isVenue ? '/venue' : ''}`, { vatNumber });
  }

  public validateVatNumberGermany(vatNumberGermany: string, isVenue: boolean = false): AxiosPromise<void> {
    return axios.post(`${this.version}/general/validation/vatNumberGermany${isVenue ? '/venue' : ''}`, {
      vatNumberGermany,
    });
  }

  public getMasterVenuesShort(customerGroup: CustomerGroup): AxiosPromise<Array<Partial<Venue>>> {
    return axios.get(`${this.getBaseUrl()}/master`, { params: { customerGroup } });
  }

  public endOfDay(filter: Filter): AxiosPromise<Receipt[]> {
    let query: string = '';
    if (filter && Object.keys(filter).length > 0) {
      query = this.mapToQuery(filter);
    }

    return axios.get(`${this.getBaseUrl()}/${filter.venue}/endOfDay?${query}`);
  }

  public copyFoodcard(payload: { sourceVenue: string; targetVenue: string }): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/foodcard/copy`, payload);
  }

  public importFoodcard(id: string, data: any): AxiosPromise<void> {
    return axios.post(`${this.getBaseUrl()}/${id}/foodcard/hellotess-test/import`, data);
  }

  public importFoodcardGastrofix(id: string, data: any): AxiosPromise<void> {
    return axios.post(`/v1/foodcard/venue/${id}/foodcard/gastrofix/import`, data);
  }

  public importFoodcardHelloTess(id: string, data: any): AxiosPromise<void> {
    return axios.post(`/v1/foodcard/venue/${id}/foodcard/hellotess-test/import`, data);
  }

  public importFoodcardDeliverect(id: string, data: any): AxiosPromise<void> {
    return axios.post(`/v1/foodcard/deliverect/import/${id}`, data);
  }

  public importFoodcardSimphony(id: string, data: any): AxiosPromise<void> {
    return axios.post(`/v1/foodcard/simphony/import/${id}`, data);
  }

  public deleteFoodcard(id: string): AxiosPromise<void> {
    return axios.delete(`${this.getBaseUrl()}/${id}/foodcard`);
  }

  public generalImport(id: string, checkoutIntegrationProperties: any): AxiosPromise<void> {
    return axios.post(`/v1/foodcard/viaCheckoutIntegrationProperties/import/${id}`, checkoutIntegrationProperties);
  }

  public changServiceStatus(data: { venue: string; isServiceActivated: boolean }): AxiosPromise<Venue> {
    return axios.patch(`/v1/general/venue/activate/${data.venue}/${data.isServiceActivated}`);
  }

  public activateService(data: { venue: string }): AxiosPromise<Venue> {
    return axios.patch(`${this.getBaseUrl()}/${data.venue}/service/activate`);
  }

  public deactivateService(data: { venue: string }): AxiosPromise<Venue> {
    return axios.patch(`${this.getBaseUrl()}/${data.venue}/service/deactivate`);
  }

  public changPiggyLoyalty(data: { venue: string; piggyLoyaltyEnabled: boolean }): AxiosPromise<Venue> {
    return axios.patch(`${this.getBaseUrl()}/${data.venue}`, { piggyLoyaltyEnabled: data.piggyLoyaltyEnabled });
  }

  public copyFromToVenue(data: { from: string; to: string[] }): AxiosPromise<Boolean> {
    return axios.patch(`${this.getBaseUrl()}/checkoutIntegration/copy`, { ...data });
  }

  public generateWebAppSlug(): AxiosPromise<any> {
    return axios.patch(`${this.getBaseUrl()}/update-webapp-slugs`);
  }
}
