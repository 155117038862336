import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Role } from '@/interfaces/models/Role';
import AuthApiService from '@/api/http/AuthApiService';
import { AxiosResponse } from 'axios';

interface RoleState {
  items: Role[];
  active: Role | null;
}

const api: AuthApiService = new AuthApiService();

const state: RoleState = {
  items: [],
  active: null,
};

const actions: ActionTree<RoleState, RootState> = {
  fetch({ commit }) {
    return api.getRoles().then((res: AxiosResponse<Role[]>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<Role>) {
    return api.storeRole(data).then((res: AxiosResponse<Role>) => {
      commit('addItem', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.showRole(data).then((res: AxiosResponse<Role>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<Role>) {
    return api.updateRole(data).then((res: AxiosResponse<Role>) => {
      commit('update', res.data);
    });
  },
  delete({ commit }, data: { id: string }) {
    return api.deleteRole(data).then(() => {
      commit('delete', data);
    });
  },
};

const mutations: MutationTree<RoleState> = {
  setItems(state: RoleState, data: Role[]) {
    state.items = data;
  },
  setActive(state: RoleState, data: Role) {
    state.active = data;
  },
  addItem(state: RoleState, data: Role) {
    state.items.push(data);
  },
  update(state: RoleState, data: Role) {
    state.items = [...state.items.filter((r: Role) => r._id !== data._id), data];
  },
  delete(state: RoleState, data: { id: string }) {
    state.items = [...state.items.filter((r: Role) => r._id !== data.id)];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
