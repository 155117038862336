
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import { venueFilter } from '@/util/helper';

@Component
export default class VenueSelectorModal extends Vue {
  @Prop({ type: Boolean, required: true }) public value!: boolean;
  @Prop({ type: Array, default: () => [] }) public items!: Venue[];

  protected search: string = '';

  get visibility() {
    return this.value;
  }

  set visibility(val: boolean) {
    if (!val) {
      this.$emit('hide');
    }
  }

  @Emit('hide-force')
  public backToVenuePage() {
    this.$router.push({ name: 'venue.index' });
  }

  @Watch('search')
  @Emit('selected')
  public onSearch() {
    return this.items.find((item: Venue) => item._id === this.search)!;
  }

  public filter(item: Venue, queryText: string): boolean {
    return venueFilter(item, queryText);
  }
}
