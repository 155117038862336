import ApiService from '@/api/http/ApiService';
import { User } from '@/interfaces/models/User';
import axios from 'axios';

export default class UserApiService extends ApiService<User> {
  constructor() {
    super('auth/user');
  }

  public store(data: Partial<User>) {
    return axios.post(`${this.version}/auth/register`, data);
  }

  public storeRestricted(data: Partial<User>) {
    return axios.post(`${this.version}/auth/register/restricted`, data);
  }

  public updateRestricted(data: Partial<User>) {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/restricted`, data);
  }

  public delete(data: { id: string }) {
    return axios.delete(`${this.version}/auth/${data.id}`);
  }

  public changePassword(data: { id: string; password: string }) {
    return axios.patch(`${this.getBaseUrl()}/${data.id}/password/reset`, data);
  }
}
