import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import Filter from '@/interfaces/api/Filter';
import FoodcardApiService from '@/api/http/FoodcardApiService';
import { StockManagementType } from '@/enums/StockManagementType';
import { GroupedArticle } from '@/interfaces/models/GroupedArticle';
import { GroupedOptionArticle } from '@/interfaces/models/GroupedOptionArticle';

const api: FoodcardApiService = new FoodcardApiService();

interface ArticleManagerState {
  articles: GroupedArticle[];
  optionArticles: GroupedOptionArticle[];
  filter: Filter;
  articlePagination: {
    total: number;
    pages: number;
  };
  optionAtriclePagination: {
    total: number;
    pages: number;
  };
}

const state: ArticleManagerState = {
  articles: [],
  optionArticles: [],
  filter: {},
  articlePagination: { total: 0, pages: 0 },
  optionAtriclePagination: { total: 0, pages: 0 },
};

const actions: ActionTree<ArticleManagerState, RootState> = {
  fetchArticleVisibility({ commit, state }, data: { venue: string }) {
    return api.getArticleVisibility(data.venue, state?.filter).then((res: any) => {
      if (state?.filter?.type === StockManagementType.ARTICLES) {
        commit('setArticles', res.data);
        return;
      }

      if (state?.filter?.type === StockManagementType.OPTION_ARTICLES) {
        commit('setOptionArticles', res.data);
      }
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  makeArticleVisible({ commit }, data: { venue: string; articles: string[] }) {
    return api.makeArticleVisible(data).then(() => {
      commit('toggleArticleVisibility', { value: true, articles: data.articles });
    });
  },
  hideArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.hideArticle(data).then(() => {
      commit('toggleArticleVisibility', { value: false, articles: data.articles });
    });
  },
  makeArticleOptionVisible({ commit }, data: { venue: string; articles: string[] }) {
    return api.makeArticleOptionVisible(data).then(() => {
      commit('toggleOptionArticleVisibility', { value: true, articles: data.articles });
    });
  },
  hideOptionArticle({ commit }, data: { venue: string; articles: string[] }) {
    return api.hideOptionArticle(data).then(() => {
      commit('toggleOptionArticleVisibility', { value: false, articles: data.articles });
    });
  },
  resetFilter({ commit }) {
    commit('resetFilter');
  },
  resetArticles({ commit }) {
    commit('resetArticles');
  },
};

const mutations: MutationTree<ArticleManagerState> = {
  setArticles(state: ArticleManagerState, data: any) {
    state.articles = [...state.articles, ...data.data];
    if (data.total) {
      state.articlePagination.total = data.total;
    }
    if (data?.pagination) {
      state.articlePagination.pages = data?.pagination?.total;
    }
  },
  setOptionArticles(state: ArticleManagerState, data: any) {
    state.optionArticles = [...state.optionArticles, ...data.data];
    if (data.total) {
      state.optionAtriclePagination.total = data.total;
    }
    if (data?.pagination) {
      state.optionAtriclePagination.pages = data?.pagination?.total;
    }
  },
  setFilter(state: ArticleManagerState, data: Filter) {
    state.filter = data;
  },
  toggleArticleVisibility(state: ArticleManagerState, data: { value: boolean; articles: string[] }) {
    const articles: GroupedArticle[] = state.articles.filter((a: any) => data.articles.indexOf(a._id) > -1);
    for (const article of articles) {
      article.visible = data.value;
    }
  },
  toggleOptionArticleVisibility(state: ArticleManagerState, data: { value: boolean; articles: string[] }) {
    const optionArticles: GroupedOptionArticle[] = state.optionArticles.filter((a: any) =>
      data.articles.includes(a._id),
    );
    for (const article of optionArticles) {
      article.visible = data.value;
    }
    for (const article of state?.articles) {
      for (const group of article?.groups) {
        const optArticle = group?.articles.find((optArticle) => data.articles.includes(optArticle?._id));
        if (optArticle) {
          optArticle.visible = data.value;
          return;
        }
      }
    }
  },
  resetFilter(state: ArticleManagerState) {
    state.filter = {};
  },
  resetArticles(state: ArticleManagerState) {
    state.articles = [];
    state.optionArticles = [];
    state.articlePagination = { total: 0, pages: 0 };
    state.optionAtriclePagination = { total: 0, pages: 0 };
  },
};

const getters: GetterTree<ArticleManagerState, RootState> = {
  articles: (state) => state.articles,
  optionArticles: (state) => state.optionArticles,
};

export default {
  namespaced: true,
  mutations,
  state,
  actions,
  getters,
};
