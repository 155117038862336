
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class VConfirm extends Vue {
  public text: string = 'common.confirmDelete';
  public isVisible: boolean = false;

  public setText(text: string) {
    this.text = text;
  }

  public resolve: (value?: {} | PromiseLike<{}> | undefined) => any = () => null;
  public reject: (reason?: {}) => any = () => null;

  public open(
    resolve: (value?: {} | PromiseLike<{}> | undefined) => any = () => null,
    reject: (reason?: {}) => any = () => null,
  ) {
    this.isVisible = true;
    this.resolve = resolve;
    this.reject = reject;
  }
  public agree() {
    this.resolve(true);
    this.isVisible = false;
  }
  public cancel() {
    this.resolve(false);
    this.isVisible = false;
  }
}
