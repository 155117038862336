function determineUserLanguage(): string {
  const browserLanguagePropertyKeys: string[] = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];
  const nav: Navigator = window.navigator;
  let language: string;

  if (Array.isArray(nav.languages)) {
    for (const navLanguage of nav.languages) {
      language = navLanguage;
      if (language && language.length) {
        return language;
      }
    }
  }
  // support for other well known properties in browsers
  for (const key of browserLanguagePropertyKeys) {
    // @ts-ignore
    language = nav[key];
    if (language && language.length) {
      return language;
    }
  }

  // fallback language
  return 'en';
}

let lang: string = determineUserLanguage();
const locale: string = determineUserLanguage();
if (lang.length > 2) {
  lang = lang.substring(0, 2);
}

export { lang, locale };
