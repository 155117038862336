import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Pagination } from '@/interfaces/api/Pagination';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Information } from '@/interfaces/models/Information';
import InformationApiService from '@/api/http/InformationApiService';
import { Venue } from '@/interfaces/models/Venue';

interface InformationState {
  active: Information | null;
  items: Information[];
  pagination: {
    total: number;
  };
}

const state: InformationState = {
  active: null,
  items: [],
  pagination: {
    total: 0,
  },
};

const api: InformationApiService = new InformationApiService();

const actions: ActionTree<InformationState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.index(page).then((res: AxiosResponse<Information[] | Pagination<Information>>) => {
      commit('setItems', res.data);
    });
  },
  store({ commit }, data: Partial<Information>) {
    return api.store(data).then((res: AxiosResponse<Information>) => {
      commit('addItem', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroy(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<Information>) => {
      commit('setActive', res.data);
    });
  },
  uploadPhoto({ commit }, data: { id: string; image: FormData }) {
    return api.uploadPhoto(data, data.image).then((res: AxiosResponse<Information>) => {
      commit('setActive', res.data);
    });
  },
  update({ commit }, data: Partial<Information>) {
    return api.update(data).then((res: AxiosResponse<Information>) => {
      commit('update', res.data);
    });
  },
};
const mutations: MutationTree<InformationState> = {
  setItems(state: InformationState, data: Pagination<Information>) {
    state.items = (data.data as Information[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  addItem(state: InformationState, data: Information) {
    state!.items.push(data);
  },
  update(state: InformationState, data: Information) {
    state.items = [...(state.items as Information[]).filter((item: Information) => item._id !== data._id), data];
  },
  removeItem(state: InformationState, data: Information) {
    state.items = state.items.filter((item: Information) => item._id !== data._id);
  },
  setActive(state: InformationState, data: Information) {
    state.active = data;
  },
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
