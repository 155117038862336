import { PromoCode } from '@/interfaces/models/PromoCode';
import { ActionTree, MutationTree } from 'vuex';
import { RootState } from '@/interfaces/stores/RootState';
import { Page } from '@/interfaces/api/Page';
import { AxiosResponse } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import Filter from '@/interfaces/api/Filter';
import { Venue } from '@/interfaces/models/Venue';
import { CouponApiService } from '@/api/http/CouponApiService';
import { Coupon } from '@/interfaces/models/Coupon';

interface CouponState {
  items: Coupon[];
  active: Coupon | null;
  filter: Filter;
  pagination: {
    total: number;
  };
}

const api: CouponApiService = new CouponApiService();

const state: CouponState = {
  active: null,
  items: [],
  filter: {},
  pagination: { total: 0 },
};

const actions: ActionTree<CouponState, RootState> = {
  fetch({ commit, state }, page: Page) {
    return api.index(page, state.filter).then((res: AxiosResponse<PromoCode[] | Pagination<PromoCode>>) => {
      commit('setItems', res.data);
    });
  },
  show({ commit }, data: { id: string }) {
    return api.show(data).then((res: AxiosResponse<PromoCode>) => {
      commit('setActive', res.data);
    });
  },
  setFilter({ commit }, filter: Filter) {
    commit('setFilter', filter);
  },
  store({ commit }, data: Partial<PromoCode>) {
    return api.store(data).then((res: AxiosResponse<PromoCode>) => {
      commit('addItem', res.data);
      commit('setActive', res.data);
    });
  },
  generate({ commit }, data: { code: Partial<PromoCode>; amount: number }) {
    return api.generate(data);
  },
  update({ commit }, data: Partial<PromoCode>) {
    return api.update(data).then((res: AxiosResponse<PromoCode>) => {
      commit('update', res.data);
    });
  },
  uploadGiftCards({ commit }, data: { id: string; file: FormData }) {
    return api.uploadGiftCards(data, data.file);
  },
  activateCoupon({ commit }, data: { id: string }) {
    return api.activate(data).then((res: AxiosResponse<PromoCode>) => {
      commit('update', res.data);
    });
  },
  enableCoupon({ commit, dispatch }, data: { id: string; venue: string }) {
    return api.enableCoupon({ venue: data.venue, promoCodes: [data.id] }).then((res: AxiosResponse<Venue>) => {
      dispatch('venue/setActive', res.data, { root: true });
    });
  },
  deactivateCoupon({ commit }, data: { id: string }) {
    return api.deactivate(data).then((res: AxiosResponse<PromoCode>) => {
      commit('update', res.data);
    });
  },
  destroy({ commit }, data: { id: string }) {
    return api.destroy(data).then(() => {
      commit('removeItem', { _id: data.id });
    });
  },
  disableCoupon({ commit, dispatch }, data: { id: string; venue: string }) {
    return api.disableCoupon({ venue: data.venue, coupon: [data.id] }).then((res: AxiosResponse<Venue>) => {
      dispatch('venue/setActive', res.data, { root: true });
    });
  },
  uploadCouponImage({ commit }, data: { id: string; category: string; image: FormData }) {
    return api.uploadCouponImage(data).then((res: AxiosResponse<any>) => {
      commit('setActiveCoupon', res.data);
      commit('updateCoupon', res.data);
    });
  },
  uploadCouponBanner({ commit }, data: { id: string; category: string; image: FormData }) {
    return api.uploadCouponBanner(data).then((res: AxiosResponse<any>) => {
      commit('setActiveCoupon', res.data);
      commit('updateCoupon', res.data);
    });
  },
};

const mutations: MutationTree<CouponState> = {
  updateCoupon(state: CouponState, data: Coupon) {
    state.items = [...state.items.map((item: Coupon) => (item._id !== data._id ? item : { ...item, ...data }))];
  },
  setActiveArticle(state: CouponState, data: Coupon) {
    state.active = data;
  },
  setItems(state: CouponState, data: Pagination<PromoCode>) {
    state.items = (data.data as Coupon[]) || data;
    if (data.total) {
      state.pagination.total = data.total;
    }
  },
  setFilter(state: CouponState, filter: Filter) {
    state.filter = filter;
  },
  addItem(state: CouponState, data: Coupon) {
    state!.items.push(data);
  },
  update(state: CouponState, data: Coupon) {
    state.items = [...(state.items as Coupon[]).filter((item: Coupon) => item._id !== data._id), data];
  },
  setActive(state: CouponState, data: Coupon) {
    state.active = data;
  },
  removeItem(state: CouponState, data: PromoCode) {
    state.items = state.items.filter((item: PromoCode) => item._id !== data._id);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
