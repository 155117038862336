import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Pagination } from '@/interfaces/api/Pagination';
import SalesItem from '@/interfaces/models/SalesItem';
import Statistic from '@/interfaces/models/Statistic';
import Filter from '@/interfaces/api/Filter';
import { StatisticOverviewItem } from '@/interfaces/models/StatisticOverviewItem';

export default class StatisticsApiService extends ApiService<any> {
  constructor() {
    super('statistic/');
  }

  public getSales(filter?: Filter): AxiosPromise<SalesItem[] | Pagination<SalesItem>> {
    return axios.get(`${this.getBaseUrl()}sales/`, { params: filter });
  }

  public getAnalytics(filter?: Filter): AxiosPromise<Statistic> {
    return axios.get(`${this.getBaseUrl()}analytics/${filter!.venue}/`, { params: filter });
  }

  public getCustomerGroupAnalytics(filter?: Filter): AxiosPromise<Statistic> {
    return axios.get(`${this.getBaseUrl()}customerGroup/${filter!.group}/`, { params: filter });
  }

  public getOverview(filter: Filter): AxiosPromise<StatisticOverviewItem[]> {
    return axios.get(`${this.getBaseUrl()}overview`, { params: filter });
  }

  public getOverviewCsv(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.getBaseUrl()}data/overview/csv`, { params: filter });
  }

  public getSalesCsv(filter: Filter): AxiosPromise<string> {
    return axios.get(`${this.getBaseUrl()}data/sales/csv`, { params: filter });
  }

  public getAnalyticsCsv(filter?: Filter): AxiosPromise<Statistic> {
    return axios.get(`${this.getBaseUrl()}data/analytics/csv/${filter!.venue}/`, { params: filter });
  }
}
