
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Venue } from '@/interfaces/models/Venue';
import LanguagePicker from '@/components/layout/LanguagePicker.vue';
import { venueFilter } from '@/util/helper';
import { Permission } from '@/enums/Permission';

@Component({
  components: { LanguagePicker },
})
export default class Toolbar extends Vue {
  get venue() {
    if (this.active) {
      return this.items.find((v: Venue) => v._id === this.active);
    }

    return null;
  }
  @Prop({ type: Array, required: true }) public items!: Venue[];
  @Prop({ type: String, default: '' }) public active!: string;
  @Prop({ type: Boolean, default: true }) public drawer!: boolean;

  public drawerVisible: boolean = true;

  protected search: string = '';

  protected fuseIndex: any;

  @Watch('drawer')
  public onDrawerChange(val: boolean, oldVal: boolean) {
    if (val !== oldVal) {
      this.drawerVisible = val;
    }
  }

  @Emit('drawer')
  public toggleDrawer() {
    this.drawerVisible = !this.drawerVisible;
    return this.drawerVisible;
  }

  public filter(item: Venue, queryText: string): boolean {
    return venueFilter(item, queryText.trim());
  }

  public editVenue() {
    if (!this.venue) {
      return;
    }
    if (this.$can(Permission.VENUE_UPDATE)) {
      this.$router.push({ name: 'venue.edit', params: { id: this.venue._id } });
    } else {
      this.$router.push({ name: 'venue.editSimple', params: { id: this.venue._id } });
    }
  }

  public copy(value: string) {
    if (value) {
      navigator.clipboard.writeText(value);
    }
  }

  @Watch('search')
  @Emit('selected')
  public onSearch() {
    return this.items.find((item: Venue) => item._id === this.search)!;
  }

  @Watch('active')
  public onActiveChange() {
    this.search = this.active;
  }
}
