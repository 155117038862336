import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/i18n/locales/en';
import de from '@/i18n/locales/de';
import fr from '@/i18n/locales/fr';
import { lang } from '@/language';
import enValidationMessages from 'vee-validate/dist/locale/en.json';
import deValidationMessages from 'vee-validate/dist/locale/de.json';
import frValidationMessages from 'vee-validate/dist/locale/fr.json';
Vue.use(VueI18n);
const messages = {
  en: {
    ...en,
    validations: { ...enValidationMessages.messages, ...en.validations },
  },
  de: {
    ...de,
    validations: { ...deValidationMessages.messages, ...de.validations },
  },
  fr: {
    ...fr,
    validations: { ...frValidationMessages.messages, ...fr.validations },
  },
};

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages,
});

export default i18n;
