import ApiService from '@/api/http/ApiService';
import axios, { AxiosPromise } from 'axios';
import { Banner } from '@/interfaces/models/Banner';

export default class BannerApiService extends ApiService<Banner> {
  constructor() {
    super('general/venue', 'v1');
  }

  public store(data: { venueId: string; banner: Banner }): AxiosPromise<Banner> {
    return axios.post(`${this.getBaseUrl()}/${data.venueId}/banners/`, data.banner);
  }

  public show(data: { venueId: string; id: string }): AxiosPromise<Banner> {
    return axios.get(`${this.getBaseUrl()}/${data.venueId}/banners/${data.id}`);
  }

  public update(data: { venueId: string; id: string; banner: Banner }): AxiosPromise<Banner> {
    return axios.patch(`${this.getBaseUrl()}/${data.venueId}/banners/${data.id}`, data.banner);
  }

  public fetch(venueId: string): AxiosPromise<Banner[]> {
    return axios.get(`${this.getBaseUrl()}/${venueId}/banners`);
  }

  public delete(data: { venueId: string; bannerId: string }): AxiosPromise<Banner[]> {
    return axios.delete(`${this.getBaseUrl()}/${data.venueId}/banners/${data.bannerId}`);
  }

  public uploadBannerImage(data: {
    venueId: string;
    id: string;
    image: FormData;
    locale: string;
  }): AxiosPromise<Banner[]> {
    return axios.post(`${this.getBaseUrl()}/${data.venueId}/banners/${data.id}/upload/${data.locale}`, data.image);
  }
}
